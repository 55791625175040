
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2"  [routerLink]="['/transformation/dries']" ></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedDries()" [disabled]="!selectedDries || !selectedDries.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="dries" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','isEnabled']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedDries" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <!-- <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Rank<p-sortIcon field="position"></p-sortIcon>
                    </th> -->
                    <th id="when" name="when" pResizableColumn pReorderableColumn pSortableColumn="when" [class]="includesCol('when') ? 'd' : 'h'">Date time 
                        <p-sortIcon field="when"></p-sortIcon> 
                        <p-columnFilter type="text" field="when" display="menu"></p-columnFilter>
                    </th>
                    <th id="who" name="who" pResizableColumn pReorderableColumn pSortableColumn="who" [class]="includesCol('who') ? 'd' : 'h'">User 
                        <p-sortIcon field="who"></p-sortIcon> 
                        <p-columnFilter type="text" field="user" display="menu"></p-columnFilter>
                    </th>
                    <th id="fromWhat" name="fromWhat" pResizableColumn pReorderableColumn pSortableColumn="fromWhat" [class]="includesCol('fromWhat') ? 'd' : 'h'">Product inputs
                        <p-sortIcon field="fromWhat"></p-sortIcon> 
                        <p-columnFilter type="text" field="fromWhat" display="menu"></p-columnFilter>
                        
                        <!-- <img alt="Create content" src="/assets/images/content.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/content-types']"/> -->
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <th id="toWhat" name="toWhat" pResizableColumn pReorderableColumn pSortableColumn="toWhat" [class]="includesCol('toWhat') ? 'd' : 'h'">Product outputs
                        <p-sortIcon field="toWhat"></p-sortIcon> 
                        <p-columnFilter type="text" field="toWhat" display="menu"></p-columnFilter>
                        
                        <!-- <img alt="Create content" src="/assets/images/container.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/packaging-types']"/> -->
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <!-- <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions&nbsp;</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dry let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <!-- <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="dry.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{dry.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('when') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="dry.when" (blur)="completeDry(dry)" (keydown.tab)="completeDry(dry)" (keydown.enter)="completeDry(dry)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{dry.when  | date:'MM/dd/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor> -->
                        <span >{{dry.when  | date:'MM/dd/yyyy HH:mm' }}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('who') ? 'd' : 'h'">
                        <span >{{dry.who}}</span>
                    </td>

                   
                    <td pEditableColumn [class]="includesCol('fromWhat') ? 'd' : 'h'">
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectContentKey" 
                                    [options]="contentTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeDry(dry)"
                                    (keydown.tab)="completeDry(dry)"
                                    (keydown.enter)="completeDry(dry)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                
                            </ng-template>
                        </p-cellEditor> -->
                        <div *ngFor="let v of a(dry)" > 
                            <p-tag styleClass="mr-2" >
                                   {{ v }}
                            </p-tag>
                            <div *ngIf="a(dry).length > 1" style="height: 5px"></div>
                       </div>
                    </td>

                    <td pEditableColumn [class]="includesCol('toWhat') ? 'd' : 'h'">
                        <p-tag styleClass="mr-2" >
                            {{dry.toWhat.name}} - {{dry.toWhat.batch}}
                        </p-tag>
                    </td>
                    <td class="row-actions">
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <!-- <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editDry(dry)"></button> -->
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteDry(dry)"></button>
                        <p-tableCheckbox class="checkbox" [value]="dry"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{dries ? dries.length : 0 }} {{ dries.length > 1 ? 'dries' : 'dry'}}
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="dryDialog" [style]="{width: '450px', height: '450px'}" header="Packaging Type Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="when">Date Time</label>
                <input type="text" pInputText id="when" [(ngModel)]="dry.when" required autofocus />
                <small class="p-error" *ngIf="submitted && !dry.when">Datetime is required.</small>
                
                <p-calendar [(ngModel)]="dry.when" [style]="{'width':'100%'}" [inputStyle]="{'width':'150px'}" [showTime]="true" [hourFormat]="'24'"></p-calendar>
            </div>

            <p-multiSelect [options]="groupedCities" [group]="true" [(ngModel)]="selectedCities4" defaultLabel="Select a City" scrollHeight="250px" display="chip">
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + group.value" style="width: 20px"/>
                        <span>{{group.label}}</span>
                    </div>
                </ng-template>
            </p-multiSelect>

            <div class="p-field">
                <label for="who">User</label>
                <input type="text"  pInputText id="who" [(ngModel)]="dry.who" required autofocus />
                <small class="p-error" *ngIf="submitted && !dry.who">User is required.</small>
            </div>

            <div class="p-field">
                <label for="fromWhat">Inputs</label>
                <input type="text" pInputText id="fromWhat" [(ngModel)]="dry.fromWhat" required autofocus />
                <small class="p-error" *ngIf="submitted && !dry.fromWhat">Inputs is required.</small>
            </div>
         
            <div class="p-field">
                <label for="toWhat">Outputs</label>
                <input type="text" pInputText id="toWhat" [(ngModel)]="dry.toWhat" required autofocus />
                <small class="p-error" *ngIf="submitted && !dry.toWhat">Outputs is required.</small>
            </div>

            <div >
                <label for="content" style="margin-left: 20px;margin-right: 10px;">Content</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectContentKey" 
                    [options]="contentTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div>

            <div >
                <label for="container" style="margin-left: 20px;margin-right: 10px;">Packaging</label>
                <p-dropdown 
          
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectPackagingKey" 
                    [options]="packagingTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveDry()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>






