
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedProductTypes()" [disabled]="!selectedProductTypes || !selectedProductTypes.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="productTypes" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['barcode','name','isEnabled']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedProductTypes" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="barcode" name="barcode" pResizableColumn pReorderableColumn pSortableColumn="barcode" [class]="includesCol('barcode') ? 'd' : 'h'">Barcode
                        <p-sortIcon field="barcode"></p-sortIcon> 
                        <p-columnFilter type="text" field="barcode" display="menu"></p-columnFilter>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th id="minCapacity" name="minCapacity" pResizableColumn pReorderableColumn pSortableColumn="minCapacity" [class]="includesCol('minCapacity') ? 'd' : 'h'">Min capacity 
                        <p-sortIcon field="minCapacity"></p-sortIcon> 
                        <p-columnFilter field="minCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="minActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{minActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{minActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </th> -->
                    <!-- <th id="maxCapacity" name="maxCapacity" pResizableColumn pReorderableColumn pSortableColumn="maxCapacity" [class]="includesCol('maxCapacity') ? 'd' : 'h'">Max capacity 
                        <p-sortIcon field="maxCapacity"></p-sortIcon> 
                        <p-columnFilter field="maxCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="maxActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{maxActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{maxActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </th> -->
                    <th id="content" name="content" pResizableColumn pReorderableColumn pSortableColumn="content" [class]="includesCol('content') ? 'd' : 'h'">Content type
                        <p-sortIcon field="content.name"></p-sortIcon> 
                        <p-columnFilter type="text" field="content.name" display="menu"></p-columnFilter>
                        
                        <img alt="Create content" src="/assets/images/content.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/content-types']"/>
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <th id="container" name="container" pResizableColumn pReorderableColumn pSortableColumn="container" [class]="includesCol('container') ? 'd' : 'h'">Packaging type
                        <p-sortIcon field="container.name"></p-sortIcon> 
                        <p-columnFilter type="text" field="container.name" display="menu"></p-columnFilter>
                        
                        <img alt="Create content" src="/assets/images/container.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/packaging-types']"/>
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-productType let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="productType.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{productType.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="productType"></p-tableCheckbox> -->
                    </td>

                    <!-- https://github.com/efgiese/ngx-barcode6 -->
                    <td pEditableColumn [class]="includesCol('barcode') ? 'd' : 'h'" style="width: 8rem">
                        <div style="text-align:center; ">
                            <ngx-barcode6
                                [bc-format]="'code128'"
                                [bc-value]="'12345678901231'"
                                [bc-display-value]="true">
                            </ngx-barcode6>
                        </div>
                        
                    </td>
                    

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="productType.name" (blur)="completeProductType(productType)" (keydown.tab)="completeProductType(productType)" (keydown.enter)="completeProductType(productType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{productType.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <!-- <td pEditableColumn [class]="includesCol('minCapacity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="productType.minCapacity" (blur)="completeProductType(productType)" (keydown.tab)="completeProductType(productType)" (keydown.enter)="completeProductType(productType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{productType.minCapacity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('maxCapacity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="productType.maxCapacity" (blur)="completeProductType(productType)" (keydown.tab)="completeProductType(productType)" (keydown.enter)="completeProductType(productType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{productType.maxCapacity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('content') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectContentKey" 
                                    [options]="contentTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeProductType(productType)"
                                    (keydown.tab)="completeProductType(productType)"
                                    (keydown.enter)="completeProductType(productType)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{productType.content?.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('container') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectPackagingKey" 
                                    [options]="packagingTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeProductType(productType)"
                                    (keydown.tab)="completeProductType(productType)"
                                    (keydown.enter)="completeProductType(productType)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{productType.container?.name }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="productType.isEnabled" (onChange)="completeProductType(productType)"></p-inputSwitch>
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="productType.isEnabled"  (blur)="completeProductType(productType)" (keydown.tab)="completeProductType(productType)" (keydown.enter)="completeProductType(productType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{productType.isEnabled}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                    </td>


                    <!-- <td>{{productType.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProductType(productType)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProductType(productType)"></button>
                        <p-tableCheckbox class="checkbox" [value]="productType"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="productType"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{productTypes ? productTypes.length : 0 }} productTypes.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="productTypeDialog" [style]="{width: '450px', height: '500px'}" header="Add or Updae a Product Type" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Name</label>
                <input style="margin-top: 10px;"  type="text" pInputText id="name" [(ngModel)]="productType.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !productType.name">Name is required.</small>
            </div>
            <br>

            <div class="p-field">
                <label for="name">Barcode</label>
                <input style="margin-top: 10px;"  type="text" pInputText id="name" [(ngModel)]="productType.barcode" required autofocus />
                <small class="p-error" *ngIf="submitted && !productType.name">Barcode is required.</small>
            </div>
            <br>

            <div tyle="margin-top: 10px;">
                <label for="content" style="margin-left: 0px;margin-top: 10px;margin-bottom: 10px;">Content</label>
                <p-dropdown style="margin-top: 10px;" 
                    [style]="'margin-top:10px'  "
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectContentKey" 
                    [options]="contentTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    (onChange)="onChangeContent(dialogSelectContentKey)"
                    placeholder="Any">
                </p-dropdown>
            </div>
            <br>

            <div tyle="margin-top: 10px;">
                <label for="container" style="margin-left: 0px;margin-top: 10px;margin-bottom: 10px;">Packaging</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectPackagingKey" 
                    [options]="packagingTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    (onChange)="onChangePackaging(dialogSelectPackagingKey)"
                    placeholder="Any">
                </p-dropdown>
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveProductType()"></button>
        </div>
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>






