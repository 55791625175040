import { Injectable } from '@angular/core';
import { Location } from '../interfaces/location';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationCrudService {


  locationsRef: AngularFireList<any> | undefined;
  locationRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/locations/' ;
  }

  // Create Location
  addLocation(location: Location) {
    this.locationsRef = this.db.list( this.path );
    const item = {
      ...location
    }
    this.locationsRef?.push( item );
  }
  // Fetch Single Location Object
  getLocation(id: string) {
    this.locationRef = this.db.object( this.path + id);
    return this.locationRef;
  }
  // Fetch Locations List
  getLocationsList() {
    this.locationsRef = this.db.list( this.path );
    return this.locationsRef;
  }
  // Update Location Object
  updateLocation(location: Location) {
    this.locationRef = this.db.object( this.path + location.$key);
   // console.log("P inside", location);
    let item : Partial<Location>  = {
      ...location
    }
    delete item?.$key;
    this.locationRef?.update(item);
  }
  // Delete Location Object
  deleteLocation(id: string) {
    this.locationRef = this.db.object( this.path + id);
    this.locationRef.remove();
  }
}
