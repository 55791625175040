
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedContentTypes()" [disabled]="!selectedContentTypes || !selectedContentTypes.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="contentTypes" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','inputable','outputable','isEnabled']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedContentTypes" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="inputable" name="inputable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('inputable') ? 'd' : 'h'">In
                        <p-columnFilter type="boolean" field="inputable" display="menu"></p-columnFilter>
                    </th>
                    <th id="outputable" name="outputable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('outputable') ? 'd' : 'h'">Out
                        <p-columnFilter type="boolean" field="outputable" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contentType let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="contentType.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{contentType.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="contentType"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="contentType.name" (blur)="completeContentType(contentType)" (keydown.tab)="completeContentType(contentType)" (keydown.enter)="completeContentType(contentType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{contentType.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- onEditInit= onEditComplete onEditCancel
                    (blur)="save({ data: item })"
                                 (keydown.tab)="save({ data: item })"
                                 (keydown.enter)="save({ data: item })" -->
                                 <td pEditableColumn [class]="includesCol('inputable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                                    <p-inputSwitch  [(ngModel)]="contentType.inputable" (onChange)="completeContentType(contentType)"></p-inputSwitch>
                                </td>
                                <td pEditableColumn [class]="includesCol('outputable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                                    <p-inputSwitch  [(ngModel)]="contentType.outputable" (onChange)="completeContentType(contentType)"></p-inputSwitch>
                                </td>

                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="contentType.isEnabled" (onChange)="completeContentType(contentType)"></p-inputSwitch>
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="contentType.isEnabled"  (blur)="completeContentType(contentType)" (keydown.tab)="completeContentType(contentType)" (keydown.enter)="completeContentType(contentType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{contentType.isEnabled}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                    </td>


                    <!-- <td>{{contentType.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editContentType(contentType)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteContentType(contentType)"></button>
                        <p-tableCheckbox class="checkbox" [value]="contentType"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="contentType"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{contentTypes ? contentTypes.length : 0 }} contentTypes.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="contentTypeDialog" [style]="{width: '450px', height: '450px'}" header="Add or Update Content Type" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Name</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="contentType.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !contentType.name">Name is required.</small>
            </div>

            <div class="p-field">
                <label for="description">Description</label>
                <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="contentType.isEnabled" rows="3" cols="20"></textarea>
                
            </div>

            <div class="in-out">

                <div class="p-field">
                    <label for="inputable">In</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="inputable" [(ngModel)]="contentType.inputable"></p-inputSwitch>
                </div>
    
                <div class="p-field">
                    <label style="vertical-align: middle;" for="outputable">Out</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="outputable" [(ngModel)]="contentType.outputable"></p-inputSwitch>
                </div>

            </div>

            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveContentType()"></button>
            </div>
            

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
           
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>





