import { Component, OnInit, Output, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Recipe } from 'src/app/shared/interfaces/recipe';
import { Output as OutputType } from 'src/app/shared/interfaces/output';
import { RecipeCrudService } from 'src/app/shared/services/recipe-crud.service';
import { OutputCrudService } from 'src/app/shared/services/output-crud.service';
import { ClientCrudService } from 'src/app/shared/services/client-crud.service';
import { Client } from 'src/app/shared/interfaces/client';
import { Location } from 'src/app/shared/interfaces/location';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { Dropdown } from 'primeng/dropdown';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { Withdrawal } from 'src/app/shared/interfaces/withdrawal';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { WithdrawCrudService } from 'src/app/shared/services/withdraw-crud.service';

@Component({
  selector: 'app-withdraws',
  templateUrl: './withdraws.component.html',
  styleUrls: ['./withdraws.component.scss']
})
export class WithdrawsComponent implements OnInit {

  @ViewChild('dropdown') dropdown: Dropdown;

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  list1: any[];
  originalList1: any[];

  list2: any[];
  list2Map = new Map<string, boolean>();

  locations: Location[] = [];
  location: Location = {} as Location;
  locationKey : string = "";
  previousLocationKey : string = "";

  locationMap = new Map<string, Location>();

  locationTransformation : string = '';

  withdrawTypeMap = new Map<string, OutputType>();


  // Transformation 
  outputTypes: OutputType[] = [];
  outputType: OutputType = {} as OutputType;
  outputTypeKey : string = "";
  // transformedProductTypeOriginals : WoProductType[] = [];
  // transformedMap = new Map<string, WoProductType>();
  // previousTransformedTypeKey : string = "";
  clients: Client[];
  client: Client;
  clientKey : string = "";

    // Transformation 
    withdrawalProductTypes: WoProductType[] = [];
    withdrawalProductType: WoProductType = {} as WoProductType;
    withdrawalProductTypeKey : string = "";
    withdrawalProductTypeOriginals : WoProductType[] = [];
    withdrawalMap = new Map<string, WoProductType>();
    previousWithdrawTypeKey : string = "";

    quantity: string = '';


  // Constructor
  constructor(
      public  authService: AuthService,
      private productService: WoProductCrudService,
      private productTypeService: WoProductTypeCrudService,
      private withdrawService: WithdrawCrudService,
      private outputTypeCrudService : OutputCrudService,
      private clientCrudService : ClientCrudService,
      private eventService: WoEventCrudService,
      private confirmationService: ConfirmationService,
      private locationService: LocationCrudService,
      private messageService: MessageService,
      private router : Router
    ) {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Withdraw'}
    ];

    this.clients = [];
    this.client = {} as Client;

    this.list1 = [];
     this.originalList1 = Object.assign([], this.list1);
     this.list2 = [];
     this.locations = [];
     this.location = {} as Location;
     this.dropdown = {} as Dropdown;
   }

  ngOnInit(): void {

    let s = this.locationService.getLocationsList(); 
     s.snapshotChanges().subscribe(data => {
       this.locations = [];
       this.locationMap = new Map<string, Location>();
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
           const loc = jsonItem as Location;
           this.locationMap.set( loc.$key , loc );
           this.locations.push(loc);
         }
       })
     });
     this.locations = this.locations.sort((a, b) => (a.name < b.name) ? -1 : 1);

    let ot = this.outputTypeCrudService.getOutputsList(); 
    ot.snapshotChanges().subscribe(data => {
      this.outputTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.outputTypes.push(jsonItem as OutputType);
        }
      })
    })
    this.outputTypes = this.outputTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

    let c = this.clientCrudService.getClientsList(); 
    c.snapshotChanges().subscribe(data => {
      this.clients = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.clients.push(jsonItem as Client);
        }
      })
    });
    this.clients = this.clients.sort((a, b) => (a.companyName < b.companyName) ? -1 : 1);


    let pt = this.productTypeService.getWoProductTypesList(); 
     pt.snapshotChanges().subscribe(data => {
       this.withdrawalProductTypes = [];
       this.withdrawalProductTypeOriginals = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
           const productType = jsonItem as WoProductType;
           if( productType.soldable ) {
            this.withdrawalMap.set( productType.$key , productType );
            this.withdrawalProductTypes.push(jsonItem as WoProductType);
            //console.log('Product type soldable', productType);
          }
          //  if (productType.transformable) {
          //   //console.log('transformable', productType.name);
          //   this.withdrawalMap.set( productType.$key , jsonItem as WoProductType);
          // }
         }
       })
      // console.log('Product type soldable NB', this.withdrawalProductTypes.length);
       this.withdrawalProductTypes = Object.assign([],this.withdrawalProductTypes);
       this.withdrawalProductTypes = this.withdrawalProductTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

       this.withdrawalProductTypeOriginals = Object.assign([], this.withdrawalProductTypes);
       this.loadProducts();
      });

  }

  loadProducts() {
    let p = this.productService.getWoProductsList(); 
     p.snapshotChanges().subscribe(data => {
       this.list1 = [];
       this.originalList1 = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }

          const product = jsonItem as WoProduct;
          const productTypeKey : string = product.productTypeKey || '';
          const productType = this.withdrawalMap.get(productTypeKey);
          
          if (productType && productType.soldable) {
            this.list1.push(jsonItem as WoProduct);
            this.originalList1.push(jsonItem as WoProduct); 
            //console.log('LIST1', product);
          }
          
         }
       })
       this.originalList1 = this.originalList1.sort((a, b) => (a.name < b.name) ? -1 : 1);
       this.list1 = Object.assign([],this.originalList1);
     });
     this.list1 = Object.assign([],this.originalList1);
   }

   confirmIsOpen : boolean = false;
 
   moveToTarget(event: any) {
    this.confirm({} as Event, event.items);
    this.list2 = [...new Set(this.list2)];
   }
 
  confirm(event: Event, items : any[]) {
    try {
      this.confirmationService.confirm({
        target: event.target || undefined,
        message: 'Are you consuming all the entire product?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //confirm action
            items.forEach(
              item => {
                this.list2Map.set( item.name + item.batch, true);
              }
            );
           // this.confirmIsOpen = false;
        },
        reject: () => {
            //reject action
            if (items) {
              items.forEach( item => {

                this.list2Map.set( item.name + item.batch, false);
                
                const found = this.list1.find((litem) => {
                  return litem.name === item.name && litem.batch === item.batch;
                });
                
                if (found !== undefined) {
                  // 👇️ this runs
                 // console.log('✅ the object is contained in the array');
                } else {
                 // console.log('⛔️ the object is NOT contained in the array');
                  this.list1.push(item);
                  this.list1 = Object.assign([], this.list1);
                }
 
 
              }); 
            }
        }
    });
  } catch (e) {

  }
}
 
sourceReorder(event: any) {
 
}
 
moveToSource(event: any)  {
    const items = event.items;
    items.forEach( (item : any) => {
      const found = this.list1.find((litem) => {
        return litem.name === item.name && litem.batch === item.batch;
      });
      if (found !== undefined) {
       // console.log('✅ the object is contained in the array');
      } else {
       // console.log('⛔️ the object is NOT contained in the array');
        this.list1.push(item);
      }
    }); 
   this.list1 = [...new Set(this.list1)];
 
   items.forEach( (item : any) => {
    this.list2 = this.list2.filter(litem => {
      return litem.name !== item.name || litem.batch !== item.batch;
    });
    }); 
 
   this.list2 = [...new Set(this.list2)];

 
  }

  submit() {
   
  }

  disabled() : boolean {
    return this.list2.length < 1 
  }

  disabledPickList() : boolean {
    return this.list1.length < 1 && this.list2.length < 1 ;
   // return this.withdrawalProductTypeKey === '' || this.withdrawalProductTypeKey === undefined;
    // return !this.driedProductTypeKey;
    //  return this.driedProductTypeKey === '' || this.driedProductTypeKey === undefined ; 
  
   }

   filterList1(event : any) {

    //console.log(event);
    const productKey = event.value;

    //console.log('Product key', productKey);

    if ( !productKey) {
      //this.list1 = [...new Set(this.list1)];
      //this.transformedProductOriginals = [...new Set(this.transformedProductOriginals)];
      this.list1 = [...new Set(this.originalList1)];
      this.dropdown.disabled;
      return;
    }

    //console.log('Product transformed key', this.transformedProductTypeKey);
    if (this.previousWithdrawTypeKey === productKey ) {
      return;
    } 
    
    //console.log(' ... ');

    // We put again all the element before filtering
    this.list1 = [...new Set(this.originalList1)];
    
    //
    if (!this.withdrawalMap.has(productKey) ) {
      //.log('Transformed map does not contain ', productKey); 
      return;
    }

    
    const transformedProduct = this.withdrawalMap.get(productKey);
  //  console.log('transformed product ', transformedProduct);

    const inputs = transformedProduct?.inputs;
   // console.log('inputs ', inputs);


    const inputsArr : WoProductType[] = Object.assign([], inputs);
      
    const newList1 : WoProductType[] = [];

     // console.log('before compare');

      this.originalList1.forEach(
        (item: WoProduct) => {
          for(let input of inputsArr) {
            
            // console.log('compare');
            // console.log( input.name );
            // console.log( item.productType?.name );
            if (input.name === item.productType?.name) {
              newList1.push( item );
              break;
            }
          }
        }
      );

       this.list1 = Object.assign( [], newList1);

      // this.transformedProducts = this.transformedProducts.filter(item => {
      //    console.log('loc name', item.location);
      //    console.log('loca name', transformedName);
      //    return item.location !== transformedName;
      //  });
      //  this.transformedProductOriginals = [...new Set(this.transformedProducts)];
    
    
      
  }



 onSubmit(){
    
  if(this.list2.length > 0){
    
    const withdrawal : Withdrawal = {} as Withdrawal;
    withdrawal.when = new Date().toISOString();
    withdrawal.who = this.authService.userData.email || ' ';
    withdrawal.fromWhat = [];
    withdrawal.toWho = '';
    withdrawal.why = '';
    withdrawal.howMuch = this.quantity;


    withdrawal.toWho = this.clientKey;
    withdrawal.why = this.outputTypeKey;

    // if (!this.withdrawalProductTypeKey) {
    //   console.error('Missing product type to create - error 1');
    //   return;
    // }
    // if (!this.withdrawalMap.has(this.withdrawalProductTypeKey)) {
    //   console.error('Missing product type to create - error 2');
    //   return;
    // }
    // const selectedProductType = this.withdrawalMap.get(this.withdrawalProductTypeKey);


    // // Name of the new product to create
    // const selectedProductTypeName = selectedProductType?.name;

    // Batch of the new product
    const now = new Date();
    const batch = now.toISOString().replace(/[-T:Z\.]/g, "");

   

    // For the history, need to see the from products
    let histories : WoEvent[] = [];
    this.list2.forEach( (product : WoProduct)  => { 

      const newProduct : Partial<WoProduct> = {} as WoProduct;
      newProduct.name          = product.name;
      newProduct.batch         = batch;
      newProduct.dryable       = false;
      newProduct.dried         = false;
      newProduct.receivable    = false;
      newProduct.transformable = false;
      newProduct.transformed   = false;
      newProduct.soldable      = false;
      newProduct.sold          = true;
      newProduct.instant       = new Date().toISOString();
      newProduct.productTypeKey= product.productTypeKey;
      let productTypeCopy : Partial<WoProductType> = Object.assign({},product.productType);
      delete productTypeCopy.$key;
      newProduct.productType   =  productTypeCopy as WoProductType;
      
      if (!this.locationTransformation) {
        this.locationTransformation = 'Delivery area';
      }
      newProduct.location = this.locationTransformation;
      if(!newProduct.history) {
        newProduct.history = [];
      }

      console.log('NEW PRODUCT', newProduct);

      // For dry event
      const fromProduct : Partial<WoProduct> = Object.assign({}, product);
      delete fromProduct.$key;
      withdrawal.fromWhat.push(fromProduct as WoProduct);
      
      // For history
      let currentFromProductHistory = Object.assign([],product.history);
      currentFromProductHistory.forEach( (element: WoEvent)  => {
        let event : Partial<WoEvent> = Object.assign({},element);
        delete event?.$key;
        histories.push(element);
      });

      
      // histories.push(woEvent);

      // For consumption
      const k =  product.name+product.batch;
      if (this.list2Map.has(k) ) {
        const hasBeenConsumedEntirely = this.list2Map.get(k);
        if (hasBeenConsumedEntirely) {
         // console.log('entirely', product.$key);
          this.productService.deleteWoProduct(product.$key);
        }
      }

      // Dry event
    let newPorductWithoutHistory = Object.assign({},newProduct);
    delete newPorductWithoutHistory.history;

    // 3.B) for the event
   const woEvent : WoEvent = {
    which: 'WITHDRAWAL',
    what: withdrawal,
    who: this.authService.userData.email,
    why: 'HUMAN-CLICK',
    where: 'WEBSERVER'
   } as WoEvent;
   this.eventService.addWoEvent( woEvent );
   histories.push(woEvent);

   // console.log('histories', histories);
    newProduct.history = histories;
    
    

    // Save the new product
 //   console.log("New product", newProduct);
    this.productService.addWoProduct(newProduct as WoProduct);

    });
    
    
    // A REFAIRE transformation.toWho = newPorductWithoutHistory as WoProduct;

   


    
    this.withdrawService.addWithdrawal(withdrawal);
    
    this.router.navigate(['/withdrawal/withdrawal-table']);
    this.dropdown.resetFilter();
    this.quantity = '';

  }
}

   isSales() : boolean {
    //console.log(this.outputTypeKey);
    if (!this.outputTypeKey) {
      return false;
    }
    if (this.outputTypeKey == 'Sales' ) {
      return true;
    }
    return false;
   }
}
