
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Archive" icon="pi pi-upload" class="button-action p-button-warning" (click)="archiveSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length "></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="products" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','product','batch','client','location','tags','quantity']" 
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedProducts" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Title 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="batchId" name="batchId" pSortableColumn="batchId" pResizableColumn pReorderableColumn [class]="includesCol('batchId') ? 'd' : 'h'">Batch
                        <p-sortIcon field="batchId"></p-sortIcon> 
                    </th>
                    <th id="client" name="client" pSortableColumn="client" pResizableColumn pReorderableColumn [class]="includesCol('client') ? 'd' : 'h'">Client
                        <p-sortIcon field="client"></p-sortIcon> 
                        <p-columnFilter type="text" field="client" display="menu"></p-columnFilter> 
                        <!-- <p-columnFilter field="client" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Client picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="statuses" placeholder="Any" (onChange)="filter($event.value)" optionLabel="label">
                                    <ng-template let-option pTemplate="item">
                                        <div class="p-multiselect-representative-option">
                                            <span class="ml-1">{{option.label}}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    <th id="comment" name="comment" pSortableColumn="comment" pResizableColumn pReorderableColumn [class]="includesCol('comment') ? 'd' : 'h'">Comment
                        <p-sortIcon field="comment"></p-sortIcon> 
                        <p-columnFilter type="text" field="comment" display="menu"></p-columnFilter> 
                        
                    </th>

                    <th id="location" name="location" pSortableColumn="location" pResizableColumn pReorderableColumn [class]="includesCol('location') ? 'd' : 'h'">Location
                        <p-sortIcon field="location"></p-sortIcon> 
                        <p-columnFilter type="text" field="location" display="menu"></p-columnFilter>
                    </th>
               
                   
                    
                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn [class]="includesCol('quantity') ? 'd' : 'h'">Quantity
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter field="quantity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="activityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{activityValues[0]}}</span> &lt; x &lt;
                                    <span>{{activityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    

                    <th pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('actions') ? 'd' : 'h'">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" [pSelectableRow]="product">
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{product.position}}  {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="product"></p-tableCheckbox> -->
                    </td>

                    

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">

                                <span >{{product.name}}</span>

                    </td>

                    <td pEditableColumn [class]="includesCol('batchId') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="product.batch"  (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{product.batch}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('client') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.client" 
                                    [options]="clients" 
                                    optionLabel="companyName" 
                                    optionValue="companyName"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.client}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('comment') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="product.comment"  (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{product.comment}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.location" 
                                    [options]="locations" 
                                    optionLabel="title" 
                                    optionValue="title"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.location}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <!-- <td pEditableColumn [class]="includesCol('row') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.row" 
                                    [options]="rows" 
                                    optionLabel="title" 
                                    optionValue="title"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.row}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('column') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.column" 
                                    [options]="columns1" 
                                    optionLabel="title" 
                                    optionValue="title"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.column}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <!-- <td pEditableColumn [class]="includesCol('level') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.level" 
                                    [options]="levels" 
                                    optionLabel="title" 
                                    optionValue="title"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.level}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="product.quantity"  (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{product.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>



                   <td class="row-actions" [class]="includesCol('actions') ? 'd' : 'h'">
                        <button pButton style="margin-right: 10px" pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(product)"></button>
                        <button pButton style="margin-right: 10px" pRipple icon="pi pi-upload" class="p-button-rounded p-button-success p-mr-2" (click)="archiveProduct(product)"></button>
                        <button pButton style="margin-right: 10px" pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(product)"></button>
                        <!-- <p-tableCheckbox class="checkbox" [value]="product"></p-tableCheckbox> -->
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        <!-- Total: {{selectedProducts ? selectedProducts.length : 0 }} selected products.<br> -->
                        Total: {{products ? products.length : 0 }} products.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>


        <div class="stats">
            
                <h2>Selection </h2>
                <ul>
                    <li><b>Number of products:</b> {{selectedProducts ? selectedProducts.length : 0 }}</li>
                    <li><b>Quantity of boxes:</b> {{ computeQuantity() }} boxes</li>
                    <li><b>Number of clients:</b> {{ computeClients().length }} distinct clients</li>
                    <li><b>List of clients:</b>  {{ computeClients() }}</li>
                    <li><b>Number of locations:</b> {{ computeLocations().length }} distinct locations</li>
                    <li><b>List of locations:</b>  {{ computeLocations() }}</li>
                </ul>
       
        </div>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="productDialog" [style]="{width: '450px', height: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <!-- <div class="p-field" style="margin-top: 10px;">
                <label for="name">Title / Name / Product type</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
            </div> -->

            <div class="p-field" style="margin-top: 10px;">
                <label for="location">Product Type / Name</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="product.name" 
                    [options]="productTypes" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div>

            <div class="p-field" style="margin-top: 10px;">
                <label for="name">Batch</label>
                <input style="margin-top: 10px;" type="text" pInputText id="batch" [(ngModel)]="product.batch" autofocus />
                <!-- <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field" style="margin-top: 10px;">
                <label for="quantity">Quantity</label>
                <input style="margin-top: 10px;" type="number" pInputText id="quantity" [(ngModel)]="product.quantity" autofocus />
                <!-- <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small> -->
            </div>

            <div class="p-field">
                <label for="tags" >Tags</label>
                <div style="height: 10px;">&nbsp;</div>
                <p-chips  id="tags" [(ngModel)]="product.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
            </div>

            <!-- <div class="p-field">
                <label for="batch">Batch Id</label>
                <textarea style="margin-top: 10px;" id="batch" pInputTextarea [(ngModel)]="product.batch" rows="3" cols="20"></textarea>
            </div> -->

            <!-- <div class="p-field">
                <label for="client">Client</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="product.client" 
                    [options]="clients" 
                    optionLabel="companyName" 
                    optionValue="companyName"
                    placeholder="Any">
                </p-dropdown>
            </div> -->

            <div class="p-field">
                <label for="comment">Comment</label>
                <textarea style="margin-top: 10px;" id="comment" pInputTextarea [(ngModel)]="product.comment" rows="3" cols="20"></textarea>
            </div>

            <div class="p-field" style="margin-top: 10px;">
                <label for="location">Location</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="product.location" 
                    [options]="locations" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div>

            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveProduct()"></button>
            </div>
            
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>




