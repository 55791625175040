import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { Location } from 'src/app/shared/interfaces/location';
import { Dry } from 'src/app/shared/interfaces/dry';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';
import { DryCrudService } from 'src/app/shared/services/dry-crud.service';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';
import { Router } from '@angular/router';
import { Dropdown } from 'primeng/dropdown';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-dries',
  templateUrl: './dries.component.html',
  styleUrls: ['./dries.component.scss']
})
export class DriesComponent implements OnInit {

  @ViewChild('dropdown') dropdown: Dropdown;

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  list1: any[];
  originalList1: any[];

  list2: any[];
  list2Map = new Map<string, boolean>();

  locations: Location[] = [];
  location: Location = {} as Location;
  locationKey : string = "";
  previousLocationKey : string = "";

  locationMap = new Map<string, Location>();

  locationDrying : string = '';

  productTypeMap = new Map<string, WoProductType>();

  // Transformation - Drying
  driedProductTypes: WoProductType[] = [];
  driedProductType: WoProductType = {} as WoProductType;
  driedProductTypeKey : string = "";
  driedProductTypeOriginals : WoProductType[] = [];
  driedMap = new Map<string, WoProductType>();
  previousTransformedTypeKey : string = "";

  // Constructor
  constructor(
    public  authService: AuthService,
    private productService: WoProductCrudService,
    private productTypeService: WoProductTypeCrudService,
    private dryService: DryCrudService,
    private eventService: WoEventCrudService,
    private confirmationService: ConfirmationService,
    private changeDetectorRef: ChangeDetectorRef,
    private locationService: LocationCrudService,
    private messageService: MessageService,
  private router: Router
    ) {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Dry'}
    ];

    this.list1 = [];
    this.originalList1 = Object.assign([], this.list1);
    this.list2 = [];
    this.locations = [];
    this.location = {} as Location;
    this.dropdown = {} as Dropdown;
  }
 
   ngOnInit(): void {
 
    let s = this.locationService.getLocationsList(); 
    s.snapshotChanges().subscribe(data => {
      this.locations = [];
      this.locationMap = new Map<string, Location>();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          const loc = jsonItem as Location;
          this.locationMap.set( loc.$key , loc );
          this.locations.push(loc);
        }
      });
      this.locations = this.locations.sort((a, b) => (a.name < b.name) ? -1 : 1);
    });

    let pt = this.productTypeService.getWoProductTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.driedProductTypes = [];
      this.driedProductTypeOriginals = [];
      this.productTypeMap = new Map<string, WoProductType>();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          const productType = jsonItem as WoProductType;
          if( productType.dried ) {
           this.driedMap.set( productType.$key , productType );
           this.driedProductTypes.push(jsonItem as WoProductType);
          }
          if (productType.dryable) {
            //console.log('dryable', productType.name);
            this.productTypeMap.set( productType.$key , jsonItem as WoProductType);
          }
        }
      })
      this.driedProductTypes = this.driedProductTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

      this.driedProductTypes = Object.assign([],this.driedProductTypes);
      this.driedProductTypeOriginals = Object.assign([], this.driedProductTypes);
      this.loadProducts();
    });

    
 
    //  //this.dataState();
    //  let s = this.recipeCrudService.getRecipesList(); 
    //  s.snapshotChanges().subscribe(data => {
    //    this.recipes = [];
    //    data.forEach(item => {
    //      let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //      if (jsonItem) {
    //        if (jsonItem) {
    //          jsonItem['$key'] = item.key;
    //        }
    //        this.recipes.push(jsonItem as Recipe);
    //      }
    //    })
    //  });
 
   }

   loadProducts() {
    let p = this.productService.getWoProductsList(); 
    p.snapshotChanges().subscribe(data => {
      this.list1 = [];
      this.originalList1 = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }

          const product = jsonItem as WoProduct;
          const productTypeKey : string = product.productTypeKey || '';
          const productType = this.productTypeMap.get(productTypeKey);
          
          // console.log('PRODUCT', product);
          // console.log('PRODUCT TYPE KEY', productTypeKey);
          // console.log('PRODUCT TYPE', productType);
          if (productType && productType.dryable) {
            this.list1.push(jsonItem as WoProduct);
            this.originalList1.push(jsonItem as WoProduct); 
          }

          // if( product.dryable) {
          //  this.list1.push(jsonItem as WoProduct);
          //  this.originalList1.push(jsonItem as WoProduct);
          // }
              
        }
      })
      this.list1 = Object.assign([],this.originalList1);
    })
   }


   // EXPLICATIONS 
   // https://cdiese.fr/angular-customize-change-detection/

   confirmIsOpen : boolean = false; 

   moveToTarget(event: any) {
    // console.log('Moved All Items: ', event.items);
    this.confirm({} as Event, event.items);
    //this.showConfirm();
    this.list2 = [...new Set(this.list2)];
   }

   confirm(event: Event, items : any[]) {
    try {
      this.confirmationService.confirm({
        target: event.target || { } as EventTarget,
        message: 'Are you consuming all the input product?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //confirm action
            items.forEach(
              item => {
                this.list2Map.set( item.name + item.batch, true);
              }
            );
            //this.confirmIsOpen = false;

        },
        reject: () => {
            //reject action
            if (items) {
             
              items.forEach( item => {
                
                this.list2Map.set( item.name + item.batch, false);

                const found = this.list1.find((litem) => {
                  return litem.name === item.name && litem.batch === item.batch;
                });
                
                //console.log(found); // 👉️ {id: 1, name: 'Alfred'}
                
                if (found !== undefined) {
                  // 👇️ this runs
                  console.log('✅ the object is contained in the array');
                } else {
                  console.log('⛔️ the object is NOT contained in the array');
                  this.list1.push(item);
                  //  this.changeDetectorRef.markForCheck();
                  this.list1 = Object.assign([], this.list1);
                  //console.log(this.list1);
                }


              }); 
             // this.confirmIsOpen = false;
            }
        }
    });
    //console.log(this.list1);
    
    this.changeDetectorRef.detectChanges();
    } catch (e) {

    }
    
  }

  

  sourceReorder(event: any) {
    //console.log('Reorder source: ', event.items);
  }

  moveToSource(event: any)  {
    //console.log('Moved items to source: ', event.items);


    const items = event.items;
    items.forEach( (item : any) => {
      const found = this.list1.find((litem) => {
        return litem.name === item.name && litem.batch === item.batch;
      });
      //console.log(found); 
      if (found !== undefined) {
        console.log('✅ the object is contained in the array');
      } else {
        console.log('⛔️ the object is NOT contained in the array');
        this.list1.push(item);
        //  this.changeDetectorRef.markForCheck();
        
        //console.log(this.list1);
      }
    }); 
   // this.list1 = Object.assign([], this.list1);

   this.list1 = [...new Set(this.list1)];


   

   items.forEach( (item : any) => {
    this.list2 = this.list2.filter(litem => {
      return litem.name !== item.name || litem.batch !== item.batch;
    });
    }); 

   this.list2 = [...new Set(this.list2)];

    //console.log(this.list1);
    this.changeDetectorRef.detectChanges();
 
   }

  @ViewChild('confirmPopup', { static: true }) confirmPopup: ConfirmPopup = {} as ConfirmPopup;

    ngAfterViewInit() {
        // this.confirmPopup.style.position = 'fixed';
        // this.confirmPopup.style.top = '50%';
        // this.confirmPopup.style.left = '50%';
        //this.confirmPopup.style.transform = 'translate(-50%, -50%)';
    }


    submit() {
      //console.log('Date time', this.location);
      // console.log('Receiver', this.authService.userData.email);
      // console.log('Provider', this.providerKey);
      // console.log('Delivery man', this.deliveryManKey);
      // console.log('Label', this.label);
      // console.log('Notes', this.notes);
      // this.items.forEach( item => {
      //   console.log('Item quantity ', item.quantity );
      //   console.log('Item name ', item.name );
      // });
    }
   
     disabled() : boolean {
       return this.list2.length < 1 
     }
   
     disabledPickList() : boolean {
        return this.driedProductTypeKey === null || this.driedProductTypeKey === '' || this.driedProductTypeKey === undefined ; 
     }
   
     filterList1(event : any) {
 
       //console.log(event);
       const productKey = event.value;
   
       //console.log('Product key', productKey);
 
       if ( !productKey) {
         //this.list1 = [...new Set(this.list1)];
         //this.transformedProductOriginals = [...new Set(this.transformedProductOriginals)];
         this.list1 = [...new Set(this.originalList1)];
         this.dropdown.disabled;
         return;
       }
   
       //console.log('Product transformed key', this.driedProductTypeKey);
       if (this.previousTransformedTypeKey === productKey ) {
         return;
       } 
       
      // console.log(' ... ');
 
       // We put again all the element before filtering
       this.list1 = [...new Set(this.originalList1)];
       
       //
       if (!this.driedMap.has(productKey) ) {
         console.log('Dried map does not contain ', productKey); 
         return;
       }
 
       
       const transformedProduct = this.driedMap.get(productKey);
      // console.log('transformed product ', transformedProduct);
 
       const inputs = transformedProduct?.dryableInputs;
      // console.log('inputs ', inputs);
 
 
       const inputsArr : WoProductType[] = Object.assign([], inputs);
         
       const newList1 : WoProductType[] = [];
 
        // console.log('before compare');
 
         this.originalList1.forEach(
           (item: WoProduct) => {
             for(let input of inputsArr) {
              
              //  console.log('compare');
              //  console.log( input.name );
              //  console.log( item.productType?.name );
               if (input.name === item.productType?.name) {
                 newList1.push( item );
                 break;
               }
             }
           }
         );

        // this.list1
           
          this.list1 = Object.assign( [], newList1);
 
         // this.transformedProducts = this.transformedProducts.filter(item => {
         //    console.log('loc name', item.location);
         //    console.log('loca name', transformedName);
         //    return item.location !== transformedName;
         //  });
         //  this.transformedProductOriginals = [...new Set(this.transformedProducts)];
       
       
         
     }


    onSubmit(){
      
      if(this.list2.length > 0){
        
        // Create a Dry event
        const dry : Dry = {} as Dry;
        dry.when = new Date().toISOString();
        dry.who = this.authService.userData.email || ' ';
        dry.fromWhat = [];
        dry.toWhat = {} as WoProduct;

        // Create the new product
        //console.log('DRY' , dry );
        //console.log('DRY key' , this.driedProductTypeKey);
        if (!this.driedProductTypeKey) {
          console.error('Missing product type to create - error 1');
          return;
        }
        if (!this.driedMap.has(this.driedProductTypeKey)) {
          console.error('Missing product type to create - error 2');
          return;
        }
        const selectedProductType = this.driedMap.get(this.driedProductTypeKey);
        //console.log('DRY r' , selectedProductType);

        // Name of the new product to create
        const selectedProductTypeName = selectedProductType?.name;

        // Batch of the new product
        const now = new Date();
        const batch = now.toISOString().replace(/[-T:Z\.]/g, "");

        const newProduct : Partial<WoProduct> = {} as WoProduct;
        newProduct.name          = selectedProductTypeName;
        newProduct.batch         = batch;
        newProduct.dryable       = selectedProductType?.dryable       || false;
        newProduct.dried         = selectedProductType?.dried         || false;
        newProduct.receivable    = selectedProductType?.receivable    || false;
        newProduct.transformable = selectedProductType?.transformable || false;
        newProduct.transformed   = selectedProductType?.transformed   || false;
        newProduct.soldable      = selectedProductType?.soldable      || false;
        newProduct.instant       = new Date().toISOString();
        newProduct.productTypeKey= selectedProductType?.$key;
        let productTypeCopy : Partial<WoProductType> = Object.assign({},selectedProductType);
        delete productTypeCopy.$key;
        newProduct.productType   =  productTypeCopy as WoProductType;
        
        if (!this.locationDrying) {
          this.locationDrying = 'Drying area';
        }
        newProduct.location = this.locationDrying;
        if(!newProduct.history) {
          newProduct.history = [];
        }

        // For the history, need to see the from products
        let histories : WoEvent[] = [];
        this.list2.forEach( (product : WoProduct)  => { 

          // For dry event
          const fromProduct : Partial<WoProduct> = Object.assign({}, product);
          delete fromProduct.$key;
          dry.fromWhat.push(fromProduct as WoProduct);
          
          // For history
          let currentFromProductHistory = Object.assign([],product.history);
          currentFromProductHistory.forEach( (element: WoEvent)  => {
            let event : Partial<WoEvent> = Object.assign({},element);
            delete event?.$key;
            histories.push(element);
          });

          
          // histories.push(woEvent);

          // For consumption
          const k =  product.name+product.batch;
          if (this.list2Map.has(k) ) {
            const hasBeenConsumedEntirely = this.list2Map.get(k);
            if (hasBeenConsumedEntirely) {
              console.log('entirely', product.$key);
              this.productService.deleteWoProduct(product.$key);
            }
          }

        });
        
        // Dry event
        let newPorductWithoutHistory = Object.assign({},newProduct);
        delete newPorductWithoutHistory.history;
        dry.toWhat = newPorductWithoutHistory as WoProduct;
  
       // 3.B) for the event
       const woEvent : WoEvent = {
        which: 'DRY',
        when: new Date().toISOString(),
        what: dry,
        who: this.authService.userData.email,
        why: 'HUMAN-CLICK',
        where: 'WEBSERVER'
       } as WoEvent;
       this.eventService.addWoEvent( woEvent );
       histories.push(woEvent);

        //console.log('histories', histories);
        newProduct.history = histories;
        
        

        // Save the new product
     //   console.log("New product", newProduct);
        this.productService.addWoProduct(newProduct as WoProduct);


        // Loop on the products
    //    this.list2.forEach( (product : WoProduct)  => { 

          // New product
          // const newProduct : Partial<WoProduct> = Object.assign({}, product);
          // newProduct.name = 'Dried ' + product.name;

          // const eventProduct : Partial<WoProduct> = Object.assign({}, product);
          // delete eventProduct.$key;

          // // Event
          // const woEvent : WoEvent = {
          //   which: 'DRY',
          //   what: eventProduct as WoProduct,
          //   who: 'receiver',
          //   why: 'HUMAN-CLICK',
          //   where: 'WEBSERVER'
          // } as WoEvent;
    
          // 3.B) for the event
          //this.eventService.addWoEvent( woEvent );

          // Update New Product
          // if(!newProduct.history) {
          //   newProduct.history = [];
          // }
          // let histories = Object.assign([],newProduct.history);
          // histories.push(woEvent); 
          // console.log('histories',histories);
          // newProduct.history = histories;
          // newProduct.dryable = false;

          // if (!this.locationDrying) {
          //   this.locationDrying = 'Drying area';
          // }
          // newProduct.location = this.locationDrying;

          
          // update product type
          
          
          // Update evenet

          // if (newProduct.$key) {
          //   delete newProduct.$key;
          // }
          // this.productService.addWoProduct(newProduct as WoProduct);
          
          // const fromProduct : Partial<WoProduct> = Object.assign({}, product);
          // delete fromProduct.$key;

          // dry.fromWhat.push(fromProduct as WoProduct);
          // dry.toWhat.push(newProduct as WoProduct);

          // const k =  product.name+product.batch;
          // if (this.list2Map.has(k) ) {
          //   const hasBeenConsumedEntirely = this.list2Map.get(k);

          //   if (hasBeenConsumedEntirely) {
          //     console.log('entirely', product.$key);
          //     this.productService.deleteWoProduct(product.$key);
          //   }

          // }
         
     //   });

       
       

       this.dryService.addDry(dry);
       this.router.navigate(['/transformation/dry-table']);
       this.dropdown.resetFilter();
      }
    }

    onConfirm() {
      this.messageService.clear('c');
    }
  
    onReject() {
      this.messageService.clear('c');
    }

    showConfirm() {
      this.messageService.clear();
      this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Are you sure?', detail:'Confirm to proceed'});
    }

}
