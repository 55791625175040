import { Injectable } from '@angular/core';
import { WoProductType } from '../interfaces/wo-product-type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoProductTypeCrudService {

  woProductTypesRef: AngularFireList<any> | undefined;
  woProductTypeRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/productTypes/' ;
  }

  // Create WoProductType
  addWoProductType(woProductType: WoProductType) {
    const item = {
      ...woProductType
    }
    const key : string = this.woProductTypesRef?.push(item).key || 'err';
    
    // this.woProductTypeRef?.set(item);
    
    this.woProductTypeRef = this.db.object( this.path + key);
    item.key = key;
    this.woProductTypeRef.update(item);
    //this.woProductTypesRef?.push( item );
  }
  // Fetch Single WoProductType Object
  getWoProductType(id: string) {
    this.woProductTypeRef = this.db.object(this.path + id);
    return this.woProductTypeRef;
  }
  // Fetch WoProductTypes List
  getWoProductTypesList() {
    this.woProductTypesRef = this.db.list(this.path + '/');
    return this.woProductTypesRef;
  }
  // Update WoProductType Object
  updateWoProductType(woProductType: WoProductType) {
    this.woProductTypeRef = this.db.object( this.path + woProductType.$key);
   // console.log("P inside", woProductType);
    let item : Partial<WoProductType>  = {
      ...woProductType
    }
    delete item?.$key;
    this.woProductTypeRef?.update(item);
  }
  // Delete WoProductType Object
  deleteWoProductType(id: string) {
    this.woProductTypeRef = this.db.object( this.path + '/' + id);
    this.woProductTypeRef.remove();
  }
}
