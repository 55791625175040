import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TransformService } from 'src/app/shared/services/transform.service';

@Component({
  selector: 'app-transform-confirmation',
  templateUrl: './transform-confirmation.component.html',
  styleUrls: ['./transform-confirmation.component.scss']
})
export class TransformConfirmationComponent implements OnInit {

  items: MenuItem[] = [];
    
  subscription: Subscription = {} as Subscription;

  activeIndex: number = 2;

  constructor(public transformService : TransformService, public messageService: MessageService) { }

  ngOnInit() {
    this.items = [{
            label: 'Selection',
            routerLink: '/transform/selection'
        },
        {
            label: 'Transformation',
            routerLink: '/transform/transformation'
        },
        {
            label: 'Confirmation',
            routerLink: '/transform/confirmation'
        }
    ];

    this.subscription = this.transformService.paymentComplete$.subscribe((personalInformation) =>{
        this.messageService.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + personalInformation.firstname + ' ' + personalInformation.lastname + ' your order completed.'});
    });
  }

  chan() {
    this.activeIndex += 1;
  }

  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

}
