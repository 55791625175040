import { Injectable } from '@angular/core';
import { Recipe } from '../interfaces/recipe';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class RecipeCrudService {

  recipesRef: AngularFireList<any> | undefined;
  recipeRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  MODULE_NAME : string = 'transformation';
  TABLE_NAME : string = 'recipes';

  // Create Recipe
  addRecipe(recipe: Recipe) {
    const item = {
      ...recipe
    }
    this.recipesRef?.push( item );
  }
  // Fetch Single Recipe Object
  getRecipe(id: string) {
    this.recipeRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    return this.recipeRef;
  }
  // Fetch Recipes List
  getRecipesList() {
    this.recipesRef = this.db.list(this.MODULE_NAME + '/' + this.TABLE_NAME + '/');
    return this.recipesRef;
  }
  // Update Recipe Object
  updateRecipe(recipe: Recipe) {
    this.recipeRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + recipe.$key);
   // console.log("P inside", recipe);
    let item : Partial<Recipe>  = {
      ...recipe
    }
    delete item?.$key;
    this.recipeRef?.update(item);
  }
  // Delete Recipe Object
  deleteRecipe(id: string) {
    this.recipeRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    this.recipeRef.remove();
  }
}
