import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { ColourCrudService } from 'src/app/shared/services/colour-crud.service';
import { Colour } from 'src/app/shared/interfaces/colour';



@Component({
  selector: 'app-colours',
  templateUrl: './colours.component.html',
  styleUrls: ['./colours.component.scss']
})
export class ColoursComponent implements OnInit {

  colours: Colour[];
  colour: Colour;
  selectedColours: Colour[] = [];

  colourDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private colourService : ColourCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Colours'}
    ];

    this.colours = [];
    this.colour = { } as Colour ;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.colourService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.colours = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Colour = jsonItem as Colour;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.colours.push(l);
        }
      })
    })

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.colourService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.colours);
            doc.save('colours.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.colours);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "colours");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedColours);
      // console.log('SAVEC CSV LOCATIONS ', this.colours);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedColours, 'colours', exportCols);
   // this.exportService.exportToCsv(this.colours, 'colours', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.colour = {} as Colour;
    this.submitted = false;
    this.colourDialog = true;
  }

  deleteSelectedColours() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected colours?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.colours = this.colours.filter(val => !this.selectedColours.includes(val));
            this.selectedColours = [];
            this.selectedColours.forEach( colour => this.colourService.delete(colour.$key));
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Colourss Deleted', life: 3000});
        }
    });
  }

  editColour(colour: Colour) {
    this.colour = {...colour};
    this.colourDialog = true;
  }

  deleteColour(colour: Colour) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + colour.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.colours = this.colours.filter(val => val.$key !== colour.$key);
            this.colour = {} as Colour;
            this.colourService.delete(colour.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Colour Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.colourDialog = false;
    this.submitted = false;
  }

  saveColour() {
    this.submitted = true;

    if ( this.colour.name.trim() ) {
      if (this.colour.$key) {
          this.colours[this.findIndexById(this.colour.$key)] = this.colour;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Colour Updated', life: 3000});
          this.colourService.update(this.colour);
        } else {
          this.colour.position = this.colours.length;
          this.colourService.add(this.colour);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Colour Created', life: 3000});
      this.colours = [...this.colours];
    }

    this.colourDialog = false;
    this.colour = { } as Colour;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.colours.length; i++) {
        if (this.colours[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeColour(colour : Colour) {
    //console.log("SAVE", colour);
    this.colourService.update(colour);
  }

  completePosition(colour : Colour) {
   // console.log("SAVE", colour);
  //  this.colourService.updateColour(colour);


    if ( this.newPosition > -1 ) {
        colour.position = this.newPosition;
        colour = this.checkPositionLimits(colour);
        this.reorderElements(colour);
        //this.updateDB(colour);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.colours);
    // const myClonedArray = [];
    // this.colours.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.colours.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.colours.length - 1 - event.dropIndex;
    const colourToMove = this.colours[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.colours[index] = this.colours[index+1];
        this.colours[index].position = index;
        this.colourService.update(this.colours[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.colours[index] = this.colours[index-1];
        this.colours[index].position = index;
        this.colourService.update(this.colours[index]);
      }
    } 
    this.colours[endIndex] = colourToMove;
    this.colours[endIndex].position = endIndex;
    this.colourService.update(this.colours[endIndex]);
  }

  // onFieldEdit(colour: Spray, fieldName: string): void {
  //   //console.log(this.colours);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + colour[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       colour.position = this.newPosition;
  //       colour = this.checkPositionLimits(colour);
  //       this.reorderElements(colour);
  //       //this.updateDB(colour);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(colour);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.colours.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, colour: Colour) {
    // console.log("event" +event);
    if (colour.position != null && colour.position > -1) {
      this.previousPosition = colour.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(colour: Colour) {
    console.log('check ',colour.position + ' ' + this.colours.length);
    if (!colour.position) {
     // console.log('check 1');
      return this.colour;
    }
    if (colour.position < 0) {
      colour.position = 0;
      // console.log('check 2');
      return colour;
    }
    if (colour.position > this.colours.length) {
      colour.position = this.colours.length - 1;
    //  console.log('check 3', colour.position);
      return colour;
    }
   // console.log('check 4');
    return colour;
  }

  async reorderElements(colour: Colour) {
    this.newPosition = this.newPosition  > this.colours.length ? this.colours.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === colour.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const colourToMove = this.colours[startIndex];
   // console.log('Item to move: ', this.colours[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.colours);
        console.log('Item to +1: ', this.colours[index+1]);

        this.colours[index] = this.colours[index+1];
        this.colours[index].position = index;
        // console.log('Move: ', this.colours[index+1]);
        // console.log('Move pos: ', this.colours[index].position);
        //await this.updateDB(this.colours[index]);
        this.colourService.update(this.colours[index]);
     //   console.log('Update DB: ', this.colours[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.colours[index] = this.colours[index-1];
        this.colours[index].position = index;
        this.colourService.update(this.colours[index]);
      }
    } 
    this.colours[endIndex] = colourToMove;
    this.colours[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.colourService.update(this.colours[endIndex]);
    
    // const myClonedArray = [];
    // this.colours.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.colours = myClonedArray;
   // this.getAllRecords();
  }
}
