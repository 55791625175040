import { Injectable } from '@angular/core';
import { Dry } from '../interfaces/dry';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DryCrudService {

  drysRef: AngularFireList<any> | undefined;
  dryRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/drying/' ;
  }

  // Create Dry
  addDry(dry: Dry) {
    this.drysRef = this.db.list(this.path);
  
    const item = {
      ...dry
    }
    this.drysRef?.push( item );
  }
  // Fetch Single Dry Object
  getDry(id: string) {
    this.dryRef = this.db.object(this.path + id);
    return this.dryRef;
  }
  // Fetch Drys List
  getDrysList() {
    this.drysRef = this.db.list(this.path);
    return this.drysRef;
  }
  // Update Dry Object
  updateDry(dry: Dry) {
    this.dryRef = this.db.object(this.path + dry.$key);
   // console.log("P inside", woDry);
    let item : Partial<Dry>  = {
      ...dry
    }
    delete item?.$key;
    this.dryRef?.update(item);
  }
  // Delete Dry Object
  deleteDry(id: string) {
    this.dryRef = this.db.object(this.path + id);
    this.dryRef.remove();
  }
}
