import { Component, OnInit, Input, ViewChild } from '@angular/core';


import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { Unit } from 'src/app/shared/interfaces/unit';
import { ContentType } from 'src/app/shared/interfaces/content-type';
import { PackagingType } from 'src/app/shared/interfaces/packaging-type';
import { ContentTypeCrudService } from 'src/app/shared/services/content-type-crud.service';
import { PackagingTypeCrudService } from 'src/app/shared/services/packaging-type-crud.service';
import { ProductCrudService } from 'src/app/shared/services/product-crud.service';
import { Product } from 'src/app/shared/interfaces/product';

@Component({
  selector: 'app-products-per-type',
  templateUrl: './products-per-type.component.html',
  styleUrls: ['./products-per-type.component.scss']
})
export class ProductsPerTypeComponent implements OnInit {

  productTypes: Product[];
  productType: Product;
  selectedProducts: Product[] = [];

  productDialog: boolean = false;

  products: Product[] = [];
  product: Product = {} as Product;

  submitted: boolean = false;

  productQuantityMap = new Map<string, number>();
  productProductsMap = new Map<string, Product[]>();

  units: Unit[] = [];
  contentTypes: ContentType[] = [];
  packagingTypes: PackagingType[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  contentTypeMap = new Map<string, ContentType>();
  packagingTypeMap = new Map<string, PackagingType>();

  dialogSelectContentKey : string = "";
  dialogSelectPackagingKey : string = "";

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private productTypeService : ProductCrudService,
    // private unitService : UnitCrudService,
    private productService : ProductCrudService,
    private contentTypeService : ContentTypeCrudService,
    private packagingTypeService : PackagingTypeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Products'}
    ];

    this.productTypes = [];
    this.productType = { } as Product ;
    //this.getAllRecords();

    // productService.addProduct( {name: 'a'} );
    // productService.addProduct( {name: 'a'} );
    // productService.addProduct( {name: 'd'} );
    // productService.addProduct( {name: 'a'} );
    // productService.addProduct( {name: 'b'} );
    // productService.addProduct( {name: 'a'} );
    // productService.addProduct( {name: 'd'} );
    // productService.addProduct( {name: 'd'} );
    // productService.addProduct( {name: 'a'} );
  }

  ngOnInit(): void {
    
    this.dataState();
    // let s = this.productTypeService.GetProductsList(); 
    // s.snapshotChanges().subscribe(data => {
    //   this.productTypes = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.productTypes.push(jsonItem as Product);
    //     }
    //   })
    // });

    let p = this.productService.getProductsList(); 
    p.snapshotChanges().subscribe(data => {
      this.products = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
         //console.log(jsonItem);
          this.products.push(jsonItem as Product);
        }
      });
      this.refreshProducts(this.products);
    });

    

    // let u = this.unitService.GetUnitsList(); 
    // u.snapshotChanges().subscribe(data => {
    //   this.units = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.units.push(jsonItem as unknown as Unit);
    //     }
    //   })
    // });

    let ct = this.contentTypeService.GetContentTypesList(); 
    ct.snapshotChanges().subscribe(data => {
      this.contentTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.contentTypes.push(jsonItem as unknown as ContentType);
          const content = jsonItem as unknown as ContentType;
          this.contentTypeMap.set( content.$key , content );
        }
      })
    });

    let pt = this.packagingTypeService.GetPackagingTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.packagingTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.packagingTypes.push(jsonItem as unknown as PackagingType);
          const packaging = jsonItem as unknown as PackagingType;
          this.packagingTypeMap.set( packaging.$key , packaging );
        }
      })
    });

    // column management
    this.cols = [
      { field: 'id', header: 'QRCode' },
      { field: 'label', header: 'Label' },
      { field: 'location', header: 'Location' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'products', header: 'Products' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  refreshProducts(products: Product[]) {
    // this.products = [];
    // this.productQuantityMap = new Map<string, number>();
    // this.productProductsMap = new Map<string, Product[]>();
    // products.forEach( product => {
    //   const productName = product.label;
    //   if (!productName) {
    //     console.error(product);
    //   } else {
    //     if ( !this.productQuantityMap.has(productName) ) {
    //       this.productQuantityMap.set(productName, 0);
    //     } 
    //     let quantity : number = this.productQuantityMap.get(productName) as number;
    //     this.productQuantityMap.set(productName, (quantity + 1));
    //   }

    //   if (!productName) {
    //     console.error(product);
    //   } else {
    //     if ( !this.productProductsMap.has(productName) ) {
    //       this.productProductsMap.set(productName, []);
    //     } 
    //     let ps : Product[]  = this.productProductsMap.get(productName) as Product[];
    //     ps.push(product);
    //     this.productProductsMap.set(productName, ps);
    //   }
      
    // });
    // for ( const [key, value] of this.productQuantityMap ) {
    //   this.products.push( { quantity: value, productTypeName: key , products: this.productProductsMap.get(key) }  ); 
    // }
  }

  dataState() {     
    this.productService.getProductsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Products);
            doc.save('content-types.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.productTypes);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "content-types");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.productTypes, 'Products', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedProducts, 'content-types', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.productType = {} as Product;
    this.submitted = false;
    this.productDialog = true;
    this.dialogSelectContentKey = "";
    this.dialogSelectPackagingKey = "";
  }

  deleteSelectedProducts() {
  //   this.confirmationService.confirm({
  //       message: 'Are you sure you want to delete the selected Packaging Types?',
  //       header: 'Confirm',
  //       icon: 'pi pi-exclamation-triangle',
  //       accept: () => {
  //         this.productTypes = this.productTypes.filter(val => !this.selectedProducts.includes(val));
  //         this.productTypes.forEach( pallet => {
  //           this.productTypeService.DeleteProduct(pallet.$key);
  //         } );
          
  //         this.selectedProducts = [];
  //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging types deleted', life: 3000});
  //     }
  // });
  }

  editProduct(productType: Product) {
    this.productType = {...productType};
    this.productDialog = true;
  }

  deleteProduct(productType: Product) {
    // this.confirmationService.confirm({
    //     message: 'Are you sure you want to delete ' + productType.name + '?',
    //     header: 'Confirm',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: () => {
    //         this.productTypes = this.productTypes.filter(val => val.$key !== productType.$key);
    //         this.productType = {} as Product;
    //         this.productTypeService.DeleteProduct(productType.$key);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Deleted', life: 3000});
    //     }
    // });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    // this.submitted = true;
    // if (this.productType.$key) {
    //   this.productTypes[this.findIndexById(this.productType.$key)] = this.productType;                
    //   this.productTypeService.UpdateProduct(this.productType);
    //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Updated', life: 3000});
    // } else {
    //   // this.pallet.id = this.createId();
    //   // this.pallet.image = 'product-placeholder.svg';

    //   if ( this.packagingTypeMap.has( this.dialogSelectPackagingKey ) ) {
    //     const packaging = this.packagingTypeMap.get( this.dialogSelectPackagingKey );
    //     if (packaging) {
    //       this.productType.container = packaging ;
    //       if (this.productType.container.$key) {
    //         delete this.productType.container.$key
    //         delete this.productType.container.isEnabled
    //       }
    //     }
    //   }

    //   if ( this.contentTypeMap.has( this.dialogSelectContentKey ) ) {
    //     const content = this.contentTypeMap.get( this.dialogSelectContentKey );
    //     if (content) {
    //       this.productType.content = content;
    //       if (this.productType.content.$key) {
    //         delete this.productType.content.$key
    //         delete this.productType.content.isEnabled
    //       }
    //     }
    //   }
      
      
    //   // dialogSelectContentKey = "";
    //   //  = "";)

    //   this.productTypeService.AddProduct(this.productType);
    //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Type Created', life: 3000});
    //   this.productTypes = [...this.productTypes];
    // }
    // this.productDialog = false;
    // this.productType = { } as Product;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.productTypes.length; i++) {
        if (this.productTypes[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeProduct(productType : Product) {
    // const productTypeTmp = {
    //   ...productType
    // };
    // console.log("SAVE", productTypeTmp);
    // this.productTypeService.UpdateProduct(  productTypeTmp );
  }


}
