import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem, SelectItemGroup } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { DryCrudService } from 'src/app/shared/services/dry-crud.service';
import { Dry } from 'src/app/shared/interfaces/dry';
import { Unit } from 'src/app/shared/interfaces/unit';
import { ContentType } from 'src/app/shared/interfaces/content-type';
import { PackagingType } from 'src/app/shared/interfaces/packaging-type';
import { ContentTypeCrudService } from 'src/app/shared/services/content-type-crud.service';
import { PackagingTypeCrudService } from 'src/app/shared/services/packaging-type-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';

@Component({
  selector: 'app-dry-table',
  templateUrl: './dry-table.component.html',
  styleUrls: ['./dry-table.component.scss']
})
export class DryTableComponent implements OnInit {

  dries: Dry[];
  dry: Dry;
  selectedDries: Dry[] = [];

  dryDialog: boolean = false;

  submitted: boolean = false;

  units: Unit[] = [];
  contentTypes: ContentType[] = [];
  packagingTypes: PackagingType[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  contentTypeMap = new Map<string, ContentType>();
  packagingTypeMap = new Map<string, PackagingType>();

  dialogSelectContentKey : string = "";
  dialogSelectPackagingKey : string = "";

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  groupedCities: SelectItemGroup[];
  selectedCities4: any[] = [];

  constructor(
   // private api: APIService,
    private dryService : DryCrudService,
    private contentTypeService : ContentTypeCrudService,
    private packagingTypeService : PackagingTypeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Tables'},
      {label: 'Dry table'}
    ];

    this.dries = [];
    this.dry = { } as Dry ;
    //this.getAllRecords();

    this.groupedCities = [
      {
          label: 'Germany', value: 'de',
          items: [
              {label: 'Berlin', value: 'Berlin'},
              {label: 'Frankfurt', value: 'Frankfurt'},
              {label: 'Hamburg', value: 'Hamburg'},
              {label: 'Munich', value: 'Munich'}
          ]
      },
      {
          label: 'USA', value: 'us',
          items: [
              {label: 'Chicago', value: 'Chicago'},
              {label: 'Los Angeles', value: 'Los Angeles'},
              {label: 'New York', value: 'New York'},
              {label: 'San Francisco', value: 'San Francisco'}
          ]
      },
      {
          label: 'Japan', value: 'jp',
          items: [
              {label: 'Kyoto', value: 'Kyoto'},
              {label: 'Osaka', value: 'Osaka'},
              {label: 'Tokyo', value: 'Tokyo'},
              {label: 'Yokohama', value: 'Yokohama'}
          ]
      }
  ];
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.dryService.getDrysList(); 
    s.snapshotChanges().subscribe(data => {
      this.dries = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.dries.push(jsonItem as Dry);
          console.log('DRY', jsonItem as Dry);
          const dry = jsonItem as Dry;
          console.log(dry.toWhat);

          //dry.toWhat + " - " + dry.name;
        }
      })
    });

    // let u = this.unitService.GetUnitsList(); 
    // u.snapshotChanges().subscribe(data => {
    //   this.units = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.units.push(jsonItem as unknown as Unit);
    //     }
    //   })
    // });

    let ct = this.contentTypeService.GetContentTypesList(); 
    ct.snapshotChanges().subscribe(data => {
      this.contentTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.contentTypes.push(jsonItem as unknown as ContentType);
          const content = jsonItem as unknown as ContentType;
          this.contentTypeMap.set( content.$key , content );
        }
      })
    });

    let pt = this.packagingTypeService.GetPackagingTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.packagingTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.packagingTypes.push(jsonItem as unknown as PackagingType);
          const packaging = jsonItem as unknown as PackagingType;
          this.packagingTypeMap.set( packaging.$key , packaging );
        }
      })
    });

    // column management
    this.cols = [
      { field: 'when', header: 'Date and Time' },
      { field: 'who', header: 'User' },
      { field: 'fromWhat', header: 'Inputs' },
      { field: 'toWhat', header: 'Outputs' }
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.dryService.getDrysList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0) {
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Dries);
            doc.save('dries.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.dries);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "dries");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.dries, 'Dries', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedDries, 'dries', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.dry = {} as Dry;
    this.submitted = false;
    this.dryDialog = true;
    this.dialogSelectContentKey = "";
    this.dialogSelectPackagingKey = "";
  }

  deleteSelectedDries() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Dries?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.dries = this.dries.filter(val => !this.selectedDries.includes(val));
          this.dries.forEach( pallet => {
            this.dryService.deleteDry(pallet.$key);
          } );
          
          this.selectedDries = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dry deleted', life: 3000});
      }
  });
  }

  editDry(dry: Dry) {
    this.dry = {...dry};
    this.dryDialog = true;
  }

  deleteDry(dry: Dry) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + dry.key + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.dries = this.dries.filter(val => val.$key !== dry.$key);
            this.dry = {} as Dry;
            this.dryService.deleteDry(dry.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tranformation deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.dryDialog = false;
    this.submitted = false;
  }

  saveDry() {
    this.submitted = true;
    if (this.dry.$key) {
      this.dries[this.findIndexById(this.dry.$key)] = this.dry;                
      this.dryService.updateDry(this.dry);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dry updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';

      if ( this.packagingTypeMap.has( this.dialogSelectPackagingKey ) ) {
        const packaging = this.packagingTypeMap.get( this.dialogSelectPackagingKey );
        // if (packaging) {
        //   this.dry.container = packaging ;
        //   if (this.dry.container.$key) {
        //     delete this.dry.container.$key
        //     delete this.dry.container.isEnabled
        //   }
        // }
      }

      if ( this.contentTypeMap.has( this.dialogSelectContentKey ) ) {
        const content = this.contentTypeMap.get( this.dialogSelectContentKey );
        // if (content) {
        //   this.dry.content = content;
        //   if (this.dry.content.$key) {
        //     delete this.dry.content.$key
        //     delete this.dry.content.isEnabled
        //   }
        // }
      }
      
      
      // dialogSelectContentKey = "";
      //  = "";)

      this.dryService.addDry(this.dry);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dry created', life: 3000});
      this.dries = [...this.dries];
    }
    this.dryDialog = false;
    this.dry = { } as Dry;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.dries.length; i++) {
        if (this.dries[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeDry(dry : Dry) {
    const dryTmp = {
      ...dry
    };
    console.log("SAVE", dryTmp);
    this.dryService.updateDry(  dryTmp );
  }


  displayFromWhat(dry : Dry) : WoProduct[] {
    if ( dry.fromWhat ) {
      const array : any[] = Object.values(dry.fromWhat) as WoProduct[];
      return array;
    }
    return [] as WoProduct[];
  }

  a(dry : Dry) : any[] {
    console.log('DRU FROM WHAT', dry.fromWhat['0']);
    console.log('DRU FROM WHAT0', Object.values(dry.fromWhat) );

    const ar : string[] = [];
    const rs = Object.values(dry.fromWhat) ;
    rs.forEach( 
      (item : WoProduct) => { 
        rs.push(item); 
        console.log(item); 
        let s1 = item.name;
        let s0 = ' - ';
        let s2 = item.batch;
        if (item) {
          ar.push( s1 + s0 + s2 );
        }
      } 
    );
    return ar;
  }

}