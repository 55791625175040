import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wo-recipes',
  templateUrl: './wo-recipes.component.html',
  styleUrls: ['./wo-recipes.component.scss']
})
export class WoRecipesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
