import { Component, OnInit } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-moves',
  templateUrl: './moves.component.html',
  styleUrls: ['./moves.component.scss']
})
export class MovesComponent implements OnInit {

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  constructor() {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Moves'}
    ];
   }

  ngOnInit(): void {
  }

}
