import { Injectable } from '@angular/core';
import { ProductType } from '../interfaces/product-type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeCrudService {

  ProductTypesRef: AngularFireList<any> | undefined;
  ProductTypeRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create ProductType
  AddProductType(productType: ProductType) {
    console.log("Add", productType)
    this.ProductTypesRef?.push({
      name: productType.name,
      content: productType.content,
      container: productType.container,
      isEnabled: productType.isEnabled? true : false
    });
  }
  // Fetch Single ProductType Object
  GetProductType(id: string) {
    this.ProductTypeRef = this.db.object('ProductTypes-list/' + id);
    return this.ProductTypeRef;
  }
  // Fetch ProductTypes List
  GetProductTypesList() {
    this.ProductTypesRef = this.db.list('ProductTypes-list');
    return this.ProductTypesRef;
  }
  // Update ProductType Object
  UpdateProductType(productType: ProductType) {
    // this.PackagingTypeRef = this.db.object('PackagingTypes-list/' + packagingType.$key);
    // let tmp : Partial<PackagingType>  = {
    //   ...packagingType
    // }
    // delete tmp?.$key;
    // this.PackagingTypeRef?.update(tmp);
  }
  // Delete ProductType Object
  DeleteProductType(id: string) {
    this.ProductTypeRef = this.db.object('ProductTypes-list/' + id);
    this.ProductTypeRef.remove();
  }
}
