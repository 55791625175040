<div class="container" style="flex: 1">

  <div class="column mat-elevation-z4" style="width: 285px">

  <form [formGroup]="forgotPasswordForm" class="column">

    <div class="title">
      <span class="greytext title-size">Fogotten password - Reset</span>
    </div>

    <!-- <p class="text-center">Please enter your email address to request a password reset.</p> -->

    <div class="input-column">
      <mat-form-field style="width: 100%;">
        <input matInput placeholder="Email" type="text" formControlName="email">
      </mat-form-field>
    </div>

    <!-- <div style="width: 100%;" style="width: 100%;margin-top: 20px;margin-bottom: 20px;display: flex; flex-direction: row;justify-content: space-between;"> -->
    <div class="button" style="margin-bottom: 30px;">  
      <button mat-raised-button style="background-color: #e1e1e1; color: #58585e;" (click)="sendEmail()">Send</button>
      <!-- <p>Vous n'avez pas reçcu de code? <a href="javascript:void(null)" (click)="sendAgain()">Envoyer de nouveau</a></p> -->
    </div>
  </form>

  <div class="input-column" routerLink="/sign-in"><span class="forgot">Going back to Sign-in</span></div>

  </div>
</div>

<!-- <div class="displayTable">
    <div class="displayTableCell">
      <div class="authBlock">
        <h3>Reset Password</h3>
        <p class="text-center">Please enter your email address to request a password reset.</p>
        <div class="formGroup">
          <input type="email" class="formControl" placeholder="Email Address" #passwordResetEmail required>
        </div>
        <div class="formGroup">
          <input type="submit" class="btn btnPrimary" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">
        </div>
      </div>
      <div class="redirectToLogin">
        <span>Go back to ? <span class="redirect" routerLink="/sign-in">Log In</span></span>
      </div>
    </div>
  </div> -->