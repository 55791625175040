import { Injectable } from '@angular/core';
import { Output } from '../interfaces/output';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutputCrudService {

  outputsRef: AngularFireList<any> | undefined;
  outputRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/outputTypes/' ;
  }
  // Create Output
  addOutput(output: Output) {
    const item = {
      ...output
    }
    this.outputsRef?.push( item );
  }
  // Fetch Single Output Object
  getOutput(id: string) {
    this.outputRef = this.db.object(this.path + id);
    return this.outputRef;
  }
  // Fetch Outputs List
  getOutputsList() {
    this.outputsRef = this.db.list(this.path);
    return this.outputsRef;
  }
  // Update Output Object
  updateOutput(output: Output) {
    this.outputRef = this.db.object(this.path + output.$key);
   // console.log("P inside", output);
    let item : Partial<Output>  = {
      ...output
    }
    delete item?.$key;
    this.outputRef?.update(item);
  }
  // Delete Output Object
  deleteOutput(id: string) {
    this.outputRef = this.db.object(this.path + id);
    this.outputRef.remove();
  }
}
