import { Component, OnInit, Input, ViewChild, ElementRef  } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Routing
import { Router } from '@angular/router';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';


import { Table } from 'primeng/table';

import { DeliveryMan } from 'src/app/shared/interfaces/delivery-man';
import { Provider } from 'src/app/shared/interfaces/provider';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeliveryManCrudService } from 'src/app/shared/services/delivery-man-crud.service';
import { ProviderCrudService } from 'src/app/shared/services/provider-crud.service';
import { ContainerTypeNode, ProductTypeLeaf, TypeNode } from 'src/app/shared/interfaces/node';


import {TreeNode} from 'primeng/api';

// Form
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Item } from 'src/app/shared/interfaces/item';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';


import { v4 as uuidv4 } from 'uuid';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { WoReceipt } from 'src/app/shared/interfaces/wo-receipt';
import { WoReceptionCrudService } from 'src/app/shared/services/wo-reception-crud.service';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { Location } from 'src/app/shared/interfaces/location';

@Component({
  selector: 'app-wo-receives',
  templateUrl: './receives.component.html',
  styleUrls: ['./receives.component.scss']
})
export class ReceivesComponent implements OnInit {

 // ui beadcrumb
 breadcrumbs: MenuItem[];
 homeBreadcrumb: MenuItem = {} as MenuItem;

 providers: Provider[];
 deliveryMen: DeliveryMan[];

 date7: Date = new Date();
 instant: string;
 datetime: string;
 providerKey: string;
 deliveryManKey: string;
 label: string;
 woCode: string;
 notes: string;





items: Item[];
item: any;
selectedItems: Provider[] = [];
itemDialog: boolean = false;

submitted: boolean = false;

cols: any[] = [];
_selectedColumns: any[] = [];

files1: TreeNode[] = [];

@ViewChild('dt', {static: true}) dt : any;
exportColumns: any[] = []; 


// Form
formGroup: FormGroup;


// Dialog
productTypes: WoProductType[] = [];
productType: WoProductType = {} as WoProductType;
dialogSelectProductTypeKey : string = "";
productTypeMap = new Map<string, WoProductType>();

// Lcoations
locations : Location[] = [];
locationReception : string | undefined = undefined;

 constructor(
  public  authService: AuthService,
  private providerService: ProviderCrudService,
  private deliveryManService: DeliveryManCrudService,
  private productTypeService: WoProductTypeCrudService,
  private productService: WoProductCrudService,
  private receptionService: WoReceptionCrudService,
  private locationService: LocationCrudService,

  private eventService: WoEventCrudService,

  private confirmationService: ConfirmationService,
  private messageService: MessageService,
  private exportService: ExportService,

  private router: Router
 ) {
   // ui breadcrumbs
   this.breadcrumbs = [
     {label: 'Actions'},
     {label: 'Receive'}
   ];

   //
   this.providers = [];
   this.deliveryMen = [];

   this.instant = '';
   this.datetime = '';
   this.providerKey = '';
   this.deliveryManKey = '';
   this.label = '';
   this.woCode = '';
   this.notes = '';

   this.items = [];
   this.item = {};

   this.cols = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'actions', header: 'Actions' } 
  ];

  this._selectedColumns = this.cols;

  // Exports
  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

  this.files1 = [];

  // Form
  // this.formGroup = new FormGroup({
  //   datetime: new FormControl('', [Validators.required]),
  //   receiver: new FormControl('', [Validators.required]),
  //   provider: new FormControl('', [Validators.required]),
  //   deliveryMan: new FormControl('', [Validators.required]),
  //   receipt: new FormControl('', [Validators.required]),
  //   woreceipt: new FormControl('', [Validators.required]),
  //   notes: new FormControl('', [Validators.required])
  // });

  this.formGroup = new FormGroup({
    datetime: new FormControl('', [Validators.required]),

   
    receiver: new FormControl(''),
    provider: new FormControl(''),
    deliveryMan: new FormControl(''),
    receipt: new FormControl(''),
    woreceipt: new FormControl(''),
    notes: new FormControl('')
  });

  // Dialog
    this.productTypes = [];
    this.productType = { } as WoProductType ;

  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    //.log( 'includesCol ', c);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  ngOnInit(): void {
    let p = this.providerService.getProvidersList(); 
    p.snapshotChanges().subscribe(data => {
      this.providers = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.providers.push(jsonItem as Provider);
        }
      });
      this.providers = this.providers.sort((a, b) => (a.name < b.name) ? -1 : 1);
    });

    let dm = this.deliveryManService.getDeliveryMansList(); 
    dm.snapshotChanges().subscribe(data => {
      this.deliveryMen = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.deliveryMen.push(jsonItem as DeliveryMan);
        }
      });
      this.deliveryMen = this.deliveryMen.sort((a, b) => (a.name < b.name) ? -1 : 1);
    });

    let pt = this.productTypeService.getWoProductTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.productTypes = [];
      this.productTypeMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }

          const productType = jsonItem as unknown as WoProductType;
          if (productType.receivable) {
            this.productTypes.push(jsonItem as unknown as WoProductType);
            this.productTypeMap.set( productType.$key , productType );
          }

        }
      });
      this.productTypes = this.productTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

      let loc = this.locationService.getLocationsList(); 
      loc.snapshotChanges().subscribe(data => {
        this.locations = [];
        this.locationReception = undefined;

        data.forEach(item => {
          let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }

          const location : Location = jsonItem as unknown as Location;
          if (location.isReception) {
            this.locationReception = location.name;
          }
        })

      });

      //this.locationReception = this.locationReception.sort((a, b) => (a.name < b.name) ? -1 : 1);
    
      //console.log( "packagingTypes" ,this.packagingTypes  );
      //console.log( "packagingTypeMap" ,this.packagingTypeMap  );
    });


    // const now = new Date();
    // this.woCode = now.toISOString().replace(/[-T:Z\.]/g, "");
   // this.datetime = now.toUTCString();



    //this.formGroup.setValue( { datetime : now.toLocaleDateString() + ' ' + now.toLocaleTimeString() });
    

    // this.getDateTime();
    // this.displayUserData();
    // this.getWoCode();
  }

  getDateTime() : string {
    // if (!this.datetime) {
    //   return 'empty';
    // }
    // return 'test';
    // // console.log(formattedDate);
    // return this.datetime;

   // setTimeout(() => {
      const now = new Date();
      this.instant = now.toISOString();
      return now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
  //  });
    return '';
    
    // const now = new Date();
    // this.instant = now.toISOString();
    // return now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
  }

  getReceiver() : string {
    //console.log( this.authService.userData ) ;
    if (this.authService.isLoggedIn) {
    //  setTimeout(() => {
        return this.authService?.userData?.email;
     // });
      
    }
    return '';
    //return 'test';
  }


  getWoCode() : string {
    
    return this.woCode;
  }

// Export management
exportPdf() {
  import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
          const doc = new jsPDF.default('l','pt');
          // @ts-ignore
          doc.autoTable(this.exportColumns, this.providers);
          doc.save('providers.pdf');
      })
  })
}

exportExcel() {
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.providers);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "items");
  });
}

exportDTCSV() {
  //console.log('export csv');
  this.dt.exportCSV();
}

exportToCsv(): void {
  this.exportService.exportToCsv(this.providers, 'providers', ['title', 'description', '$key', 'actions']);
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}


  openNew() {
    this.item = {} ;
    this.submitted = false;
    this.itemDialog = true;
  }

  addItem() {

    if ( !this.dialogSelectProductTypeKey) {
      console.error( 'addItem empty 1');
      return;
    }
    if ( !this.productTypeMap.has(this.dialogSelectProductTypeKey) ) {
      // console.error( 'Key', this.dialogSelectProductTypeKey );
      // console.error( 'Map', this.productTypeMap );
      console.error( 'addItem empty 2');
      return;
    }

    const pt : Partial<WoProductType> = this.productTypeMap.get(this.dialogSelectProductTypeKey) || {} as WoProductType ;
    if (pt.$key) {
      delete pt.$key;
    }

    const i : Item = {
      name: pt.name || '',
      productType: pt
    }
    if (this.item.description) {
      i.description = this.item.description;
    }

    this.items.push( i );
    this.item = {} ;
    this.submitted = true;
    this.itemDialog = false;
  }

  deleteSelectedProviders() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected providers?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.providers = this.providers.filter(val => !this.selectedProviders.includes(val));
            // this.selectedProviders = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Providerss Deleted', life: 3000});
        }
    });
  }

  editProvider(item: Item) {
    this.item = {...item};
    this.itemDialog = true;
  }

  deleteProvider(item: any) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + item.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.items = this.items.filter(val => val.name !== item.name);
            this.item = {};
           //DO this.providerService.DeleteProvider(provider.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Item Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.itemDialog = false;
    this.submitted = false;
  }

  saveProvider() {
    this.submitted = true;

    // if (this.provider.companyName.trim()) {
    //     if (this.provider.$key) {
    //         this.providers[this.findIndexById(this.provider.$key)] = this.provider;                
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Updated', life: 3000});
    //     }
    //     else {
    //         // this.provider.id = this.createId();
    //         // this.provider.image = 'product-placeholder.svg';
    //         this.providers.push(this.provider);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Created', life: 3000});
    //     }
    //}

    //DO this.providerService.addProvider(this.provider);
    //DO this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Created', life: 3000});

    this.items.push( this.item );

    //    this.items = [...this.items];
        this.itemDialog = false;
        this.item = { };

        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Item added', life: 3000});
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].name === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeProvider(provider : Item) {
    //console.log("SAVE", provider);
  }

  submit() {
    console.log('Date time', this.notes);
    console.log('Receiver', this.authService.userData.email);
    console.log('Provider', this.providerKey);
    console.log('Delivery man', this.deliveryManKey);
    console.log('Label', this.label);
    console.log('Notes', this.notes);
    this.items.forEach( item => {
      console.log('Item quantity ', item.quantity );
      console.log('Item name ', item.name );
    });
  }

  submitTest() {
    const mainContainer : TypeNode = new TypeNode();
    let boxOf25Cans : ContainerTypeNode = new ContainerTypeNode(25);
    let can1 : ProductTypeLeaf = new ProductTypeLeaf('can1');
    let can2 : ProductTypeLeaf = new ProductTypeLeaf('can2');
    let can3 : ProductTypeLeaf = new ProductTypeLeaf('can3');
    boxOf25Cans.children.push(can1);
    boxOf25Cans.children.push(can2);
    boxOf25Cans.children.push(can3);

    this.files1 = boxOf25Cans as unknown as TreeNode[];

    this.files1 = 
      
      [
          {
              "label": "Documents",
              "data": "Documents Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [{
                      "label": "Work",
                      "data": "Work Folder",
                      "expandedIcon": "pi pi-folder-open",
                      "collapsedIcon": "pi pi-folder",
                      "children": [{"label": "Expenses.doc", "icon": "pi pi-file", "data": "Expenses Document"}, {"label": "Resume.doc", "icon": "pi pi-file", "data": "Resume Document"}]
                  },
                  {
                      "label": "Home",
                      "data": "Home Folder",
                      "expandedIcon": "pi pi-folder-open",
                      "collapsedIcon": "pi pi-folder",
                      "children": [{"label": "Invoices.txt", "icon": "pi pi-file", "data": "Invoices for this month"}]
                  }]
          },
          {
              "label": "Pictures",
              "data": "Pictures Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [
                  {"label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo"},
                  {"label": "logo.jpg", "icon": "pi pi-image", "data": "PrimeFaces Logo"},
                  {"label": "primeui.png", "icon": "pi pi-image", "data": "PrimeUI Logo"}]
          },
          {
              "label": "Movies",
              "data": "Movies Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [{
                      "label": "Al Pacino",
                      "data": "Pacino Movies",
                      "children": [{"label": "Scarface", "icon": "pi pi-video", "data": "Scarface Movie"}, {"label": "Serpico", "icon": "pi pi-video", "data": "Serpico Movie"}]
                  },
                  {
                      "label": "Robert De Niro",
                      "data": "De Niro Movies",
                      "children": [{"label": "Goodfellas", "icon": "pi pi-video", "data": "Goodfellas Movie"}, {"label": "Untouchables", "icon": "pi pi-video", "data": "Untouchables Movie"}]
                  }]
          }
      ]
    ;
  }

  printOrder() {
    window.print();
  }

  @ViewChild('myImage') myImage: ElementRef | undefined ;
  
    printImage() {
        let printContents, popupWin;
        printContents = this.myImage?.nativeElement;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin?.document.open();
        popupWin?.document.write(`
          <html>
            <body onload="window.print();window.close()">
              <img src="${printContents.src}" width="100%">
            </body>
          </html>`
        );
        popupWin?.print();
       // popupWin?.document.close();
    }


    // https://medium.com/@Idan_Co/angular-print-service-290651c721f9

    printQRCode() {
      let printContents, popupWin;
      printContents = document.getElementById('qrcode-print');
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin?.document.open();
      popupWin?.document.write(`
        <html>
          <body onload="window.print();window.close()">
            ${printContents?.innerHTML}
          </body>
        </html>`
      );
      popupWin?.print();
      //popupWin?.document.close();
  }

  test() {
    this.messageService.add({severity:'error', summary: 'Successful', detail: 'Providerss Deleted', life: 3000});
     
  }

  onSubmit(){
    if (!this.items || this.items.length < 1 ) {
      // //console.log('ITEMS', this.items);
      // receiptObject.items = Object.assign([], this.items);
      alert('Please enter at least one item')
      return;
    }

    

    if(this.formGroup.valid){
      //console.log(this.formGroup.value);

      // 1) Retrieve data from formGroup
      const datetime = this.formGroup.get('datetime')?.value as string ;
      const receiver = this.formGroup.get('receiver')?.value as string ;;
      const provider : string | undefined = this.formGroup.get('provider')?.value as string ;;
      const deliveryMan = this.formGroup.get('deliveryMan')?.value as string ;;
      const receipt = this.formGroup.get('receipt')?.value as string ;;
      const woreceipt = this.formGroup.get('woreceipt')?.value as string ;;
      const notes = this.formGroup.get('notes')?.value as string ;;


      if (!receiver || receiver.length < 1 ) {
        // //console.log('ITEMS', this.items);
        // receiptObject.items = Object.assign([], this.items);
        alert('Please refresh the receiver')
        return;
      }

      if (!woreceipt || woreceipt.length < 1 ) {
        // //console.log('ITEMS', this.items);
        // receiptObject.items = Object.assign([], this.items);
        alert('Please refresh an internal code')
        return;
      }

      // 2) Create Receipt Object

      // 2.A) Create empty object structure to store
      const receiptObject : WoReceipt = {} as WoReceipt;

      // 2.B) Add properties only if defined and not empty
      if (datetime !== undefined && datetime !== null && datetime !== '') {
        receiptObject.datetime = datetime;
        receiptObject.datetime = this.instant;
      }
      if (receiver !== undefined && receiver !== null && receiver !== '') {
        receiptObject.receiver = receiver;
      }
      if (provider !== undefined && provider !== null && provider !== '') {
        const providerName = this.getProviderName( provider );
        receiptObject.provider = providerName;
        //provider = provider && provider.length > 0? provider : undefined;
        //receiptObject['provider'] = provider?? undefined;
      }
      if (deliveryMan !== undefined && deliveryMan !== null && deliveryMan !== '') {
        const deliveryManName = this.getDeliveryManName( deliveryMan );
        receiptObject.deliveryMan = deliveryManName;
      }
      if (receipt !== undefined && receipt !== null && receipt !== '') {
        receiptObject.receipt = receipt;
      }
      if (woreceipt !== undefined && woreceipt !== null && woreceipt !== '') {
        receiptObject.woreceipt = woreceipt;
      }
      if (notes !== undefined && notes !== null && notes !== '') {
        receiptObject.notes = notes;
      }
      if (this.items && this.items.length > 0 ) {
        //console.log('ITEMS', this.items);
        receiptObject.items = Object.assign([], this.items);
      }
      
      // 2.C) Store for the reception table
      this.receptionService.addWoReceipt( receiptObject );

      // 3) Create object event
      // 3.A) for the event
      const woEvent : WoEvent = {
        which: 'RECEPTION',
        what: receiptObject,
        who: receiver,
        why: 'HUMAN-CLICK',
        where: 'WEBSERVER'
      } as WoEvent;

      // 3.B) for the event
      this.eventService.addWoEvent( woEvent );


    //   $key: string;
    // key?: string;
    // when: string;   // datetime stamp of this event
    // what: string;   // object
    // why?: string;   // the cause, e.g: human interaction
    // who: string;    // the actor system responsible for this event
    // where?: string; // where it has been launched (omputer, mobile, desktop)
    // which: string;  // which type of event
    // how?: string;
    // position?: number;



      //console.log('TEST', receiptObject);

      //const receiptObject2 : WoReceipt = receiptObject as WoReceipt;

     // this.receptionService.addWoReceipt( receiptObject2 );


      // 4) Create products
      if (!this.locationReception) {
        this.locationReception = 'Reception area';
      }

      // 4.A) for the event
      this.items.forEach( (item: Item) => {
        console.log('ITEM', item);
        const product : WoProduct = {
          name: item.name,
          batch: woreceipt,
          instant: this.instant,
          // description: item.description,
          
          productTypeKey: item.productType?.key || undefined,
          //
          receivable: item.productType?.receivable || false,
          dryable: item.productType?.dryable || false,
          transformable: item.productType?.transformable || false,
          soldable: item.productType?.soldable || false,
          isEnabled: item.productType?.isEnabled || false,
          history: [woEvent],
          productType: item.productType as WoProductType,
          receipt: receiptObject,
          location: this.locationReception
        } as WoProduct;
        this.productService.addWoProduct(product);
      });
      this.router.navigate(['/reception/receptions']);
    }
  }

  onChangeProductType(key: any) {
    // console.log("key", key);
    // console.log("key map",this.productTypeMap.get(key));
    this.dialogSelectProductTypeKey = key;
  }

  updateDatetime(value: string) {

    this.formGroup.patchValue({ datetime: this.getDateTime() });
  }
  updateReceiver(value: string) {
    this.formGroup.patchValue({ receiver: this.getReceiver() });
  }
  updateWoReceipt(value: string) {
    setTimeout(() => { 
      const now = new Date();
      this.woCode = now.toISOString().replace(/[-T:Z\.]/g, "");
      this.formGroup.patchValue({ woreceipt: this.woCode });
    });
    
  }

  getProviderName(key: string) : string | undefined {
    for( let provider of this.providers) {
      if (provider.$key === key) {
        return provider.name || undefined
      }
    }
    return undefined;
  }

  getDeliveryManName(key: string) : string | undefined {
    for( let man of this.deliveryMen) {
      if (man.$key === key) {
        return man.name || undefined
      }
    }
    return undefined;
  }
 
  ngAfterViewInit() {
    setTimeout(() => {  
      this.updateDatetime('');
      this.updateReceiver('');
      this.updateWoReceipt('');
    });
   
  }

  onSelect(event: any) {
    //console.log('onSelect ', event);
  }

  onBlur(event: any) {
    //console.log('onBlur ', event);
  }

  onClose(event: any) {
    //console.log('onClose ', event);
  }

  dateSelected(event : Date) {
    this.date7 = event;
  }

}
