import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { RecipeCrudService } from 'src/app/shared/services/recipe-crud.service';
import { Recipe } from 'src/app/shared/interfaces/recipe';
import { RecipeItem } from 'src/app/shared/interfaces/recipe-item';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {

  recipes: Recipe[];
  recipe: Recipe;
  selectedRecipes: Recipe[] = [];

  recipeDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private recipeService : RecipeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Recipes'}
    ];

    this.recipes = [];
    this.recipe = { } as Recipe ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.recipeService.getRecipesList(); 
    s.snapshotChanges().subscribe(data => {
      this.recipes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.recipes.push(jsonItem as Recipe);
        }
      })
    })


   // this.getAllRecords();

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
      { field: 'inputs', header: 'Inputs' },
      { field: 'outputs', header: 'Outputs' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.recipeService.getRecipesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    // console.log( 'includesCol ', c);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.recipes);
            doc.save('recipes.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.recipes);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "recipes");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.recipes, 'recipes', ['title', 'description', '$key', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.recipe = {} as Recipe;
    this.submitted = false;
    this.recipeDialog = true;
  }

  deleteSelectedRecipes() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected recipes?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.recipes = this.recipes.filter(val => !this.selectedRecipes.includes(val));
            // this.selectedRecipes = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipess Deleted', life: 3000});
        }
    });
  }

  editRecipe(recipe: Recipe) {
    this.recipe = {...recipe};
    this.recipeDialog = true;
  }

  deleteRecipe(recipe: Recipe) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + recipe.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.recipes = this.recipes.filter(val => val.$key !== recipe.$key);
            this.recipe = {} as Recipe;
            this.recipeService.deleteRecipe(recipe.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipe Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.recipeDialog = false;
    this.submitted = false;
  }

  saveRecipe() {
    this.submitted = true;

    // if (this.recipe.companyName.trim()) {
    //     if (this.recipe.$key) {
    //         this.recipes[this.findIndexById(this.recipe.$key)] = this.recipe;                
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipe Updated', life: 3000});
    //     }
    //     else {
    //         // this.recipe.id = this.createId();
    //         // this.recipe.image = 'product-placeholder.svg';
    //         this.recipes.push(this.recipe);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipe Created', life: 3000});
    //     }
    //}

    this.recipeService.addRecipe(this.recipe);
    this.messageService.add({severity:'success', summary: 'Successful', detail: 'Recipe Created', life: 3000});

        this.recipes = [...this.recipes];
        this.recipeDialog = false;
        this.recipe = { } as Recipe;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.recipes.length; i++) {
        if (this.recipes[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeRecipe(recipe : Recipe) {
    console.log("SAVE", recipe);
  }

  refreshInputs($event: RecipeItem[], recipe : Recipe) {
    recipe.inputItems = $event;
  }

  refreshOutputs($event: RecipeItem[], recipe : Recipe) {
    console.log($event);
    recipe.outputItems = $event;
    console.log(recipe);
  }

  c(recipe : Recipe) : string {
    console.log(recipe.outputItems);
    return 'test'
  }

  display(d: any, field: string) {
    console.log(d);
    return d[field];
  }



}
