import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-move-selection',
  templateUrl: './move-selection.component.html',
  styleUrls: ['./move-selection.component.scss']
})
export class MoveSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
