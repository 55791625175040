import { Injectable } from '@angular/core';

import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { Reception } from '../interfaces/reception';


@Injectable({
  providedIn: 'root'
})
export class ReceptionCrudService {

  stocksRef: AngularFireList<any> | undefined;
  stockRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create Reception
  addReception(reception: Reception) {
    console.log("Add", reception)
    this.stocksRef?.push({
      identifier: reception.identifier
      // content: stock.content,
      // container: stock.container,
     
    });
  }
  // Fetch Single Reception Object
  getReception(id: string) {
    this.stockRef = this.db.object('receptions/receipts/' + id);
    return this.stockRef;
  }
  // Fetch Receptions List
  getReceptionsList() {
    this.stocksRef = this.db.list('receptions/receipts/');
    return this.stocksRef;
  }
  // Update Reception Object
  updateReception(reception: Reception) {
    // this.PackagingTypeRef = this.db.object('PackagingTypes-list/' + packagingType.$key);
    // let tmp : Partial<PackagingType>  = {
    //   ...packagingType
    // }
    // delete tmp?.$key;
    // this.PackagingTypeRef?.update(tmp);
  }
  // Delete Reception Object
  deleteReception(id: string) {
    this.stockRef = this.db.object('receptions/receipts/' + id);
    this.stockRef.remove();
  }
}
