import { Injectable } from '@angular/core';
import { Stock } from '../interfaces/stock';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';


@Injectable({
  providedIn: 'root'
})
export class StockCrudService {

  stocksRef: AngularFireList<any> | undefined;
  stockRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create Stock
  addStock(stock: Stock) {
    console.log("Add", stock)
    this.stocksRef?.push({
      quantity: stock.quantity,
      // content: stock.content,
      // container: stock.container,
      isEnabled: stock.isEnabled? true : false
    });
  }
  // Fetch Single Stock Object
  getStock(id: string) {
    this.stockRef = this.db.object('Stocks-list/' + id);
    return this.stockRef;
  }
  // Fetch Stocks List
  getStocksList() {
    this.stocksRef = this.db.list('Stocks-list');
    return this.stocksRef;
  }
  // Update Stock Object
  updateStock(stock: Stock) {
    // this.PackagingTypeRef = this.db.object('PackagingTypes-list/' + packagingType.$key);
    // let tmp : Partial<PackagingType>  = {
    //   ...packagingType
    // }
    // delete tmp?.$key;
    // this.PackagingTypeRef?.update(tmp);
  }
  // Delete Stock Object
  deleteStock(id: string) {
    this.stockRef = this.db.object('Stocks-list/' + id);
    this.stockRef.remove();
  }

}
