import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-z-long',
  templateUrl: './z-long.component.html',
  styleUrls: ['./z-long.component.scss']
})
export class ZLongComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
