<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedProviders()" [disabled]="!selectedItems || !selectedItems.length "></button>
        </div> 
    </div>


    <div class="section">
        <div class="sectionTitle">Date and time: </div>
        {{ getDateTime() }}
    </div>

    <div class="section">
        <div class="sectionTitle">Provider</div>
        <p-dropdown 
            appendTo="body" 
            [filter]="true"
            [showClear]="true" 
            [(ngModel)]="providerKey" 
            [options]="providers" 
            optionLabel="name" 
            optionValue="$key"
            placeholder="Any">
        </p-dropdown> 
    </div>

    <div class="section">
        <div class="sectionTitle">Delivery man</div>
        <p-dropdown 
            appendTo="body" 
            [filter]="true"
            [showClear]="true" 
            [(ngModel)]="deliveryManKey" 
            [options]="deliveryMen" 
            optionLabel="name" 
            optionValue="$key"
            placeholder="Any">
        </p-dropdown> 
    </div>

    <div class="section">
        <div class="sectionTitle">Receiver</div>
        {{ displayUserData() }}
    </div>

    <div class="section">
        <div class="sectionTitle">Reception Label / Code / Batch id</div>
        <div class="p-field">
            <!-- <label for="provider">Reception Label / Code / Batch id</label> -->
            <input style="margin-top: 10px;" type="text" pInputText id="label" [(ngModel)]="label" required autofocus />
        </div>
    </div>

    <div class="section">
        <div class="sectionTitle">Internal label</div>
    </div>

    <div class="section">
        <div class="sectionTitle">List of items</div>
    </div>

    



    <p-table #dt 
    [value]="items"                                                                                                      
    [rows]="10" 
    [paginator]="true" 
    [globalFilterFields]="['name','description','status']"
    [rowsPerPageOptions]="[5,10,25,50,100]"
    [(selection)]="selectedItems" 
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [rowHover]="true" 
    dataKey="$key"
    styleClass="p-datatable-striped"
    currentPageReportTemplate="{first} to {last} of {totalRecords}" 
    [showCurrentPageReport]="true"
    selectionMode="multiple"
    [columns]="selectedColumns"
    >

    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="max-width:10%">
            <col style="max-width:20%">
            <col style="min-width:10%;max-width:80%">
            <col style="max-width:20%">
        </colgroup>
    </ng-template>
    
    <ng-template pTemplate="caption">
        <p-multiSelect 
            [options]="cols" 
            [(ngModel)]="selectedColumns" 
            optionLabel="header"
            selectedItemsLabel="{0} columns selected" 
            [style]="{minWidth: '200px'}" 
            placeholder="Choose Columns">
        </p-multiSelect>
    </ng-template>


    <ng-template pTemplate="header" let-columns="columns">
        <tr>
            <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                Order<p-sortIcon field="position"></p-sortIcon>
            </th>
            <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                <p-sortIcon field="name"></p-sortIcon> 
                <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
            </th>
            <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description</th>
            <!-- <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
            <th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
            <th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
            <th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th> -->
            <th pResizableColumn pReorderableColumn style="width: 8rem">
                Actions 
                <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
            </th>
            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-provider let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index" [pSelectableRow]="provider">
            <td pEditableColumn>
                <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="provider.position" required>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{provider.position}} {{index}}
                    </ng-template>
                </p-cellEditor>
                <!-- <p-tableCheckbox [value]="provider"></p-tableCheckbox> -->
            </td>

            <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="provider.name" required>
                    </ng-template>
                    <ng-template pTemplate="output" >
                        <span >{{provider.name}}</span>
                    </ng-template>
                </p-cellEditor>
            </td>
<!-- onEditInit= onEditComplete onEditCancel
            (blur)="save({ data: item })"
                         (keydown.tab)="save({ data: item })"
                         (keydown.enter)="save({ data: item })" -->
            <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                <p-cellEditor >
                    <ng-template pTemplate="input" >
                        <input pInputText type="text" [(ngModel)]="provider.description"  (blur)="completeProvider(provider)" (keydown.tab)="completeProvider(provider)" (keydown.enter)="completeProvider(provider)"  required>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span >{{provider.description}}</span>
                    </ng-template>
                </p-cellEditor>
            </td>


            <!-- <td>{{provider.name}}</td> -->
            <!-- <td>nicolas</td> -->
            <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
            <!-- <td>{{product.price | currency:'USD'}}</td>
            <td>{{product.category}}</td>
            <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
            <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
            <td class="row-actions">
                <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProvider(provider)"></button>
                <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProvider(provider)"></button>
                <p-tableCheckbox class="checkbox" [value]="provider"></p-tableCheckbox>
                
            </td>
            <!-- <td>
                <p-tableCheckbox [value]="provider"></p-tableCheckbox>
            </td> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="summary">
            <div class="summary-total">
                Total: {{providers ? providers.length : 0 }} providers.
            </div>
            <div class="summary-export">
                <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            </div>
        </div>
    </ng-template>
</p-table>



<div class="section">
    <div class="sectionTitle">Notes</div>
    <div class="p-field">
        <!-- <label for="provider">Reception Label / Code / Batch id</label> -->
        <input style="margin-top: 10px;" type="text" pInputText id="notes" [(ngModel)]="notes" required autofocus />
    </div>
</div>


<p-tree [value]="files1" [filter]="true"></p-tree>

<img src="assets/images-black/move.png" #myImage>

<div id="qrcode-print">
    <qrcode [qrdata]="'Hello World'" size="256" level="M"></qrcode>
</div>

<div class="section">
    <button (click)="printQRCode()">Print QRCode</button>
    <button (click)="printImage()">Print Image</button>
    <button (click)="printOrder()">Print Order</button>
    <button pButton pRipple label="Submit" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="submitTest()"></button>
</div>




    <p-dialog [(visible)]="itemDialog" [style]="{width: '450px', height: '450px'}" header="New Item" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="item.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !item.name">Provider name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="item.description" rows="3" cols="20"></textarea>
        </div>
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success p-mr-2" (click)="saveProvider()"></button>
        </div>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


</div>
