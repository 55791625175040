import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Feature } from 'src/app/shared/interfaces/feature';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeatureCrudService } from 'src/app/shared/services/feature-crud.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  public modules = environment.modules;

  public appLogo = environment.applicationLogo;

  public features : Feature[];

  @Input() sidenav!: MatSidenav ;

  constructor(
    public authService: AuthService,
    private featureService: FeatureCrudService) { 
      this.features = [];
  }

  ngOnInit(): void {
   // this.dataState();
    let s = this.featureService.getFeaturesList(); 
    s.snapshotChanges().subscribe(data => {
      this.features = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.features.push(jsonItem as Feature);
        }
      })
    })
  }

  async logout() {
    try {
      this.authService.SignOut()
    } catch (error) {
      console.log('[ERROR] logout', error);
    }
  }

}
