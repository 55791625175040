import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { GrapeVarietyCrudService } from 'src/app/shared/services/grape-variety-crud.service';
import { GrapeVariety } from 'src/app/shared/interfaces/grape-variety';



@Component({
  selector: 'app-grape-varieties',
  templateUrl: './grape-varieties.component.html',
  styleUrls: ['./grape-varieties.component.scss']
})
export class GrapeVarietiesComponent implements OnInit {

  grapeVarieties: GrapeVariety[];
  grapeVariety: GrapeVariety;
  selectedGrapeVarieties: GrapeVariety[] = [];

  grapeVarietyDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private grapeVarietyService : GrapeVarietyCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'GrapeVarieties'}
    ];

    this.grapeVarieties = [];
    this.grapeVariety = { } as GrapeVariety ;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.grapeVarietyService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.grapeVarieties = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : GrapeVariety = jsonItem as GrapeVariety;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.grapeVarieties.push(l);
        }
      })
    })

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.grapeVarietyService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.grapeVarieties);
            doc.save('grapeVarieties.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.grapeVarieties);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "grapeVarieties");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedGrapeVarieties);
      // console.log('SAVEC CSV LOCATIONS ', this.grapeVarieties);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedGrapeVarieties, 'grapeVarieties', exportCols);
   // this.exportService.exportToCsv(this.grapeVarieties, 'grapeVarieties', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.grapeVariety = {} as GrapeVariety;
    this.submitted = false;
    this.grapeVarietyDialog = true;
  }

  deleteSelectedGrapeVarieties() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected grapeVarieties?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.grapeVarieties = this.grapeVarieties.filter(val => !this.selectedGrapeVarieties.includes(val));
            this.selectedGrapeVarieties = [];
            this.selectedGrapeVarieties.forEach( grapeVariety => this.grapeVarietyService.delete(grapeVariety.$key));
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'GrapeVarietiess Deleted', life: 3000});
        }
    });
  }

  editGrapeVariety(grapeVariety: GrapeVariety) {
    this.grapeVariety = {...grapeVariety};
    this.grapeVarietyDialog = true;
  }

  deleteGrapeVariety(grapeVariety: GrapeVariety) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + grapeVariety.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.grapeVarieties = this.grapeVarieties.filter(val => val.$key !== grapeVariety.$key);
            this.grapeVariety = {} as GrapeVariety;
            this.grapeVarietyService.delete(grapeVariety.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'GrapeVariety Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.grapeVarietyDialog = false;
    this.submitted = false;
  }

  saveGrapeVariety() {
    this.submitted = true;

    if ( this.grapeVariety.name.trim() ) {
      if (this.grapeVariety.$key) {
          this.grapeVarieties[this.findIndexById(this.grapeVariety.$key)] = this.grapeVariety;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'GrapeVariety Updated', life: 3000});
          this.grapeVarietyService.update(this.grapeVariety);
        } else {
          this.grapeVariety.position = this.grapeVarieties.length;
          this.grapeVarietyService.add(this.grapeVariety);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'GrapeVariety Created', life: 3000});
      this.grapeVarieties = [...this.grapeVarieties];
    }

    this.grapeVarietyDialog = false;
    this.grapeVariety = { } as GrapeVariety;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.grapeVarieties.length; i++) {
        if (this.grapeVarieties[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeGrapeVariety(grapeVariety : GrapeVariety) {
    //console.log("SAVE", grapeVariety);
    this.grapeVarietyService.update(grapeVariety);
  }

  completePosition(grapeVariety : GrapeVariety) {
   // console.log("SAVE", grapeVariety);
  //  this.grapeVarietyService.updateGrapeVariety(grapeVariety);


    if ( this.newPosition > -1 ) {
        grapeVariety.position = this.newPosition;
        grapeVariety = this.checkPositionLimits(grapeVariety);
        this.reorderElements(grapeVariety);
        //this.updateDB(grapeVariety);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.grapeVarieties);
    // const myClonedArray = [];
    // this.grapeVarieties.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.grapeVarieties.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.grapeVarieties.length - 1 - event.dropIndex;
    const grapeVarietyToMove = this.grapeVarieties[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.grapeVarieties[index] = this.grapeVarieties[index+1];
        this.grapeVarieties[index].position = index;
        this.grapeVarietyService.update(this.grapeVarieties[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.grapeVarieties[index] = this.grapeVarieties[index-1];
        this.grapeVarieties[index].position = index;
        this.grapeVarietyService.update(this.grapeVarieties[index]);
      }
    } 
    this.grapeVarieties[endIndex] = grapeVarietyToMove;
    this.grapeVarieties[endIndex].position = endIndex;
    this.grapeVarietyService.update(this.grapeVarieties[endIndex]);
  }

  // onFieldEdit(grapeVariety: Spray, fieldName: string): void {
  //   //console.log(this.grapeVarieties);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + grapeVariety[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       grapeVariety.position = this.newPosition;
  //       grapeVariety = this.checkPositionLimits(grapeVariety);
  //       this.reorderElements(grapeVariety);
  //       //this.updateDB(grapeVariety);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(grapeVariety);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.grapeVarieties.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, grapeVariety: GrapeVariety) {
    // console.log("event" +event);
    if (grapeVariety.position != null && grapeVariety.position > -1) {
      this.previousPosition = grapeVariety.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(grapeVariety: GrapeVariety) {
    console.log('check ',grapeVariety.position + ' ' + this.grapeVarieties.length);
    if (!grapeVariety.position) {
     // console.log('check 1');
      return this.grapeVariety;
    }
    if (grapeVariety.position < 0) {
      grapeVariety.position = 0;
      // console.log('check 2');
      return grapeVariety;
    }
    if (grapeVariety.position > this.grapeVarieties.length) {
      grapeVariety.position = this.grapeVarieties.length - 1;
    //  console.log('check 3', grapeVariety.position);
      return grapeVariety;
    }
   // console.log('check 4');
    return grapeVariety;
  }

  async reorderElements(grapeVariety: GrapeVariety) {
    this.newPosition = this.newPosition  > this.grapeVarieties.length ? this.grapeVarieties.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === grapeVariety.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const grapeVarietyToMove = this.grapeVarieties[startIndex];
   // console.log('Item to move: ', this.grapeVarieties[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.grapeVarieties);
        console.log('Item to +1: ', this.grapeVarieties[index+1]);

        this.grapeVarieties[index] = this.grapeVarieties[index+1];
        this.grapeVarieties[index].position = index;
        // console.log('Move: ', this.grapeVarieties[index+1]);
        // console.log('Move pos: ', this.grapeVarieties[index].position);
        //await this.updateDB(this.grapeVarieties[index]);
        this.grapeVarietyService.update(this.grapeVarieties[index]);
     //   console.log('Update DB: ', this.grapeVarieties[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.grapeVarieties[index] = this.grapeVarieties[index-1];
        this.grapeVarieties[index].position = index;
        this.grapeVarietyService.update(this.grapeVarieties[index]);
      }
    } 
    this.grapeVarieties[endIndex] = grapeVarietyToMove;
    this.grapeVarieties[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.grapeVarietyService.update(this.grapeVarieties[endIndex]);
    
    // const myClonedArray = [];
    // this.grapeVarieties.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.grapeVarieties = myClonedArray;
   // this.getAllRecords();
  }
}
