
<div class="page">

    <div class="page-header" *ngIf="isPreTable">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedRecipeItems()" [disabled]="!selectedRecipeItems || !selectedRecipeItems.length "></button>
        </div> 
    </div>


    <div class="page-header">
        <div class="breadcrumb">
            <span> {{ tableTitle }} </span>
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
        </div>
    </div>
    <div class="page-content">
        <p-table #dt 
            [value]="recipeItems"                                                                                                      
            [rows]="100" 
            [paginator]="false" 
            [globalFilterFields]="['name','quantity','status']"
            
            
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"

            [showCurrentPageReport]="true"
           
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup" *ngIf="isPreTable">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption" *ngIf="isPreTable">
                <p-multiSelect 
                    [options]="cols" 
                    [(ngModel)]="selectedColumns" 
                    optionLabel="header"
                    selectedItemsLabel="{0} columns selected" 
                    [style]="{minWidth: '200px'}" 
                    placeholder="Choose Columns">
                </p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 3rem">
                        <!-- <p-sortIcon field="position"></p-sortIcon> -->
                    </th>
                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn pSortableColumn="quantity" [class]="includesCol('quantity') ? 'd' : 'h'">Quantity 
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter type="number" field="quantity" display="menu"></p-columnFilter>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description</th> -->
                    <!-- <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
                    <th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
                    <th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
                    <th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th> -->
                    <th pResizableColumn pReorderableColumn style="width: 3rem">
                        
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <!-- <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox> -->
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-recipeItem let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" [pSelectableRow]="recipeItem">
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="recipeItem.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{recipeItem.position}} {{index}}
                            </ng-template>
                        </p-cellEditor> -->
                        <!-- <p-tableCheckbox [value]="recipeItem"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="recipeItem.quantity" required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{recipeItem.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="recipeItem.name" required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{recipeItem.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- onEditInit= onEditComplete onEditCancel
                    (blur)="save({ data: item })"
                                 (keydown.tab)="save({ data: item })"
                                 (keydown.enter)="save({ data: item })" -->
                    <!-- <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="recipeItem.description"  (blur)="completeRecipeItem(recipeItem)" (keydown.tab)="completeRecipeItem(recipeItem)" (keydown.enter)="completeRecipeItem(recipeItem)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{recipeItem.description}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->


                    <!-- <td>{{recipeItem.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <!-- <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editRecipeItem(recipeItem)"></button> -->
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteRecipeItem(recipeItem)"></button>
                        <!-- <p-tableCheckbox class="checkbox" [value]="recipeItem"></p-tableCheckbox> -->
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="recipeItem"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary" *ngIf="isPostTable">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{recipeItems ? recipeItems.length : 0 }} recipeItems.
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer" *ngIf="isPostTable">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="recipeItemDialog" [style]="{width: '450px', height: '450px'}" header="New RecipeItem" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
            <div class="p-field">
                <label for="name">Name</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="recipeItem.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !recipeItem.name">RecipeItem name is required.</small>
            </div>
            <div class="p-field">
                <label for="quantity">Quantity</label>
                <input style="margin-top: 10px;" id="quantity" pInputText [(ngModel)]="recipeItem.quantity" />
            </div>
            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success p-mr-2" (click)="saveRecipeItem()"></button>
            </div>
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>






