import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { BarrelCrudService } from 'src/app/shared/services/barrel-crud.service';
import { Barrel } from 'src/app/shared/interfaces/barrel';
import { CooperCrudService } from 'src/app/shared/services/cooper-crud.service';
import { VolumeCrudService } from 'src/app/shared/services/volume-crud.service';
import { WoodCrudService } from 'src/app/shared/services/wood-crud.service';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { RackCrudService } from 'src/app/shared/services/rack-crud.service';
import { BatchCrudService } from 'src/app/shared/services/batch-crud.service';
import { WoodTypeCrudService } from 'src/app/shared/services/wood-type-crud.service';
import { Type } from 'src/app/shared/interfaces/type';
import { Cooper } from 'src/app/shared/interfaces/cooper';
import { Volume } from 'src/app/shared/interfaces/volume';
import { Rack } from 'src/app/shared/interfaces/rack';
import { Wood } from 'src/app/shared/interfaces/wood';
import { Batch } from 'src/app/shared/interfaces/batch';
import { Location } from 'src/app/shared/interfaces/location';

@Component({
  selector: 'app-barrel-current',
  templateUrl: './barrel-current.component.html',
  styleUrls: ['./barrel-current.component.scss']
})
export class BarrelCurrentComponent implements OnInit {

  barrels: Barrel[];
  barrel: Barrel;
  selectedBarrels: Barrel[] = [];

  types: Type[];
  typesMap : Map<string,Type>;

  coopers: Cooper[];
  coopersMap : Map<string,Cooper>;

  years: number[];

  volumes: Volume[];
  volumesMap : Map<string,Volume>;

  woods: Wood[];
  woodsMap : Map<string,Wood>;

  locations: Location[];
  locationsMap : Map<string,Location>;

  racks: Rack[];
  racksMap : Map<string,Rack>;

  batches: Batch[];
  batchesMap : Map<string,Batch>;


  barrelDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private barrelService : BarrelCrudService,
    private typeService: WoodTypeCrudService,
    private cooperService: CooperCrudService,
    private volumeService: VolumeCrudService,
    private woodService: WoodCrudService,
    private locationService: LocationCrudService,
    private rackService: RackCrudService,
    private batchService: BatchCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Barrels'}
    ];

    this.barrels = [];
    this.barrel = { } as Barrel ;

    this.types = [];
    this.typesMap = new Map<string,Type>();

    this.coopers = [];
    this.coopersMap = new Map<string,Cooper>();

    this.years = [];

    this.volumes = [];
    this.volumesMap = new Map<string,Volume>();

    this.woods = [];
    this.woodsMap = new Map<string,Wood>();

    this.locations = [];
    this.locationsMap = new Map<string,Location>();

    this.racks = [];
    this.racksMap = new Map<string,Rack>();

    this.batches = [];
    this.batchesMap = new Map<string,Batch>();
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.barrelService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.barrels = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Barrel = jsonItem as Barrel;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.barrels.push(l);
        }
      })
    });


    let t = this.typeService.getList(); 
    t.snapshotChanges().subscribe(data => {
      this.types = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Type = jsonItem as Type;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.types.push(l);
          this.typesMap.set( l.$key , l);
        }
      })
    });

    let c = this.cooperService.getList(); 
    c.snapshotChanges().subscribe(data => {
      this.coopers = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Cooper = jsonItem as Cooper;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.coopers.push(l);
          this.coopersMap.set( l.$key , l);
        }
      })
    });

    let date : Date = new Date();
    let currentYear : number = date.getFullYear();
    for(let i = 1900; i < currentYear; i++ ) {
        this.years.push(i);
    }

    let v = this.volumeService.getList(); 
    v.snapshotChanges().subscribe(data => {
      this.volumes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Volume = jsonItem as Volume;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.volumes.push(l);
          this.volumesMap.set( l.$key , l);
        }
      })
    });
    

    let w = this.woodService.getList(); 
    w.snapshotChanges().subscribe(data => {
      this.woods = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Wood = jsonItem as Wood;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.woods.push(l);
          this.woodsMap.set( l.$key , l);
        }
      })
    });

    let loc = this.locationService.getLocationsList(); 
    loc.snapshotChanges().subscribe(data => {
      this.locations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Location = jsonItem as Location;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.locations.push(l);
          this.locationsMap.set( l.$key , l);
        }
      })
    });

    let r = this.rackService.getList(); 
    r.snapshotChanges().subscribe(data => {
      this.racks = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Rack = jsonItem as Rack;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.racks.push(l);
          this.racksMap.set( l.$key , l);
        }
      })
    });

    let b = this.batchService.getList(); 
    b.snapshotChanges().subscribe(data => {
      this.batches = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Batch = jsonItem as Batch;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.batches.push(l);
          this.batchesMap.set( l.$key , l);
        }
      })
    });

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
      { field: 'type', header: 'Type' },
      { field: 'cooper', header: 'Cooper' },
      { field: 'year', header: 'Barrel year' },
      { field: 'volume', header: 'Volume' },
      { field: 'wood', header: 'Wood' },
      { field: 'tags', header: 'Tags' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'location', header: 'Location' },
      { field: 'rack', header: 'Rack' },
      { field: 'batch', header: 'Batch' },
      { field: 'wine', header: 'Wine' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'type');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'cooper');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'year');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'volumes');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'wood');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.barrelService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.barrels);
            doc.save('barrels.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.barrels);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "barrels");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedBarrels);
      // console.log('SAVEC CSV LOCATIONS ', this.barrels);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedBarrels, 'barrels', exportCols);
   // this.exportService.exportToCsv(this.barrels, 'barrels', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.barrel = {} as Barrel;
    this.submitted = false;
    this.barrelDialog = true;
  }

  deleteSelectedBarrels() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected barrels?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.barrels = this.barrels.filter(val => !this.selectedBarrels.includes(val));
            this.selectedBarrels = [];
            this.selectedBarrels.forEach( barrel => this.barrelService.delete(barrel.$key));
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Barrelss Deleted', life: 3000});
        }
    });
  }

  editBarrel(barrel: Barrel) {
    this.barrel = {...barrel};
    this.barrelDialog = true;
  }

  deleteBarrel(barrel: Barrel) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + barrel.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.barrels = this.barrels.filter(val => val.$key !== barrel.$key);
            this.barrel = {} as Barrel;
            this.barrelService.delete(barrel.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Barrel Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.barrelDialog = false;
    this.submitted = false;
  }

  saveBarrel() {
    this.submitted = true;

    if ( this.barrel.name.trim() ) {
      if (this.barrel.$key) {
          this.barrels[this.findIndexById(this.barrel.$key)] = this.barrel;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Barrel Updated', life: 3000});
          this.barrelService.update(this.barrel);
        } else {
          this.barrel.position = this.barrels.length;
          this.barrelService.add(this.barrel);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Barrel Created', life: 3000});
      this.barrels = [...this.barrels];
    }

    this.barrelDialog = false;
    this.barrel = { } as Barrel;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.barrels.length; i++) {
        if (this.barrels[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeBarrel(barrel : Barrel) {
    //console.log("SAVE", barrel);
    this.barrelService.update(barrel);
  }

  completePosition(barrel : Barrel) {
   // console.log("SAVE", barrel);
  //  this.barrelService.updateBarrel(barrel);


    if ( this.newPosition > -1 ) {
        barrel.position = this.newPosition;
        barrel = this.checkPositionLimits(barrel);
        this.reorderElements(barrel);
        //this.updateDB(barrel);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.barrels);
    // const myClonedArray = [];
    // this.barrels.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.barrels.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.barrels.length - 1 - event.dropIndex;
    const barrelToMove = this.barrels[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.barrels[index] = this.barrels[index+1];
        this.barrels[index].position = index;
        this.barrelService.update(this.barrels[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.barrels[index] = this.barrels[index-1];
        this.barrels[index].position = index;
        this.barrelService.update(this.barrels[index]);
      }
    } 
    this.barrels[endIndex] = barrelToMove;
    this.barrels[endIndex].position = endIndex;
    this.barrelService.update(this.barrels[endIndex]);
  }

  // onFieldEdit(barrel: Spray, fieldName: string): void {
  //   //console.log(this.barrels);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + barrel[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       barrel.position = this.newPosition;
  //       barrel = this.checkPositionLimits(barrel);
  //       this.reorderElements(barrel);
  //       //this.updateDB(barrel);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(barrel);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.barrels.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, barrel: Barrel) {
    // console.log("event" +event);
    if (barrel.position != null && barrel.position > -1) {
      this.previousPosition = barrel.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(barrel: Barrel) {
    console.log('check ',barrel.position + ' ' + this.barrels.length);
    if (!barrel.position) {
     // console.log('check 1');
      return this.barrel;
    }
    if (barrel.position < 0) {
      barrel.position = 0;
      // console.log('check 2');
      return barrel;
    }
    if (barrel.position > this.barrels.length) {
      barrel.position = this.barrels.length - 1;
    //  console.log('check 3', barrel.position);
      return barrel;
    }
   // console.log('check 4');
    return barrel;
  }

  async reorderElements(barrel: Barrel) {
    this.newPosition = this.newPosition  > this.barrels.length ? this.barrels.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === barrel.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const barrelToMove = this.barrels[startIndex];
   // console.log('Item to move: ', this.barrels[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.barrels);
        console.log('Item to +1: ', this.barrels[index+1]);

        this.barrels[index] = this.barrels[index+1];
        this.barrels[index].position = index;
        // console.log('Move: ', this.barrels[index+1]);
        // console.log('Move pos: ', this.barrels[index].position);
        //await this.updateDB(this.barrels[index]);
        this.barrelService.update(this.barrels[index]);
     //   console.log('Update DB: ', this.barrels[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.barrels[index] = this.barrels[index-1];
        this.barrels[index].position = index;
        this.barrelService.update(this.barrels[index]);
      }
    } 
    this.barrels[endIndex] = barrelToMove;
    this.barrels[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.barrelService.update(this.barrels[endIndex]);
    
    // const myClonedArray = [];
    // this.barrels.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.barrels = myClonedArray;
   // this.getAllRecords();
  }
}
