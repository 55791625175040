import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  hide = true;
  signupForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.min(2), Validators.required]),
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    // phone: new FormControl('', [Validators.min(10)]),
    // fname: new FormControl('', [Validators.min(2)]),
    // lname: new FormControl('', [Validators.min(2)])
  });

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  get emailInput() {
    return this.signupForm.get('email');
  }
  get passwordInput() {
    return this.signupForm.get('password');
  }

  getEmailInputError() {
    if (this.emailInput?.hasError('email')) {
      return 'Please enter a valid email address.';
    }
    if (this.emailInput?.hasError('required')) {
      return 'An Email is required.';
    }
    return undefined;
  }
  
  getPasswordInputError() {
    if (this.passwordInput?.hasError('required')) {
      return 'A password is required.';
    }
    return undefined;
  }
  
  shouldEnableSubmit() {
    return (
     // !this.usernameInput.valid ||
      !this.emailInput?.valid ||
      !this.passwordInput?.valid
      // !this.fnameInput.valid ||
      // !this.lnameInput.valid
     // !this.phoneInput.valid
    );
  }
  
  signUp() {
    this.authService.SignUp(this.emailInput?.value, this.passwordInput?.value)
  }

}
