import { Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCrudService {

  stocksRef: AngularFireList<any> | undefined;
  stockRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = 'current/productsTA/' ;
  }

  // products/items2/

  // Create Product
  addProduct(stock: Product) {
    this.stocksRef = this.db.list( this.path );
    const item = {
      ...stock
    }
    this.stocksRef?.push( item );
  }

  // Fetch Single Product Object
  getProduct(id: string) {
    this.stockRef = this.db.object( this.path + id);
    return this.stockRef;
  }

  // Fetch Products List
  getProductsList() {
    this.stocksRef = this.db.list( this.path );
    return this.stocksRef;
  }

  // Update Product Object
  updateProduct(stock: Product) {
   // const k : string = stock.$key || 'na';
    this.stockRef = this.db.object(this.path + stock.$key);
    let item : Partial<Product>  = {
      ...stock
    }
    delete item?.$key;
    this.stockRef?.update(item);
  }
  // Delete Product Object
  deleteProduct(id: string) {
    this.stockRef = this.db.object( this.path + id);
    this.stockRef.remove();
  }

}
