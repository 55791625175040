

import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelJson } from '../interfaces/excel-json';


const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  /**
   * Creates excel from the table element reference.
   *
   * @param element DOM table element reference.
   * @param fileName filename to save as.
   */
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  /**
   * Creates XLSX option from the Json data. Use this to customise the sheet by adding arbitrary rows and columns.
   *
   * @param json Json data to create xlsx.
   * @param fileName filename to save as.
   */
  public exportJsonToExcel(json: ExcelJson[], fileName: string): void {
    // inserting first blank row
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      // adding a dummy row for separation
      XLSX.utils.sheet_add_json(
        worksheet,
        [{}],
        this.getOptions(
          {
            data: [],
            skipHeader: true
          }, -1)
      );
      XLSX.utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    const workbook: XLSX.WorkBook = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  /**
   * Creates XLSX option from the data.
   *
   * @param json Json data to create xlsx.
   * @param origin XLSX option origin.
   * @returns options XLSX options.
   */
  private getOptions(json: ExcelJson, origin?: number): any {
    // adding actual data
    const options = {
      skipHeader: true,
      origin: -1,
      header: []
    };
    options.skipHeader = json.skipHeader ? json.skipHeader : false;
    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header as never[];
    }
    if (origin) {
      options.origin = origin ? origin : -1;
    }
    return options;
  }

  /**
   * Saves the file on client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to csv. It will automatically generate title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    if (!rows || !rows.length) {
      return '';
    }
    const separator = ',';
    const keys : string[] = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        console.log('columns includes ', columns);
        console.log('columns includes ', k);
        return columns.includes(k);
      } else {
        return true;
      }
    });
    console.log('KEYS', keys);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map( (row : object) => {
        return keys.map( (k : any) => {
          // if (!k) {
          //   k = '';
          // }
          let key : keyof typeof row = k as never;
          if (!key) {
            return '';
          }
          let cell : string = row[key] === null || row[key] === undefined ? '' : row[key];
            cell = `"${cell}"`;
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
    return '';
  }



  // public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
  //   if (!rows || !rows.length) {
  //     return;
  //   }
  //   const separator = ',';
  //   const keys = Object.keys(rows[0]).filter(k => {
  //     if (columns?.length) {
  //       return columns.includes(k);
  //     } else {
  //       return true;
  //     }
  //   });
  //   const csvContent =
  //     keys.join(separator) +
  //     '\n' +
  //     rows.map(row => {
  //       return keys.map(k => {
  //         let cell = row[k] === null || row[k] === undefined ? '' : row[k];
  //         cell = cell instanceof Date
  //           ? cell.toLocaleString()
  //           : cell.toString().replace(/"/g, '""');
  //         if (cell.search(/("|,|\n)/g) >= 0) {
  //           cell = `"${cell}"`;
  //         }
  //         return cell;
  //       }).join(separator);
  //     }).join('\n');
  //   this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  // }
}
