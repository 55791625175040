import { Injectable } from '@angular/core';
import { PackagingType } from '../interfaces/packaging-type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class PackagingTypeCrudService {

  PackagingTypesRef: AngularFireList<any> | undefined;
  PackagingTypeRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create PackagingType
  AddPackagingType(packagingType: PackagingType) {
    console.log("Add", packagingType)
    this.PackagingTypesRef?.push({
      name: packagingType.name,
      minCapacity: packagingType.minCapacity? Number(packagingType.minCapacity) : 0,
      maxCapacity: packagingType.maxCapacity? Number(packagingType.maxCapacity) : 1000,
      unit: packagingType.unit? packagingType.unit : 'unit' ,
      isEnabled: packagingType.isEnabled? true : false
    });
  }
  // Fetch Single PackagingType Object
  GetPackagingType(id: string) {
    this.PackagingTypeRef = this.db.object('products/packagingTypes/' + id);
    return this.PackagingTypeRef;
  }
  // Fetch PackagingTypes List
  GetPackagingTypesList() {
    this.PackagingTypesRef = this.db.list('products/packagingTypes');
    return this.PackagingTypesRef;
  }
  // Update PackagingType Object
  UpdatePackagingType(packagingType: PackagingType) {
    this.PackagingTypeRef = this.db.object('products/packagingTypes/' + packagingType.$key);
    let tmp : Partial<PackagingType>  = {
      ...packagingType
    }
    delete tmp?.$key;
    this.PackagingTypeRef?.update(tmp);
  }
  // Delete PackagingType Object
  DeletePackagingType(id: string) {
    this.PackagingTypeRef = this.db.object('products/packagingTypes/' + id);
    this.PackagingTypeRef.remove();
  }
}
