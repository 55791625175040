import { Injectable } from '@angular/core';

import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { WoReceipt } from '../interfaces/wo-receipt';

@Injectable({
  providedIn: 'root'
})
export class WoReceptionCrudService {

  stocksRef: AngularFireList<any> | undefined;
  stockRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/receipts/' ;
  }

  // Create WoReceipt
  addWoReceipt(woReceipt: WoReceipt) {
    this.stocksRef = this.db.list( this.path );
    console.log("Add", woReceipt)
    const item = {
      ...woReceipt
    }
    this.stocksRef?.push( item ).catch( err=> console.error(err));

  }
  // Fetch Single WoReceipt Object
  getWoReceipt(id: string) {
    this.stockRef = this.db.object( this.path + id);
    return this.stockRef;
  }
  // Fetch WoReceipts List
  getWoReceiptsList() {
    this.stocksRef = this.db.list( this.path );
    return this.stocksRef;
  }
  // Update WoReceipt Object
  updateWoReceipt(woReceipt: WoReceipt) {
    this.stockRef = this.db.object(this.path + woReceipt.$key);
    let item : Partial<WoReceipt>  = {
      ...woReceipt
    }
    delete item?.$key;
    this.stockRef?.update(item);
  }
  // Delete WoReceipt Object
  deleteWoReceipt(id: string) {
    this.stockRef = this.db.object( this.path + id);
    this.stockRef.remove();
  }
}
