import { Injectable } from '@angular/core';

import { WoProduct } from '../interfaces/wo-product';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoProductCrudService {

  woProductsRef: AngularFireList<any> | undefined;
  woProductRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/products/' ;
  }

  // Create WoProduct
  addWoProduct(woProduct: WoProduct) {
    this.woProductsRef = this.db.list( this.path );
    const item = {
      ...woProduct
    }
    this.woProductsRef?.push( item );
  }
  // Fetch Single WoProduct Object
  getWoProduct(id: string) {
    this.woProductRef = this.db.object( this.path + id);
    return this.woProductRef;
  }
  // Fetch WoProducts List
  getWoProductsList() {
    this.woProductsRef = this.db.list( this.path );
    return this.woProductsRef;
  }
  // Update WoProduct Object
  updateWoProduct(woProduct: WoProduct) {
    this.woProductRef = this.db.object(this.path + woProduct.$key);
   // console.log("P inside", woProduct);
    let item : Partial<WoProduct>  = {
      ...woProduct
    }
    delete item?.$key;
    this.woProductRef?.update(item);
  }
  // Delete WoProduct Object
  deleteWoProduct(id: string) {
    this.woProductRef = this.db.object(this.path + id);
    this.woProductRef.remove();
  }
}
