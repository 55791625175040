import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { PackagingTypeCrudService } from 'src/app/shared/services/packaging-type-crud.service';
import { PackagingType } from 'src/app/shared/interfaces/packaging-type';
import { Unit } from 'src/app/shared/interfaces/unit';
import { UnitCrudService } from 'src/app/shared/services/unit-crud.service';

@Component({
  selector: 'app-packaging-types',
  templateUrl: './packaging-types.component.html',
  styleUrls: ['./packaging-types.component.scss']
})
export class PackagingTypesComponent implements OnInit {

  //checked2: boolean = false;

  packagingTypes: PackagingType[];
  packagingType: PackagingType;
  selectedPackagingTypes: PackagingType[] = [];

  packagingTypeDialog: boolean = false;

  submitted: boolean = false;

  units: Unit[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private packagingTypeService : PackagingTypeCrudService,
    private unitService : UnitCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Packaging types'}
    ];

    this.packagingTypes = [];
    this.packagingType = { } as PackagingType ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.packagingTypeService.GetPackagingTypesList(); 
    s.snapshotChanges().subscribe(data => {
      this.packagingTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.packagingTypes.push(jsonItem as PackagingType);
        }
      })
    });

    let u = this.unitService.GetUnitsList(); 
    u.snapshotChanges().subscribe(data => {
      this.units = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.units.push(jsonItem as unknown as Unit);
        }
      })
    });

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'minCapacity', header: 'Min capacity' },
      { field: 'maxCapacity', header: 'Max capacity' },
      { field: 'unit', header: 'Unit' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.packagingTypeService.GetPackagingTypesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.PackagingTypes);
            doc.save('content-types.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.packagingTypes);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "content-types");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.packagingTypes, 'PackagingTypes', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedPackagingTypes, 'content-types', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.packagingType = {} as PackagingType;
    this.submitted = false;
    this.packagingTypeDialog = true;
  }

  deleteSelectedPackagingTypes() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Packaging Types?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.packagingTypes = this.packagingTypes.filter(val => !this.selectedPackagingTypes.includes(val));
          this.packagingTypes.forEach( pallet => {
            this.packagingTypeService.DeletePackagingType(pallet.$key);
          } );
          
          this.selectedPackagingTypes = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging types deleted', life: 3000});
      }
  });
  }

  editPackagingType(packagingType: PackagingType) {
    this.packagingType = {...packagingType};
    this.packagingTypeDialog = true;
  }

  deletePackagingType(packagingType: PackagingType) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + packagingType.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.packagingTypes = this.packagingTypes.filter(val => val.$key !== packagingType.$key);
            this.packagingType = {} as PackagingType;
            this.packagingTypeService.DeletePackagingType(packagingType.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.packagingTypeDialog = false;
    this.submitted = false;
  }

  savePackagingType() {
    this.submitted = true;
    if (this.packagingType.$key) {
      this.packagingTypes[this.findIndexById(this.packagingType.$key)] = this.packagingType;                
      this.packagingTypeService.UpdatePackagingType(this.packagingType);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';
      this.packagingTypeService.AddPackagingType(this.packagingType);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Created', life: 3000});
      this.packagingTypes = [...this.packagingTypes];
    }
    this.packagingTypeDialog = false;
    this.packagingType = { } as PackagingType;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.packagingTypes.length; i++) {
        if (this.packagingTypes[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completePackagingType(packagingType : PackagingType) {
    const packagingTypeTmp = {
      ...packagingType
    };
    console.log("SAVE", packagingTypeTmp);
    this.packagingTypeService.UpdatePackagingType(  packagingTypeTmp );
  }

}
