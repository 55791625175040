import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ZLongComponent } from './components/z-long/z-long.component';
import { ZShortComponent } from './components/z-short/z-short.component';
import { ItemListComponent } from './components/items/item-list/item-list.component';
//import { PalletBwsListComponent } from './components/pallets-bws/pallet-bws-list/pallet-bws-list.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { LevelListComponent } from './components/levels/level-list/level-list.component';
import { ColumnListComponent } from './components/columns/column-list/column-list.component';
import { LocationListComponent } from './components/locations/location-list/location-list.component';
import { RowListComponent } from './components/rows/row-list/row-list.component';
import { ContentTypeComponent } from './components/types/content-type/content-type.component';
import { PackagingTypesComponent } from './components/types/packaging-types/packaging-types.component';
import { ProductTypesComponent } from './components/types/product-types/product-types.component';
import { ContainerTypesComponent } from './components/types/container-types/container-types.component';
import { UnitComponent } from './components/types/unit/unit.component';
import { StockComponent } from './components/stock/stock.component';
import { TransformStepsComponent } from './components/transform/transform-steps/transform-steps.component';
import { MoveStepsComponent } from './components/move/move-steps/move-steps.component';
import { TransformSelectionComponent } from './components/transform/transform-selection/transform-selection.component';
import { TransformToProductsComponent } from './components/transform/transform-to-products/transform-to-products.component';
import { TransformConfirmationComponent } from './components/transform/transform-confirmation/transform-confirmation.component';
import { MoveSelectionComponent } from './components/move/move-selection/move-selection.component';
import { MoveLocationComponent } from './components/move/move-location/move-location.component';
import { TransformTableComponent } from './components/transform/transform-table/transform-table.component';
import { MovementTableComponent } from './components/move/movement-table/movement-table.component';
import { ProductComponent } from './components/product/product.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { ReceptionsComponent } from './components/receptions/receptions.component';
import { RecipesComponent } from './components/transform/recipes/recipes.component';
import { DeliveryMenComponent } from './components/delivery-men/delivery-men.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { ProductsPerLocationComponent } from './components/products-per-location/products-per-location.component';
import { ProductsPerTypeComponent } from './components/products-per-type/products-per-type.component';
import { CompaniesComponent } from './components/clients/companies/companies.component';
import { OutputsComponent } from './components/outputs/outputs.component';
//import { MovesComponent } from './movement/moves/moves.component';
//import { TransformsComponent } from './transformation/transforms/transforms.component';
import { WithdrawsComponent } from './withdrawal/withdraws/withdraws.component';
//import { ReceivesComponent } from './reception/receives/receives.component';
import { ReceivesComponent } from './wo/receives/receives.component';
import { DriesComponent } from './wo/dries/dries.component';
import { MovesComponent } from './wo/moves/moves.component';
import { TransformsComponent } from './wo/transforms/transforms.component';
import { WoProductTypeComponent } from './wo/wo-product-type/wo-product-type.component';
import { WoProductComponent } from './wo/wo-product/wo-product.component';
import { WoReceptionsComponent } from './wo/wo-receptions/wo-receptions.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders.component';
import { PurchaseOrderComponent } from './purchases/purchase-order/purchase-order.component';
import { DryTableComponent } from './transformation/dry-table/dry-table.component';
import { TaProductsComponent } from './ta/ta-products/ta-products.component';
import { TypesComponent } from './barrels/types/types.component';
import { CoopersComponent } from './barrels/coopers/coopers.component';
import { VolumesComponent } from './barrels/volumes/volumes.component';
import { WoodsComponent } from './barrels/woods/woods.component';
import { ColoursComponent } from './wines/colours/colours.component';
import { GrapeVarietiesComponent } from './wines/grape-varieties/grape-varieties.component';
import { BarrelCurrentComponent } from './barrels/barrel-current/barrel-current.component';
import { BarrelArchiveComponent } from './barrels/barrel-archive/barrel-archive.component';
import { PalletCurrentComponent } from './pallets/pallet-current/pallet-current.component';
import { PalletArchiveComponent } from './pallets/pallet-archive/pallet-archive.component';
import { WineCurrentComponent } from './wines/wine-current/wine-current.component';
import { WineArchiveComponent } from './wines/wine-archive/wine-archive.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { RacksComponent } from './barrels/racks/racks.component';
import { BatchesComponent } from './wines/batches/batches.component';
import { GrowersComponent } from './wines/growers/growers.component';
import { RegionsComponent } from './wines/regions/regions.component';
import { TanksComponent } from './wines/tanks/tanks.component';
import { WithdrawalTableComponent } from './withdrawal/withdrawal-table/withdrawal-table.component';


const routes: Routes = [

  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  //{ path: 'dashboard', component: PalletBwsListComponent },
  { path: 'dashboard', component: WoProductComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },

  { path: 'z-long', component: ZLongComponent },
  { path: 'z-short', component: ZShortComponent },


 // { path: 'pallets2/add', component: AddPalletComponent },
 // { path: 'pallets2/edit/:id', component: EditPalletComponent },

  // Barrel module
  { path: 'barrels/types'   , component: TypesComponent, canActivate: [AuthGuard] },
  { path: 'barrels/coopers' , component: CoopersComponent, canActivate: [AuthGuard] },
  { path: 'barrels/volumes' , component: VolumesComponent, canActivate: [AuthGuard] },
  { path: 'barrels/woods'   , component: WoodsComponent, canActivate: [AuthGuard] },
  { path: 'barrels/current' , component: BarrelCurrentComponent, canActivate: [AuthGuard] },
  { path: 'barrels/archive' , component: BarrelArchiveComponent, canActivate: [AuthGuard] },

   // Pallet module
  { path: 'pallets/pallets'   , component: TypesComponent, canActivate: [AuthGuard] },
  { path: 'pallets/racks'     , component: RacksComponent, canActivate: [AuthGuard] },
  { path: 'pallets/tanks'     , component: TanksComponent, canActivate: [AuthGuard] },
  { path: 'pallets/current'   , component: PalletCurrentComponent, canActivate: [AuthGuard] },
  { path: 'pallets/archive'   , component: PalletArchiveComponent, canActivate: [AuthGuard] },

  // Wine module
  { path: 'wines/colours' , component: ColoursComponent, canActivate: [AuthGuard] },
  { path: 'wines/grape-varieties' , component: GrapeVarietiesComponent, canActivate: [AuthGuard] },
  { path: 'wines/batches' , component: BatchesComponent, canActivate: [AuthGuard] },
  { path: 'wines/growers' , component: GrowersComponent, canActivate: [AuthGuard] },
  { path: 'wines/regions' , component: RegionsComponent, canActivate: [AuthGuard] },
  { path: 'wines/current'   , component: WineCurrentComponent, canActivate: [AuthGuard]},
  { path: 'wines/archive'   , component: WineArchiveComponent, canActivate: [AuthGuard] },

  // WO Product module
  { path: 'wo-product/manage/product-types', component: WoProductTypeComponent },

  // WO Product module
  { path: 'ta-product/products', component: TaProductsComponent },
  
  // Purchase module
  { path: 'purchase/orders', component: PurchaseOrdersComponent },
  { path: 'purchase/order', component: PurchaseOrderComponent },

  // Product module
  { path: 'product/manage/units', component: UnitComponent },
  { path: 'product/manage/content-types', component: ContentTypeComponent },
  { path: 'product/manage/packaging-types', component: PackagingTypesComponent },
  { path: 'product/manage/product-types', component: ProductTypesComponent },
  { path: 'product/manage/container-types', component: ContainerTypesComponent },
  { path: 'product/products', component: WoProductComponent },
  { path: 'product/products-by-location', component: ProductsPerLocationComponent },
  { path: 'product/products-per-type', component: ProductsPerTypeComponent },

  // Reception module
  { path: 'reception/manage/delivery-men', component: DeliveryMenComponent },
  { path: 'reception/manage/providers', component: ProvidersComponent },
  { path: 'reception/receptions', component: WoReceptionsComponent },
  { path: 'reception/receives', component: ReceivesComponent },

  //{ path: 'pallets', component: PalletBwsListComponent },
  { path: 'products', component: ProductTypeComponent },
  { path: 'stock', component: StockComponent },

  // Movement module
  { path: 'movement/move/selection', component: MoveSelectionComponent },
  { path: 'movement/move/to', component: MoveLocationComponent },
  { path: 'movement/move/confirmation', component: MoveStepsComponent },
  { path: 'movement/moves', component: MovesComponent },
  { path: 'movement/move', component: MoveStepsComponent },
  { path: 'movement/movement-table', component: MovementTableComponent },
  { path: 'movement/manage/locations', component: LocationListComponent },
  { path: 'movement/manage/rows', component: RowListComponent },
  { path: 'movement/manage/columns', component: ColumnListComponent },
  { path: 'movement/manage/levels', component: LevelListComponent },
   
  // Transformation module
  { path: 'transformation/manage/recipes', component: RecipesComponent },
  { path: 'transformation/selection', component: TransformSelectionComponent },
  { path: 'transformation/transformation', component: TransformToProductsComponent },
  { path: 'transformation/confirmation', component: TransformConfirmationComponent },
  { path: 'transformation/transformation-table', component: TransformTableComponent },
  { path: 'transformation/transforms', component: TransformsComponent },
  { path: 'transformation/dries', component: DriesComponent },
  { path: 'transformation/dry-table', component: DryTableComponent },
  { path: 'transformation/transform', component: TransformStepsComponent },

  // Withdrawal module
  { path: 'withdrawal/withdraws', component: WithdrawsComponent },
  { path: 'withdrawal/withdrawal-table', component: WithdrawalTableComponent },

  { path: 'stock', component: StockComponent },

  // Client / Output module
  { path: 'clients/manage/outputs', component: OutputsComponent },
  { path: 'clients/manage/clients', component: ClientListComponent },
  { path: 'clients/manage/companies', component: CompaniesComponent },
  
{ path: 'items', component: ItemListComponent },


  //{ path: 'my account', component: MyProfileComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
