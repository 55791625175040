import { Injectable } from '@angular/core';
import { WoEvent } from '../interfaces/wo-event';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoEventCrudService {

  woEventsRef: AngularFireList<any> | undefined;
  woEventRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/events/' ;
  }

  // Create WoEvent
  addWoEvent(woEvent: WoEvent) {
    this.woEventsRef = this.db.list(this.path);
  
    const item = {
      ...woEvent
    }
    this.woEventsRef?.push( item );
  }
  // Fetch Single WoEvent Object
  getWoEvent(id: string) {
    this.woEventRef = this.db.object(this.path + id);
    return this.woEventRef;
  }
  // Fetch WoEvents List
  getWoEventsList() {
    this.woEventsRef = this.db.list(this.path);
    return this.woEventsRef;
  }
  // Update WoEvent Object
  updateWoEvent(woEvent: WoEvent) {
    this.woEventRef = this.db.object(this.path + woEvent.$key);
   // console.log("P inside", woWoEvent);
    let item : Partial<WoEvent>  = {
      ...woEvent
    }
    delete item?.$key;
    this.woEventRef?.update(item);
  }
  // Delete WoEvent Object
  deleteWoEvent(id: string) {
    this.woEventRef = this.db.object(this.path + id);
    this.woEventRef.remove();
  }
}
