<mat-toolbar color="primary" class='white mat-elevation-z16'  >
    <div>
      <span class="gap"><a [routerLink]="['/']"><img style="vertical-align:middle;background-color: #000000" src="{{ appLogo }}" height="55px" ></a></span>
      <span class="logotitle" style="background-color: #000000"></span>
    </div>



    <span class="middle">

      <!-- <span *ngIf="authService.isLoggedIn && features && features.length > -1" class="semi_gap"  >
        <button mat-button [routerLink]="['/pallets']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dashboard.png" />
            <span style="color: white">Dashboard</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn">
        <button mat-button [routerLink]="['/receptions']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Reception</span>
        </button>
      </span> -->

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.barrels">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="barrelsMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_barrel.png" />
           <span style="color: white">Barrels</span>
        </button> 
      <mat-menu #barrelsMenu="matMenu">
        <div *ngIf="modules.barrels" >
          <button mat-button [routerLink]="['/barrels/current']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_barrel.png" />
            <span style="color: white">Current</span>
          </button>
        </div>
        <div *ngIf="modules.barrels" >
          <button mat-button [routerLink]="['/barrels/archive']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
            <span style="color: white">Archive</span>
          </button>
        </div>
      </mat-menu>
    </span>

    <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.pallets">
      <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="palletsMenu">
         <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/pallet.png" />
         <span style="color: white">Pallets</span>
      </button> 
    <mat-menu #palletsMenu="matMenu">
      <div *ngIf="modules.pallets" >
        <button mat-button [routerLink]="['/pallets/current']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/pallet.png" />
          <span style="color: white">Current</span>
        </button>
      </div>
      <div *ngIf="modules.pallets" >
        <button mat-button [routerLink]="['/pallets/archive']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
          <span style="color: white">Archive</span>
        </button>
      </div>
    </mat-menu>
  </span>


 
  <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wines">
    <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="palletsMenu">
       <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_tags.png" />
       <span style="color: white">Batches</span>
    </button> 
  <mat-menu #palletsMenu="matMenu">
    <div *ngIf="modules.pallets" >
      <button mat-button [routerLink]="['/wines/batches']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_tags.png" />
        <span style="color: white">Current</span>
      </button>
    </div>
    <div *ngIf="modules.pallets" >
      <button mat-button [routerLink]="['/wines/archive']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
        <span style="color: white">Archive</span>
      </button>
    </div>
  </mat-menu>
</span>


<!-- 
<span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wines">
  <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="palletsMenu">
     <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
     <span style="color: white">Wines</span>
  </button> 
<mat-menu #palletsMenu="matMenu">
  <div *ngIf="modules.pallets" >
    <button mat-button [routerLink]="['/wines/current']">
      <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
      <span style="color: white">Current</span>
    </button>
  </div>
  <div *ngIf="modules.pallets" >
    <button mat-button [routerLink]="['/wines/archive']">
      <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
      <span style="color: white">Archive</span>
    </button>
  </div>
</mat-menu>
</span> -->

    

      
      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.warehouse && false">
          <button mat-button [routerLink]="['/ta-product/products']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
            <span style="color: white">Stock</span>
          </button>   
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.warehouse">
        <button mat-button [routerLink]="['/product/products']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
          <span style="color: white">Products</span>
        </button>   
    </span>
     
      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn">
          <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="productsMenu">
             <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
             <span style="color: white">Products</span>
          </button> 
        <mat-menu #productsMenu="matMenu">
          <div >
            <button mat-menu-item [routerLink]="['/product/products-by-location']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/location.png" />
             <span style="color: white">By location</span>
          </button> 
          </div>
          <div>
            <button mat-menu-item [routerLink]="['/product/products-by-type']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/packaging.png" />
              <span style="color: white">By product type</span>
           </button> 
          </div>
          <div>
            <button mat-menu-item [routerLink]="['/product/products']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
              <span style="color: white">All</span>
           </button> 
          </div>
        </mat-menu>
      </span> -->

      <span class="semi_gap" *ngIf="authService.isLoggedIn">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="actionsMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/tables.png" />
           <span style="color: white">Actions</span>
        </button> 
      <mat-menu #actionsMenu="matMenu">
        <div *ngIf="modules.purchase" >
          <button mat-button [routerLink]="['/purchase/order']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/purchase_order.png" />
            <span style="color: white">Purchase</span>
          </button>
        </div>
        <div *ngIf="modules.receive" >
          <button mat-button [routerLink]="['/reception/receives']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Receive</span>
          </button>
        </div>
        <div *ngIf="modules.dry" >
          <button mat-button [routerLink]="['/transformation/dries']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dryer.png" />
            <span style="color: white">Dry</span>
          </button>
        </div>
        <div *ngIf="modules.move" >
          <button mat-button [routerLink]="['/movement/moves']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/move.png" />
            <span style="color: white">Move</span>
          </button>
        </div>
        <div *ngIf="modules.transform" >
          <button mat-button [routerLink]="['/transformation/transforms']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/transform.png" />
            <span style="color: white">Transform</span>
          </button>
        </div>
        <div *ngIf="modules.output" >
          <button mat-button [routerLink]="['/withdrawal/withdraws']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/outputs.png" />
            <span style="color: white">Withdraw</span>
          </button>
        </div>
      </mat-menu>
    </span>



      <span class="semi_gap" *ngIf="authService.isLoggedIn">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="tablesMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/tables.png" />
           <span style="color: white">Tables</span>
        </button> 
      <mat-menu #tablesMenu="matMenu">
        <div *ngIf="modules.purchase" >
          <button mat-button [routerLink]="['/purchase/orders']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/purchase_order.png" />
            <span style="color: white">Purchases</span>
          </button>
        </div>
        <div *ngIf="modules.receive" >
          <button mat-button [routerLink]="['/reception/receptions']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Reception</span>
          </button>
        </div>
        <div *ngIf="modules.dry" >
          <button mat-button [routerLink]="['/transformation/dry-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dryer.png" />
            <span style="color: white">Drying</span>
          </button>
        </div>
        <div *ngIf="modules.move" >
          <button mat-button [routerLink]="['/movement/movement-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/move.png" />
            <span style="color: white">Movement</span>
          </button>
        </div>
        <div *ngIf="modules.transform" >
          <button mat-button [routerLink]="['/transformation/transformation-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/transform.png" />
            <span style="color: white">Transformation</span>
          </button>
        </div>
        <div *ngIf="modules.output" >
          <button mat-button [routerLink]="['/withdrawal/withdrawal-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/outputs.png" />
            <span style="color: white">Withdrawal</span>
          </button>
        </div>
        <div *ngIf="modules.update" >
          <button mat-button [routerLink]="['/output/output-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/updates.png" />
            <span style="color: white">Updates</span>
          </button>
        </div>
        <div *ngIf="modules.event" >
          <button mat-button [routerLink]="['/output/output-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/events.png" />
            <span style="color: white">Events</span>
          </button>
        </div>
      </mat-menu>
    </span>

    
  
    </span>
 
    <span class="gap" *ngIf="authService.isLoggedIn">
      <button mat-button (click)="sidenav.toggle()">
        <!-- <img style="vertical-align:middle;" src="assets/images/news-icon.png" height="20px" > -->
        <mat-icon style="color: white; vertical-align:middle;">settings</mat-icon>
        <!-- <span class="greytext"> Settings </span> -->
      </button>
    </span>
  
  
    <span>
      <div *ngIf="true">
        <button mat-button [matMenuTriggerFor]="settingsMenu">
          <mat-icon color="accent" style="color: white; vertical-align:middle;">account_circle</mat-icon>
        </button>
      </div>
      <mat-menu   backdropClass="customize" color="primary" #settingsMenu="matMenu">
        <div >
          <button mat-menu-item [routerLink]="['my-account']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">face</mat-icon>
            <span style="color: white">My account</span>
          </button>
        </div>
        <div>
          <button mat-menu-item (click)="sidenav.close();logout()">
              <mat-icon color="accent" style="color: white; vertical-align:middle;">settings_power</mat-icon>
              <span style="color: white">Log-out</span>
          </button>
        </div>
      </mat-menu>
    </span>
  
</mat-toolbar>
