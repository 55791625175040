import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthService } from './shared/services/auth.service';
import { SettingsComponent } from './components/settings/settings.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppMaterialModule } from './modules/app-material/app-material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZLongComponent } from './components/z-long/z-long.component';
import { ZShortComponent } from './components/z-short/z-short.component';
//import { AddPalletComponent } from './components/pallets/add-pallet/add-pallet.component';
//import { EditPalletComponent } from './components/pallets/edit-pallet/edit-pallet.component';
//import { PalletListComponent } from './components/pallets/pallet-list/pallet-list.component';

//import { ToastrModule } from 'ngx-toastr';

import { NgxPaginationModule } from 'ngx-pagination';

import { GoogleChartsModule } from 'angular-google-charts';
import { ItemListComponent } from './components/items/item-list/item-list.component';


import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';  
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule } from 'primeng/radiobutton';
import {InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';
import { PickListModule } from 'primeng/picklist';
import { TreeModule } from 'primeng/tree';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { InplaceModule } from 'primeng/inplace';
import { DataViewModule } from 'primeng/dataview';
import {ChipsModule} from 'primeng/chips';


import { HttpClientModule } from '@angular/common/http';
import { ProductService } from './shared/services/product.service';
//import { PalletBwsListComponent } from './components/pallets-bws/pallet-bws-list/pallet-bws-list.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { LocationListComponent } from './components/locations/location-list/location-list.component';
import { RowListComponent } from './components/rows/row-list/row-list.component';
import { ColumnListComponent } from './components/columns/column-list/column-list.component';
import { LevelListComponent } from './components/levels/level-list/level-list.component';
import { ContentTypeComponent } from './components/types/content-type/content-type.component';
import { PackagingTypesComponent } from './components/types/packaging-types/packaging-types.component';
import { ProductTypesComponent } from './components/types/product-types/product-types.component';
import { ContainerTypesComponent } from './components/types/container-types/container-types.component';
import { UnitComponent } from './components/types/unit/unit.component';
import { StockComponent } from './components/stock/stock.component';
import { MoveSelectionComponent } from './components/move/move-selection/move-selection.component';
import { TransformSelectionComponent } from './components/transform/transform-selection/transform-selection.component';
import { TransformConfirmationComponent } from './components/transform/transform-confirmation/transform-confirmation.component';
import { MoveConfirmationComponent } from './components/move/move-confirmation/move-confirmation.component';
import { MoveLocationComponent } from './components/move/move-location/move-location.component';
import { TransformToProductsComponent } from './components/transform/transform-to-products/transform-to-products.component';
import { TransformStepsComponent } from './components/transform/transform-steps/transform-steps.component';
import { MoveStepsComponent } from './components/move/move-steps/move-steps.component';
import { TransformTableComponent } from './components/transform/transform-table/transform-table.component';
import { MovementTableComponent } from './components/move/movement-table/movement-table.component';
import { ProductComponent } from './components/product/product.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { ReceptionsComponent } from './components/receptions/receptions.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { RecipesComponent } from './components/transform/recipes/recipes.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { DeliveryMenComponent } from './components/delivery-men/delivery-men.component';

import { QRCodeModule } from 'angularx-qrcode';
import { ProductsPerLocationComponent } from './components/products-per-location/products-per-location.component';
import { ProductsPerTypeComponent } from './components/products-per-type/products-per-type.component';
import { CompaniesComponent } from './components/clients/companies/companies.component';

import { NgxBarcode6Module } from 'ngx-barcode6';
import { RecipeItemsComponent } from './components/transform/recipe-items/recipe-items.component';
import { OutputsComponent } from './components/outputs/outputs.component';
import { TransformsComponent } from './transformation/transforms/transforms.component';
import { MovesComponent } from './movement/moves/moves.component';
import { WithdrawsComponent } from './withdrawal/withdraws/withdraws.component';
import { ReceivesComponent } from './reception/receives/receives.component';

import { ReceivesComponent as WoReceivesComponent } from './wo/receives/receives.component';
import { DriesComponent } from './wo/dries/dries.component';
import { MovesComponent as WoMovesComponent } from './wo/moves/moves.component';
import { TransformsComponent as WoTransformsComponent } from './wo/transforms/transforms.component';
import { WoProductTypeComponent } from './wo/wo-product-type/wo-product-type.component';
import { WoRecipesComponent } from './wo/wo-recipes/wo-recipes.component';
import { WoProductComponent } from './wo/wo-product/wo-product.component';
import { WoReceptionsComponent } from './wo/wo-receptions/wo-receptions.component';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders.component';
import { PurchaseOrderComponent } from './purchases/purchase-order/purchase-order.component';
import { DryTableComponent } from './transformation/dry-table/dry-table.component';
import { TaProductsComponent } from './ta/ta-products/ta-products.component';
import { TypesComponent } from './barrels/types/types.component';
import { CoopersComponent } from './barrels/coopers/coopers.component';
import { VolumesComponent } from './barrels/volumes/volumes.component';
import { WoodsComponent } from './barrels/woods/woods.component';
import { BarrelArchiveComponent } from './barrels/barrel-archive/barrel-archive.component';
import { BarrelCurrentComponent } from './barrels/barrel-current/barrel-current.component';
import { PalletCurrentComponent } from './pallets/pallet-current/pallet-current.component';
import { PalletArchiveComponent } from './pallets/pallet-archive/pallet-archive.component';
import { ColoursComponent } from './wines/colours/colours.component';
import { GrapeVarietiesComponent } from './wines/grape-varieties/grape-varieties.component';
import { WineCurrentComponent } from './wines/wine-current/wine-current.component';
import { WineArchiveComponent } from './wines/wine-archive/wine-archive.component';
import { RacksComponent } from './barrels/racks/racks.component';
import { BatchesComponent } from './wines/batches/batches.component';
import { RegionsComponent } from './wines/regions/regions.component';
import { GrowersComponent } from './wines/growers/growers.component';
import { TanksComponent } from './wines/tanks/tanks.component';
import { WithdrawalTableComponent } from './withdrawal/withdrawal-table/withdrawal-table.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    SettingsComponent,
    NavigationBarComponent,
    FooterComponent,
    ZLongComponent,
    ZShortComponent,
    ItemListComponent,
    ClientListComponent,
    LocationListComponent,
    RowListComponent,
    ColumnListComponent,
    LevelListComponent,

    ContentTypeComponent,
    PackagingTypesComponent,
    ProductTypesComponent,
    ContainerTypesComponent,
    UnitComponent,
    StockComponent,
    MoveSelectionComponent,
    TransformSelectionComponent,
    TransformConfirmationComponent,
    MoveConfirmationComponent,
    MoveLocationComponent,
    TransformToProductsComponent,
    TransformStepsComponent,
    MoveStepsComponent,
    TransformTableComponent,
    MovementTableComponent,
    ProductComponent,
    ProductTypeComponent,
    ReceptionsComponent,
    MyProfileComponent,
    RecipesComponent,
    ProvidersComponent,
    DeliveryMenComponent,
    ProductsPerLocationComponent,
    ProductsPerTypeComponent,
    CompaniesComponent,
    RecipeItemsComponent,
    OutputsComponent,
    TransformsComponent,
    MovesComponent,
    WithdrawsComponent,
    ReceivesComponent,

    WoReceivesComponent,
    WoMovesComponent,
    WoTransformsComponent,
    DriesComponent,
    WoProductTypeComponent,
    WoRecipesComponent,
    WoProductComponent,
    WoReceptionsComponent,
    PurchaseOrdersComponent,
    PurchaseOrderComponent,
    DryTableComponent,
    TaProductsComponent,
    TypesComponent,
    CoopersComponent,
    VolumesComponent,
    WoodsComponent,
    BarrelArchiveComponent,
    BarrelCurrentComponent,
    PalletCurrentComponent,
    PalletArchiveComponent,
    ColoursComponent,
    GrapeVarietiesComponent,
    WineCurrentComponent,
    WineArchiveComponent,
    RacksComponent,
    BatchesComponent,
    RegionsComponent,
    GrowersComponent,
    TanksComponent,
    WithdrawalTableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,

    FormsModule, 
    ReactiveFormsModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatMenuModule,
    // MatSidenavModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    //AngularFirestoreModule,
   // ToastrModule.forRoot(),

    NgxPaginationModule,
    GoogleChartsModule,


    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    BreadcrumbModule,
    InputSwitchModule,
    StepsModule,
    PickListModule,
    TreeModule,
    ConfirmPopupModule,
    CardModule,
    TimelineModule,
    TagModule,
    InplaceModule,
    DataViewModule,
    ChipsModule,

    HttpClientModule,

    QRCodeModule,
    NgxBarcode6Module
  ],
  providers: [AuthService, MessageService, ConfirmationService, ProductService],
  bootstrap: [AppComponent]
})
export class AppModule { }
