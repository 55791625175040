import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TransformService } from 'src/app/shared/services/transform.service';

@Component({
  selector: 'app-transform-selection',
  templateUrl: './transform-selection.component.html',
  styleUrls: ['./transform-selection.component.scss']
})
export class TransformSelectionComponent implements OnInit {

    items: MenuItem[] = [];

    personalInformation: any;

    submitted: boolean = false;

    activeIndex: number = 0;

    constructor(public transformService: TransformService, private router: Router) { }

    ngOnInit() { 
        this.personalInformation = this.transformService.getTicketInformation().personalInformation;

        this.items = [{
          label: 'Selection',
          routerLink: '/transform/selection'
      },
      {
          label: 'Transformation',
          routerLink: '/transform/transformation'
      },
      {
          label: 'Confirmation',
          routerLink: '/transform/confirmation'
      }
  ];
    }

    nextPage() {
        if (this.personalInformation.firstname && this.personalInformation.lastname && this.personalInformation.age) {
            this.transformService.ticketInformation.personalInformation = this.personalInformation;
            this.router.navigate(['/transform/tranformation']);
            return;
        }
        this.submitted = true;
    }
}
