import { Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class CompanyCrudService {

  companysRef: AngularFireList<any> | undefined;
  companyRef: AngularFireObject<any> | undefined;

  MODULE_NAME : string = 'clients';
  TABLE_NAME : string = 'companies';

  constructor(private db: AngularFireDatabase) {}

  // Create Company
  addCompany(company: Company) {
    const item = {
      ...company
    }
    this.companysRef?.push( item );
  }
  // Fetch Single Company Object
  getCompany(id: string) {
    this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    return this.companyRef;
  }
  // Fetch Companys List
  getCompanysList() {
    this.companysRef = this.db.list(this.MODULE_NAME + '/' + this.TABLE_NAME + '/');
    return this.companysRef;
  }
  // Update Company Object
  updateCompany(company: Company) {
    this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + company.$key);
   // console.log("P inside", provider);
    let item : Partial<Company>  = {
      ...company
    }
    delete item?.$key;
    this.companyRef?.update(item);
  }
  // Delete Company Object
  deleteCompany(id: string) {
    this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    this.companyRef.remove();
  }
}
