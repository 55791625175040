<div class="container" style="flex: 1">


  <div class="column mat-elevation-z4" style="width: 285px">
    <form class="column" [formGroup]="signupForm">

      <div class="title">
        <span class="greytext title-size">Create your account</span>
      </div>
      <div class="input-column" style="width: 100%;">
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Email" type="email" formControlName="email" autocomplete="email" required>
          <mat-hint *ngIf="!emailInput?.value">Enter your email address</mat-hint>
        </mat-form-field>
      </div>

      <div class="input-column">
        <mat-form-field>
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
          <mat-icon matSuffix class="cursor-pointer" style="color: #58585e;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint>
        </mat-form-field>
      </div>

      <div class="button" style="margin-bottom: 10px;">
        <button mat-raised-button style="color: #58585e;"  (click)="signUp()" [disabled]="shouldEnableSubmit()">Sign up</button>
      </div>
    </form>
    <div class="input-column" routerLink="/sign-in"><span class="forgot">Already have an account? Sign-in</span></div>

  </div>

</div>



<!-- <div class="displayTable">
    <div class="displayTableCell">
      <div class="authBlock">
        <h3>Sign Up</h3>
        <div class="formGroup">
          <input
            type="email"
            class="formControl"
            placeholder="Email Address"
            #userEmail
            required
          />
        </div>
        <div class="formGroup">
          <input
            type="password"
            class="formControl"
            placeholder="Password"
            #userPwd
            required
          />
        </div>
        <div class="formGroup">
          <input
            type="button"
            class="btn btnPrimary"
            value="Sign Up"
            (click)="authService.SignUp(userEmail.value, userPwd.value)"
          />
        </div>
        <div class="formGroup">
          <span class="or"><span class="orInner">Or</span></span>
        </div>
        <div class="formGroup">
          <button
            type="button"
            class="btn googleBtn"
            (click)="authService.GoogleAuth()"
          >
            <i class="fab fa-google-plus-g"></i>
            Continue with Google
          </button>
        </div>
      </div>
      <div class="redirectToLogin">
        <span
          >Already have an account?
          <span class="redirect" routerLink="/sign-in">Log In</span></span
        >
      </div>
    </div> -->