import { Injectable } from '@angular/core';
import { DeliveryMan } from '../interfaces/delivery-man';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryManCrudService {

  deliveryMansRef: AngularFireList<any> | undefined;
  deliveryManRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/deliveryMen/' ;
  }

  // Create DeliveryMan
  addDeliveryMan(deliveryMan: DeliveryMan) {
    const item = {
      ...deliveryMan
    }
    this.deliveryMansRef?.push( item );
  }
  // Fetch Single DeliveryMan Object
  getDeliveryMan(id: string) {
    this.deliveryManRef = this.db.object( this.path + id);
    return this.deliveryManRef;
  }
  // Fetch DeliveryMans List
  getDeliveryMansList() {
    this.deliveryMansRef = this.db.list(this.path);
    return this.deliveryMansRef;
  }
  // Update DeliveryMan Object
  updateDeliveryMan(deliveryMan: DeliveryMan) {
    this.deliveryManRef = this.db.object(this.path + deliveryMan.$key);
    let item : Partial<DeliveryMan>  = {
      ...deliveryMan
    }
    delete item?.$key;
    this.deliveryManRef?.update(item);
  }
  // Delete DeliveryMan Object
  deleteDeliveryMan(id: string) {
    this.deliveryManRef = this.db.object(this.path + id);
    this.deliveryManRef.remove();
  }
}
