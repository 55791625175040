<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>

        <!-- <div style="width: 70%;">
            <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
        </div> -->
        
        <!-- <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedProductTypes()" [disabled]="!selectedProductTypes || !selectedProductTypes.length "></button>
        </div>  -->

    </div>

    <div style="width: 70%;margin-top: 40px;">
        <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
    </div>

</div>

<div class="card">
    <p-toast></p-toast>
    
</div>
