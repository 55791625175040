import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { UnitCrudService } from 'src/app/shared/services/unit-crud.service';
import { Unit } from 'src/app/shared/interfaces/unit';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  units: Unit[];
  unit: Unit;
  selectedUnits: Unit[] = [];

  unitDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private unitService : UnitCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Content types'}
    ];

    this.units = [];
    this.unit = { } as Unit ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.unitService.GetUnitsList(); 
    s.snapshotChanges().subscribe(data => {
      this.units = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.units.push(jsonItem as Unit);
        }
      })
    })

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'factor', header: 'Factor' },
      { field: 'unitBase', header: 'Base (unit)' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.unitService.GetUnitsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Units);
            doc.save('units.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.units);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "units");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.units, 'Units', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedUnits, 'units', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.unit = {} as Unit;
    this.submitted = false;
    this.unitDialog = true;
  }

  deleteSelectedUnits() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Content Types?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.units = this.units.filter(val => !this.selectedUnits.includes(val));
          this.units.forEach( pallet => {
            this.unitService.DeleteUnit(pallet.$key);
          } );
          
          this.selectedUnits = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Content types deleted', life: 3000});
      }
  });
  }

  editUnit(unit: Unit) {
    this.unit = {...unit};
    this.unitDialog = true;
  }

  deleteUnit(unit: Unit) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + unit.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.units = this.units.filter(val => val.$key !== unit.$key);
            this.unit = {} as Unit;
            this.unitService.DeleteUnit(unit.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Content Type Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.unitDialog = false;
    this.submitted = false;
  }

  saveUnit() {
    this.submitted = true;
    if (this.unit.$key) {
      this.units[this.findIndexById(this.unit.$key)] = this.unit;                
      this.unitService.UpdateUnit(this.unit);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Content Type Updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';
      this.unitService.AddUnit(this.unit);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Content Type Created', life: 3000});
      this.units = [...this.units];
    }
    this.unitDialog = false;
    this.unit = { } as Unit;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.units.length; i++) {
        if (this.units[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeUnit(unit : Unit) {
    const unitTmp = {
      ...unit
    };
    console.log("SAVE", unitTmp);
    this.unitService.UpdateUnit(  unitTmp );
  }

  onRowReorder(event : {dragIndex: number, dropIndex: number}) {
    console.log("dragIndex", event.dragIndex);
    console.log("dropIndex", event.dropIndex);
    // if ( !(event.dragIndex && event.dropIndex)) {
    //   return;
    // }
    if ( event.dragIndex == event.dropIndex) {
      return;
    }
    console.log("before",this.units);
    const row = this.units[event.dragIndex];
    //this.units.splice(event.dragIndex,1);
    console.log("after",this.units);
    //this.units.splice(event.dropIndex,0,row);
    //this.unitService.UpdateUnits(this.units);
  }

}
