import { Injectable } from '@angular/core';
import { Level } from '../interfaces/level';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class LevelCrudService {

  levelsRef: AngularFireList<any> | undefined;
  levelRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create Level
  AddLevel(level: Level) {
    const item = {
      ...level
    }
    this.levelsRef?.push( item );
  }
  // Fetch Single Level Object
  GetLevel(id: string) {
    this.levelRef = this.db.object('levels-list/' + id);
    return this.levelRef;
  }
  // Fetch Levels List
  GetLevelsList() {
    this.levelsRef = this.db.list('levels-list');
    return this.levelsRef;
  }
  // Update Level Object
  UpdateLevel(level: Level) {
    this.levelRef = this.db.object('levels-list/' + level.$key);
   // console.log("P inside", level);
    let item : Partial<Level>  = {
      ...level
    }
    delete item?.$key;
    this.levelRef?.update(item);
  }
  // Delete Level Object
  DeleteLevel(id: string) {
    this.levelRef = this.db.object('levels-list/' + id);
    this.levelRef.remove();
  }
}
