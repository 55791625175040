import { Component, OnInit } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-transforms',
  templateUrl: './transforms.component.html',
  styleUrls: ['./transforms.component.scss']
})
export class TransformsComponent implements OnInit {

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  constructor() {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Transforms'}
    ];
   }
  ngOnInit(): void {
  }

}
