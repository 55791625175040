import { Injectable } from '@angular/core';
import { GrapeVariety } from '../interfaces/grape-variety';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GrapeVarietyCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/grapeVarieties/' ;
  }

  // Create GrapeVariety
  add(record: GrapeVariety) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single GrapeVariety Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch GrapeVarietys List
  getList() {
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update GrapeVariety Object
  update(record: GrapeVariety) {
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<GrapeVariety>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete GrapeVariety Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
