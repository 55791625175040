import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TransformService } from 'src/app/shared/services/transform.service';

@Component({
  selector: 'app-transform-to-products',
  templateUrl: './transform-to-products.component.html',
  styleUrls: ['./transform-to-products.component.scss']
})
export class TransformToProductsComponent implements OnInit {

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  items: MenuItem[] = [];
    
  subscription: Subscription = {} as Subscription;

  activeIndex: number = 1;

  constructor(public transformService : TransformService, public messageService: MessageService) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Transform'},
      {label: 'To products'}
    ];

  }

  ngOnInit() {
    this.items = [{
            label: 'Selection',
            routerLink: '/transform/selection'
        },
        {
            label: 'Transformation',
            routerLink: '/transform/transformation'
        },
        {
            label: 'Confirmation',
            routerLink: '/transform/confirmation'
        }
    ];

    

    this.subscription = this.transformService.paymentComplete$.subscribe((personalInformation) =>{
        this.messageService.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + personalInformation.firstname + ' ' + personalInformation.lastname + ' your order completed.'});
    });
  }

  chan() {
    this.activeIndex += 1;
  }

  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

}
