<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        
    </div>


<h1>New Receipt</h1>


<form [formGroup]="formGroup" >


    <div class="two-columns">

        <div class="col1">
            <div class="form-group">
                <label for="datetime">Date and time</label>
                <p-calendar [(ngModel)]="date7"  [ngModelOptions]="{standalone: true}" [showIcon]="true" [showTime]="true" [showSeconds]="true" inputId="time" (onSelect)="onSelect($event)" (onSelect)="onBlur($event)" (onClose)="onClose($event)"></p-calendar>
                <!-- <input pInputText formControlName="datetime" type="text" class="form-control" id="datetime" aria-describedby="datetimeHelp" placeholder="Date and time" value=""> -->
                <!-- <small id="datetimeHelp" class="form-text text-muted">Current date and time of the reception.</small> -->
              </div>
          
              <div class="p-inputgroup form-group">
                  <label for="receiver">Receiver</label>
                  <input style="width: 262px" class="inputgroup" readonly pInputText formControlName="receiver" type="text" class="form-control" id="receiver" aria-describedby="receiverHelp" placeholder="Receiver" value="">
                 <button pButton pRipple  icon="pi pi-user" class="p-button-success" (click)="updateReceiver('123');"></button>
                  <!-- <small id="receiverHelp" class="form-text text-muted">Current receiver of the reception.</small> -->
              </div>
          
              <div class="form-group">
                  <label for="email">Provider</label>
                  <p-dropdown 
                      [style]="{'minWidth':'300px', 'width': '300px'}"
                      formControlName="provider"
                      appendTo="body" 
                      [filter]="true"
                      [showClear]="true" 
                      [options]="providers" 
                      optionLabel="name" 
                      optionValue="$key"
                      placeholder="Any">
                  </p-dropdown>   
              </div>
          
              <div class="form-group">
                  <label for="email">Delivery person</label>
                  <p-dropdown 
                      [style]="{'minWidth':'300px', 'width': '300px'}"
                      formControlName="deliveryMan"
                      appendTo="body" 
                      [filter]="true"
                      [showClear]="true" 
                      [options]="deliveryMen" 
                      optionLabel="name" 
                      optionValue="$key"
                      placeholder="Any">
                  </p-dropdown>  
              </div>
          
             
              <div class="form-group">
                  <label for="receipt">Receipt</label>
                  <input style="min-width: 300px" pInputText formControlName="receipt" type="text" class="form-control" id="receipt" aria-describedby="receiptHelp" placeholder="Receipt number">
                  <!-- <small id="receiptHelp" class="form-text text-muted">The receipt number if there is one.</small> -->
              </div>
          
              <!-- <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <input type="text" pInputText placeholder="Username">         
              </div> -->
          
              <div class="p-inputgroup form-group">
                  <label for="woreceipt">Internal code</label>
                  <input pInputText formControlName="woreceipt" type="text" class="form-control" id="woreceipt" aria-describedby="woreceipttHelp" placeholder="An internal code to retrieve it later" value="{{ getWoCode() }}">
                  
                  <button pButton pRipple icon="pi pi-refresh" class="p-button-success" (click)="updateWoReceipt('123');"></button>
          
                  <!-- <small id="woreceiptHelp" class="form-text text-muted">An internal Woodlane Orchad code for retrieving items</small> -->
              
              </div>
          
              <!-- <div class="p-inputgroup form-group" style="display: flex;margin-left: 250px;">
                  <qrcode [qrdata]="formGroup.get('woreceipt')?.value" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
              </div>      -->
          
              <div class="form-group" >
                  <label for="notes">Notes</label>
                  <input style="min-width: 300px" pInputText formControlName="notes" type="text" class="form-control" id="notes" aria-describedby="notesHelp" placeholder="e.g: No issue">
                  <!-- <small id="notesHelp" class="form-text text-muted">Add some notes</small> -->
              </div>
        </div>

        <div style="border-left: 2px solid #004170; height: 270px; margin-top: 25px;margin-right: 35px"></div>

        <div class="col2">
            <div class="form-group" style="width: 500px">
                <label style="line-height: 50px; height: 50px;vertical-align: middle;" for="items">Items</label>
            
                <button pButton style="text-align: right; float: right;" pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
                
                <p-table #dt 
                    [ngStyle]="{'width': 500}"
                    [value]="items"                                                                                                      
                    [rows]="100" 
                    [paginator]="true" 
                    [globalFilterFields]="['name','description','status']"
                    [rowsPerPageOptions]="[5,10,25,50,100]"
                    [(selection)]="selectedItems" 
                    [resizableColumns]="true"
                    [reorderableColumns]="true"
                    [rowHover]="true" 
                    dataKey="$key"
                    styleClass="p-datatable-striped"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" 
                    [showCurrentPageReport]="true"
                    selectionMode="multiple"
                    [columns]="selectedColumns"
            >
            
            <!-- <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template> -->
            
            <!-- <ng-template pTemplate="caption">
                <p-multiSelect 
                    [options]="cols" 
                    [(ngModel)]="selectedColumns" 
                    optionLabel="header"
                    selectedItemsLabel="{0} columns selected" 
                    [style]="{minWidth: '200px'}" 
                    placeholder="Choose Columns">
                </p-multiSelect>
            </ng-template> -->
            
            
            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <!-- <th pResizableColumn pSortableColumn="position" style="width: 7rem">
                        Rank<p-sortIcon field="position"></p-sortIcon>
                    </th> -->
                    <!-- <th id="quantity" name="qunatity" pResizableColumn pReorderableColumn pSortableColumn="quantity" [class]="includesCol('quantity') ? 'd' : 'h'">Nb 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th> -->
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description</th>
                   <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <!-- <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox> -->
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <!-- <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="item.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{item.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                    </td> -->
            
                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="item.name" required>
                            </ng-template>
                            <ng-template pTemplate="output" > -->
                                <span >{{item.name}}</span>
                            <!-- </ng-template>
                        </p-cellEditor> -->
                    </td>
            
                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="item.description"  (blur)="completeProvider(item)" (keydown.tab)="completeProvider(item)" (keydown.enter)="completeProvider(item)"  required>
                            </ng-template> -->
                            <!-- <ng-template pTemplate="output"> -->
                                <span >{{item.description}}</span>
                            <!-- </ng-template> -->
                        <!-- </p-cellEditor> -->
                    </td>
            
                    <td class="row-actions">
                        <!-- <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProvider(item)"></button> -->
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProvider(item)"></button>
                           <!-- <p-tableCheckbox class="checkbox" [value]="provider"></p-tableCheckbox> -->
                        
                    </td>
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{items ? items.length : 0 }} items.
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 0px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template> -->
            </p-table>
            </div>
        </div>

    </div>


    

   





<!-- <button pButton pRipple label="Submit" [disabled]="!formGroup.valid" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="submitTest()"></button> -->

<div class="submit-line">
    <button pButton type="submit"  label="Submit" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="test();onSubmit()"></button>

    <!-- <button pButton type="submit"  label="Submit" [disabled]="!formGroup.valid" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="test();onSubmit()"></button> -->
</div>

<!-- 
<button pButton pRipple label="Date time"  icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="updateDatetime('123');"></button>

<button pButton pRipple label="Receiver"  icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="updateReceiver('123');"></button> -->

<!-- <button pButton pRipple label="WO code"  icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="updateWoReceipt('123');"></button>
 -->

<!-- <button type="submit" class="btn btn-primary" >Submit</button> -->
</form>




    <p-dialog [(visible)]="itemDialog" [style]="{width: '450px', height: '380px'}" header="New Item" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        


            <div class="p-field">
                <label class="label" for="productTypes" style=" display: inline-block;;margin-left: 0px;margin-top: 0px;margin-bottom: 10px;">Product types</label>
                <p-dropdown 
                  
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="true" 
                    [(ngModel)]="dialogSelectProductTypeKey" 
                    [options]="productTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    (onChange)="onChangeProductType(dialogSelectProductTypeKey)"
                    placeholder="Any">
                </p-dropdown>
            </div>


       
        
        <!-- <div class="p-field">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="item.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !item.name">Provider name is required.</small>
        </div> -->

            <div class="p-field">
                <label class="label" for="description">Notes (quantity and remarks)</label>
                <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="item.description" rows="3" cols="20"></textarea>
            </div>
   
        
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success p-mr-2" (click)="addItem()"></button>
        </div>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


</div>
