import { Injectable } from '@angular/core';
import { Client } from '../interfaces/client';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientCrudService {

  clientsRef: AngularFireList<any> | undefined;
  clientRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/clients/' ;
  }

  // Create Client
  addClient(client: Client) {
    this.clientsRef = this.db.list( this.path );
    const item = {
      ...client
    }
    this.clientsRef?.push( item );
  }
  // Fetch Single Client Object
  getClient(id: string) {
    this.clientRef = this.db.object(this.path + id);
    return this.clientRef;
  }
  // Fetch Clients List
  getClientsList() {
    this.clientsRef = this.db.list(this.path);
    return this.clientsRef;
  }
  // Update Client Object
  updateClient(client: Client) {
    this.clientRef = this.db.object( this.path + client.$key);
    let item : Partial<Client>  = {
      ...client
    }
    delete item?.$key;
    this.clientRef?.update(item);
  }
  // Delete Client Object
  deleteClient(id: string) {
    this.clientRef = this.db.object(this.path + id);
    this.clientRef.remove();
  }
}
