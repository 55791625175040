<p>transform-selection works!</p>
<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>

</div>

<div class="stepsdemo-content">
    tets
    <!-- <p-card> -->
        <ng-template pTemplate="title">
            Personal Information
        </ng-template>
        <ng-template pTemplate="subtitle">
            Enter your personal information
        </ng-template>

        <button (click)="nextPage()" p-button>Next</button>
    <!-- </p-card> -->
</div>
