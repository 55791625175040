// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'test',
  firebaseConfig : {
    // apiKey: "AIzaSyDBkM12nf987TcfeYbMDzxeDQzKlQHEWJw",
    // authDomain: "ibisa-75eab.firebaseapp.com",
    // databaseURL: "https://ibisa-75eab-default-rtdb.firebaseio.com",
    // projectId: "ibisa-75eab",
    // storageBucket: "ibisa-75eab.appspot.com",
    // messagingSenderId: "374440258825",
    // appId: "1:374440258825:web:39f9eb359ef9dd13b937d8",
    // measurementId: "G-KWECPHX19P"

    // apiKey: "AIzaSyD4YHqej3PeW9gmJBIzZLXTMxEN-rW2xDw",
    // authDomain: "winebottlingsolutions.firebaseapp.com",
    // databaseURL: "https://winebottlingsolutions-default-rtdb.firebaseio.com",
    // projectId: "winebottlingsolutions",
    // storageBucket: "winebottlingsolutions.appspot.com",
    // messagingSenderId: "227924042197",
    // appId: "1:227924042197:web:6ce7c0cd37afaaba751d5c",
    // measurementId: "G-CPWJDRMLR8"

    // Sorby Adams Wine
    // apiKey: "AIzaSyDRtzhO97gcyLzUEIm9pHDDyuSr1kNh-og",
    // authDomain: "sorby-adams-wines.firebaseapp.com",
    // projectId: "sorby-adams-wines",
    // storageBucket: "sorby-adams-wines.appspot.com",
    // messagingSenderId: "416367543090",
    // appId: "1:416367543090:web:6629b5df4c2960970ecd2b",
    // measurementId: "G-FY8BWSPH97"

    // Taronga Almonds
    apiKey: "AIzaSyA09Mf0PNKyZ-IOc2RVG7KX2ZvE1i-UhGw",
    authDomain: "taronga-almonds.firebaseapp.com",
    databaseURL: "https://taronga-almonds-default-rtdb.firebaseio.com",
    projectId: "taronga-almonds",
    storageBucket: "taronga-almonds.appspot.com",
    messagingSenderId: "452887588022",
    appId: "1:452887588022:web:8e0dc77d3ed54479b6ca17",
    measurementId: "G-TQ2LG83K3H"


    // // Woodlane Orchad
    // apiKey: "AIzaSyAHDoOy13yuaSe3zAekzZqToNLZIcjHw_Y",
    // authDomain: "woodlane-orchad.firebaseapp.com",
    // databaseURL: "https://woodlane-orchad-default-rtdb.firebaseio.com",
    // projectId: "woodlane-orchad",
    // storageBucket: "woodlane-orchad.appspot.com",
    // messagingSenderId: "22889169115",
    // appId: "1:22889169115:web:daa567d6f2af8232054cfe",
    // measurementId: "G-WN18MGTJXW"

    // Ziptrak
    // apiKey: "AIzaSyDDYYz7Q7kv8vmog8ki4EPb6tUvdkNYrdQ",
    // authDomain: "ziptrak-19cc5.firebaseapp.com",
    // databaseURL: "https://ziptrak-19cc5-default-rtdb.firebaseio.com",
    // projectId: "ziptrak-19cc5",
    // storageBucket: "ziptrak-19cc5.appspot.com",
    // messagingSenderId: "474321451827",
    // appId: "1:474321451827:web:a80eaf89c7ec56bae61c46",
    // measurementId: "G-BJ8GY810RW"

  },
  //applicationLogo: 'assets/images/logo_ziptrak_white_trans.png',
  //applicationLogo: 'assets/logo_woodlane_trans.png',
  //applicationLogo: 'assets/logo_sorbyadamswines.png',
  applicationLogo: 'assets/logo_ta.jpeg',

  companyLogo: 'assets/images/logo.png',
  facebook: 'https://www.facebook.com/ebottli',
  linkedin: 'https://www.linkedin.com/company/e-bottli/',
  copyright: 'Copyright eBottli Pty Ltd 2023',
  
  // // Sorby Adams
  // modules : {
  //   barrels: true,
  //   pallets: true,
  //   wines: true,

  //   warehouse: false,

  //   purchase: false,
  //   receive: false,
  //   dry: false,
  //   move: true,
  //   transform: false,
  //   output: true,
  //   update: false,
  //   event: false
  // }

  // Taronga
   modules : {
    barrels: false,
    pallets: false,
    wines: false,
    warehouse: true,
    purchase: false,
    receive: true,
    dry: false,
    move: true,
    transform: true,
    output: true,
    update: false,
    event: false
  }

  // // WO
  //  modules : {
  //   barrels: false,
  //   pallets: false,
  //   wines: false,
  //   warehouse: true,
  //   purchase: false,
  //   receive: true,
  //   dry: true,
  //   move: true,
  //   transform: true,
  //   output: true,
  //   update: false,
  //   event: false
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
