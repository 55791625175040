
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        
        <div class="search" style="margin-top: 0px">
            <span style="margin-right: 10px">Recipe</span>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="recipeKey" 
                [options]="recipes" 
                optionLabel="name" 
                optionValue="$key"
                (change)="selectRecipe(dt)"
                (blur)="selectRecipe(dt)"
                (keydown.tab)="selectRecipe(dt)"
                (keydown.enter)="selectRecipe(dt)"
                placeholder="Any">
            </p-dropdown>
            
        </div>
        <div class="actions">
            <button  pButton pRipple label="Transform" icon="pi pi-reply" class="button-action p-button-success" (click)="transform()"></button>
            
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="products" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['label','container.name','content.name','isEnabled']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedProducts" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect 
                    [options]="cols" 
                    [(ngModel)]="selectedColumns" 
                    optionLabel="header"
                    selectedItemsLabel="{0} columns selected" 
                    [style]="{minWidth: '200px'}" 
                    placeholder="Choose Columns">
                </p-multiSelect>
            </ng-template>




            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                   
                    <th pResizableColumn pSortableColumn="position" style="width: 2rem">
                        <p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="id" name="id" pResizableColumn pReorderableColumn pSortableColumn="id" [class]="includesCol('id') ? 'd' : 'h'">QR Code
                        <p-sortIcon field="id"></p-sortIcon> 
                        <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
                    </th>

                    <!-- <th id="idType" name="idType" pResizableColumn pReorderableColumn pSortableColumn="idType" [class]="includesCol('idType') ? 'd' : 'h'">ID type
                        <p-sortIcon field="idType"></p-sortIcon> 
                        <p-columnFilter type="text" field="idType" display="menu"></p-columnFilter>
                    </th> -->

                    <th id="key" name="key" pResizableColumn pReorderableColumn pSortableColumn="key" [class]="includesCol('key') ? 'd' : 'h'">Key
                        <p-sortIcon field="key"></p-sortIcon> 
                        <p-columnFilter type="text" field="key" display="menu"></p-columnFilter>
                    </th>

                    <th id="label" name="label" pResizableColumn pReorderableColumn pSortableColumn="label" [class]="includesCol('label') ? 'd' : 'h'">Label
                        <p-sortIcon field="label"></p-sortIcon> 
                        <p-columnFilter type="text" field="label" display="menu"></p-columnFilter>
                    </th>

                    <!-- <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th> -->
                    <!-- <th id="minCapacity" name="minCapacity" pResizableColumn pReorderableColumn pSortableColumn="minCapacity" [class]="includesCol('minCapacity') ? 'd' : 'h'">Min capacity 
                        <p-sortIcon field="minCapacity"></p-sortIcon> 
                        <p-columnFilter field="minCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="minActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{minActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{minActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </th> -->
                    <!-- <th id="maxCapacity" name="maxCapacity" pResizableColumn pReorderableColumn pSortableColumn="maxCapacity" [class]="includesCol('maxCapacity') ? 'd' : 'h'">Max capacity 
                        <p-sortIcon field="maxCapacity"></p-sortIcon> 
                        <p-columnFilter field="maxCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="maxActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{maxActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{maxActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </th> -->
                    <th id="location" name="location" pResizableColumn pReorderableColumn pSortableColumn="location" [class]="includesCol('location') ? 'd' : 'h'">Location
                        <p-sortIcon field="location"></p-sortIcon> 
                        <p-columnFilter type="text" field="location" display="menu"></p-columnFilter>
                    </th>

                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn pSortableColumn="quantity" [class]="includesCol('quantity') ? 'd' : 'h'">Product quantity
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter type="text" field="quantity" display="menu"></p-columnFilter>
                        
                        <img alt="Create content" src="/assets/images/content.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/content-types']"/>
                            <!-- <button mat-flat-button >+</button> -->
                    </th>

                    <th id="unit" name="unit" pResizableColumn pReorderableColumn pSortableColumn="unit" [class]="includesCol('unit') ? 'd' : 'h'">Unit
                        <p-sortIcon field="unit"></p-sortIcon> 
                        <p-columnFilter type="text" field="unit" display="menu"></p-columnFilter>
                    </th>

<!-- 
                 //   $key?: string; -->

    <!-- containerName?: string;
    contentTypeIsInputable?: boolean;
    contentTypeIsOutputable?: boolean;
    contentTypeName?: string;


    packagingTypeMaxCapacity?: number;
    packagingTypeMinCapacity?: number;
    packagingTypeName?: string;
    packagingTypeUnit?: number;


    receiptDeliveryMan?: string;
    receiptIdentifier?: string;
    receiptInstant?: string;
    receiptProvider?: string;
    receiptReceiver?: string;

    // sku?: string; // Stock Keeping Unit
    // upc?: string; // Universal Product Codes
    // name?: string;
    // productTypeName?: string;
    // productType?: ProductType;
    // products?: Product[];
    isEnabled?: boolean; -->

                    <th id="container" name="container" pResizableColumn pReorderableColumn pSortableColumn="container" [class]="includesCol('container') ? 'd' : 'h'">Packaging type
                        <p-sortIcon field="container.name"></p-sortIcon> 
                        <p-columnFilter type="text" field="container.name" display="menu"></p-columnFilter>
                        
                        <img alt="Create content" src="/assets/images/container.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/packaging-types']"/>
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <!-- <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex" let-expanded="expanded">
                <tr [pReorderableRow]="index" >
                   
                    <td pEditableColumn>
                       
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{product.position}} {{index}}
                            </ng-template>
                        </p-cellEditor> -->
                        <!-- <p-tableCheckbox [value]="product"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('id') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.id" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <!-- <span >{{product.id}}</span> -->
                                <qrcode [qrdata]="product.key" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <!-- <td pEditableColumn [class]="includesCol('idType') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.idType" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.idType}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('key') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.key" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.key}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('label') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.label" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.label}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- 
                    <td pEditableColumn [class]="includesCol('productTypeName') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.name" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.productTypeName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <!-- <td pEditableColumn [class]="includesCol('minCapacity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.minCapacity" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.minCapacity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('maxCapacity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="product.maxCapacity" (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.maxCapacity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="product.location" 
                                    [options]="locations" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.location}}</span>
                                <!-- <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-reply"  class="p-button-rounded p-button-success p-mr-2" pTooltip="move" tooltipPosition="bottom"></button> -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectContentKey" 
                                    [options]="contentTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('unit') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectContentKey" 
                                    [options]="contentTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.unit}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('container') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="dialogSelectPackagingKey" 
                                    [options]="packagingTypes" 
                                    optionLabel="name" 
                                    optionValue="$key"
                                    (blur)="completeProduct(product)"
                                    (keydown.tab)="completeProduct(product)"
                                    (keydown.enter)="completeProduct(product)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{product.container?.name }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <!-- <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="product.isEnabled" (onChange)="completeProduct(product)"></p-inputSwitch> -->
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="product.isEnabled"  (blur)="completeProduct(product)" (keydown.tab)="completeProduct(product)" (keydown.enter)="completeProduct(product)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{product.isEnabled}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                    <!-- </td> -->


                    <!-- <td>{{product.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(product)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(product)"></button>
                        <p-tableCheckbox class="checkbox" [value]="product"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>

            <ng-template 
            pTemplate="rowexpansion"
            let-product >
            <tr>
                <td colspan="5">
                    <div class="expansion">
                        <div class="expansion-line">
                            line
                        </div>
                    </div>
                </td>
            </tr>
            </ng-template>
            
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{products ? products.length : 0 }} products.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="productDialog" [style]="{width: '450px', height: '450px'}" header="Packaging Type Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Name</label>
                <input type="text" pInputText id="name" [(ngModel)]="product.label" required autofocus />
                <small class="p-error" *ngIf="submitted && !product.label">Name is required.</small>
            </div>

            <div >
                <label for="content" style="margin-left: 20px;margin-right: 10px;">Content</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectContentKey" 
                    [options]="contentTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div>

            <div >
                <label for="container" style="margin-left: 20px;margin-right: 10px;">Packaging</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectPackagingKey" 
                    [options]="packagingTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>



    <p-dialog [(visible)]="transformDialog" [style]="{width: '450px', height: '450px'}" header="Transformation" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
         <!-- <div class="p-field">
                <label for="name">Name</label>
                <input type="text" pInputText id="name" [(ngModel)]="product.label" required autofocus />
                <small class="p-error" *ngIf="submitted && !product.label">Name is required.</small>
            </div>

            <div >
                <label for="content" style="margin-left: 20px;margin-right: 10px;">Content</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectContentKey" 
                    [options]="contentTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div>

            <div >
                <label for="container" style="margin-left: 20px;margin-right: 10px;">Packaging</label>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="dialogSelectPackagingKey" 
                    [options]="packagingTypes" 
                    optionLabel="name" 
                    optionValue="$key"
                    placeholder="Any">
                </p-dropdown>
            </div> -->
                   

            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
     
    </p-dialog>
    


</div>






