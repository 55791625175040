import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { Location } from 'src/app/shared/interfaces/location';
import { MoveCrudService } from 'src/app/shared/services/move-crud.service';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { Move } from 'src/app/shared/interfaces/move';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-moves',
  templateUrl: './moves.component.html',
  styleUrls: ['./moves.component.scss']
})
export class MovesComponent implements OnInit {

  @ViewChild('dropdown') dropdown: Dropdown;

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  list1: any[];
  originalList1: any[];

  list2: any[];

  locations: Location[] = [];
  location: Location = {} as Location;
  locationKey : string = '';
  previousLocationKey : string = '';

  locationMap = new Map<string, Location>();

  list2Map = new Map<string, boolean>();

  constructor(
    public authService: AuthService,
    private productService: WoProductCrudService,
    private moveService: MoveCrudService,
    private eventService: WoEventCrudService,
    private confirmationService: ConfirmationService,
    private locationService: LocationCrudService,
    private router: Router
   //private changeDetectorRef: ChangeDetectorRef
    ) {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Move'}
    ];

    this.list1 = [];
  //   this.list1 = [ 
  //    {'name':'Orange', 'batch': '8634567' , 'location': 'reception area' } ,
  //    {'name':'Orange', 'batch': '2348731' , 'location': 'reception area' } ,
  //    {'name':'Orange', 'batch': '2348732' , 'location': 'drying area' } ,
  //    {'name':'Pear'  , 'batch': '5448761' , 'location': 'drying area' } ,
  //  ];
    this.originalList1 = Object.assign([], this.list1);
    this.list2 = [];

    this.locations = [];
    this.location = {} as Location;
    this.dropdown = {} as Dropdown;
   }

  ngOnInit(): void {

    let s = this.locationService.getLocationsList(); 
    s.snapshotChanges().subscribe(data => {
      this.locations = [];
      this.locationMap = new Map<string, Location>();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          const loc = jsonItem as Location;
          this.locationMap.set( loc.$key , loc );
          this.locations.push(loc);
        }
      })
    });

    let p = this.productService.getWoProductsList(); 
     p.snapshotChanges().subscribe(data => {
       this.list1 = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }

           const product = jsonItem as WoProduct;
          // if( product.dryable) {
            this.list1.push(jsonItem as WoProduct);
          // }
          
           
         }
       })
       this.list1 = Object.assign([],this.list1);
       this.originalList1 = Object.assign([], this.list1);
     });

  }

  // EXPLICATIONS 
  // https://cdiese.fr/angular-customize-change-detection/

  moveToTarget(event: any) {
   //(onMoveToTarget)="moveToTarget($event)"
   console.log('Moved All Items: ', event.items);

   this.confirm({} as Event, event.items);
   this.list2 = [...new Set(this.list2)];
  }

  confirm(event: Event, items : any[]) {
   this.confirmationService.confirm({
       target: event.target || {} as EventTarget,
       message: 'Are you moving the entire product?',
       icon: 'pi pi-exclamation-triangle',
       accept: () => {
           //confirm action
           items.forEach(
            item => {
              this.list2Map.set( item.name + item.batch, true);
            }
          );

       },
       reject: () => {
           //reject action
           if (items) {
             items.forEach( item => {

              this.list2Map.set( item.name + item.batch, false);

               const found = this.list1.find((litem) => {
                 return litem.name === item.name && litem.batch === item.batch;
               });
               
               console.log(found); // 👉️ {id: 1, name: 'Alfred'}
               
               if (found !== undefined) {
                 // 👇️ this runs
                 console.log('✅ the object is contained in the array');
               } else {
                 console.log('⛔️ the object is NOT contained in the array');
                 this.list1.push(item);
                 //  this.changeDetectorRef.markForCheck();
                 this.list1 = Object.assign([], this.list1);
                 //console.log(this.list1);
               }


             }); 
           }
       }
   });
   console.log(this.list1);
   
   //this.changeDetectorRef.detectChanges();
 }

 

 sourceReorder(event: any) {
   console.log('Reorder source: ', event.items);
 }

 moveToSource(event: any)  {
   console.log('Moved items to source: ', event.items);



   

   const items = event.items;
   items.forEach( (item : any) => {
     const found = this.list1.find((litem) => {
       return litem.name === item.name && litem.batch === item.batch;
     });
     //console.log(found); 
     if (found !== undefined) {
       console.log('✅ the object is contained in the array');
     } else {
       console.log('⛔️ the object is NOT contained in the array');
       this.list1.push(item);
       //  this.changeDetectorRef.markForCheck();
       
       //console.log(this.list1);
     }
   }); 
  // this.list1 = Object.assign([], this.list1);

  this.list1 = [...new Set(this.list1)];


  

  items.forEach( (item : any) => {
   this.list2 = this.list2.filter(litem => {
     return litem.name !== item.name || litem.batch !== item.batch;
   });
   }); 

  this.list2 = [...new Set(this.list2)];

   console.log(this.list1);
   //this.changeDetectorRef.detectChanges();

  }
  submit() {
    console.log('Date time', this.location);
    // console.log('Receiver', this.authService.userData.email);
    // console.log('Provider', this.providerKey);
    // console.log('Delivery man', this.deliveryManKey);
    // console.log('Label', this.label);
    // console.log('Notes', this.notes);
    // this.items.forEach( item => {
    //   console.log('Item quantity ', item.quantity );
    //   console.log('Item name ', item.name );
    // });
  }
 
  disabled() : boolean {
    return this.list2.length < 1 
    this.locationKey === '' || this.locationKey === undefined;  
    // return this.list2.length < 1 
    //      || !this.location 
    //      || (Object.keys(this.location).length === 0 
    //          && this.location.constructor === Object) 
  }

  disabledPickList() : boolean {
    console.log('Loc Key', this.locationKey);
    return this.locationKey === '' || this.locationKey === undefined ; 
    // return !this.location 
    //      || (Object.keys(this.location).length === 0 
    //          && this.location.constructor === Object) 
  }

  filterList1(event : any) {
    console.log(event);
    const locationKey = event.value;

    if ( !locationKey) {
      // this.list1 = [...new Set(this.list1)];
      this.list1 = [...new Set(this.originalList1)];
      this.dropdown.disabled;
      return;
    }

    if (this.previousLocationKey === locationKey ) {
      return;
    } else {
      this.list1 = [...new Set(this.originalList1)];
    }

    if (this.locationMap.has(locationKey) ) {
      const locationName = this.locationMap.get(locationKey)?.name;
      this.list1 = this.list1.filter(item => {
        console.log('loc name', item.location);
        console.log('loca name', locationName);
        return item.location !== locationName;
      });
      this.list1 = [...new Set(this.list1)];
    }
  }

  // filterValue: string = '';

  // updateFilter(value: string) {
  //   this.filterValue = value;
  // }

  // filterItems(event: any) {
  //   let query = event.query;
  //   // code to filter the items
  //   console.log(query);
  // }

  onSubmit(){
      
    if(this.list2.length > 0){
      
      const move : Move = {} as Move;
      move.when = new Date().toISOString();
      move.who = this.authService.userData.email || ' ';
      move.fromWhat = [];
      move.toWhat = [];

      this.list2.forEach( (product : WoProduct)  => { 

        // Previous product is product
        const previousLocation : string = product.location || ' ';

        // New product
        const newProduct : Partial<WoProduct> = Object.assign({}, product);
        console.log('Map', this.locationMap);
        console.log('Key', this.locationKey);
       // console.log('Location name', this.location.name);
        newProduct.location = this.locationMap.get(this.locationKey)?.name || 'not found' as string;
       //newProduct.location = this.location.name || 'not found' as string;


        const eventProduct : Partial<WoProduct> = Object.assign({}, product);
        delete eventProduct.$key;

       
        // Event
        const woEvent : WoEvent = {
          which: 'MOVE',
          // fromWhere: previousLocation,
          // toWhere: newProduct.location, 
          what: eventProduct,
          who: 'receiver',
          why: 'HUMAN-CLICK',
          where: 'WEBSERVER'
        } as WoEvent;
        // 3.B) for the event
        //this.eventService.addWoEvent( woEvent );

        // Update New Product
        if(!newProduct.history) {
          newProduct.history = [];
        }
        let histories = Object.assign([],newProduct.history);
        histories.push(woEvent); 
        console.log('histories',histories);
        newProduct.history = histories;
        //newProduct.dryable = false;

       
        // update product type
        
        
        // Update evenet

        if (newProduct.$key) {
          delete newProduct.$key;
        }
        this.productService.addWoProduct(newProduct as WoProduct);
        
        
        
        move.fromWhat.push(eventProduct as WoProduct);
        move.toWhat.push(newProduct as WoProduct);

        const k =  product.name+product.batch;
        if (this.list2Map.has(k) ) {
          const hasBeenConsumedEntirely = this.list2Map.get(k);

          if (hasBeenConsumedEntirely) {
            console.log('entirely', product.$key);
            this.productService.deleteWoProduct(product.$key);
          }

        }
       
      });

     this.moveService.addMovement(move);
     this.router.navigate(['movement/movement-table']);
     this.dropdown.resetFilter();
    }

  }
                

}
