import { Injectable } from '@angular/core';
import { ContainerType } from '../interfaces/container-type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class ContainerTypeCrudService {

  ContainerTypesRef: AngularFireList<any> | undefined;
  ContainerTypeRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create ContainerType
  AddContainerType(containerType: ContainerType) {
    this.ContainerTypesRef?.push({
      name: containerType.name
      // batch: ContainerType.batch,
      // batchId: ContainerType.batchId,
      // comment: ContainerType.comment,

      // quantity: ContainerType.quantity,

      // location: ContainerType.location,
      // row: ContainerType.row,
      // column: ContainerType.column,
      // level: ContainerType.level,
   
      // isInSite: ContainerType.isInSite,
      // isEmpty: ContainerType.isEmpty,
    });
  }
  // Fetch Single ContainerType Object
  GetContainerType(id: string) {
    this.ContainerTypeRef = this.db.object('products/containerTypes/' + id);
    return this.ContainerTypeRef;
  }
  // Fetch ContainerTypes List
  GetContainerTypesList() {
    this.ContainerTypesRef = this.db.list('products/containerTypes/');
    return this.ContainerTypesRef;
  }
  // Update ContainerType Object
  UpdateContainerType(containerType: ContainerType) {
    this.ContainerTypeRef?.update({
      name: containerType.name
      // batch: ContainerType.batch,
      // batchId: ContainerType.batchId,
      // comment: ContainerType.comment,

      // quantity: ContainerType.quantity,

      // location: ContainerType.location,
      // row: ContainerType.row,
      // column: ContainerType.column,
      // level: ContainerType.level,
   
      // isInSite: ContainerType.isInSite,
      // isEmpty: ContainerType.isEmpty,
    });
  }
  // Delete ContainerType Object
  DeleteContainerType(id: string) {
    this.ContainerTypeRef = this.db.object('products/containerTypes/' + id);
    this.ContainerTypeRef.remove();
  }
}
