import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';

import { ConfirmationService, FilterMatchMode, FilterService, MenuItem, SelectItem, SortEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { WoReceptionCrudService } from 'src/app/shared/services/wo-reception-crud.service';
import { WoReceipt } from 'src/app/shared/interfaces/wo-receipt';
import { Unit } from 'src/app/shared/interfaces/unit';
import { UnitCrudService } from 'src/app/shared/services/unit-crud.service';
import { ProductType } from 'src/app/shared/interfaces/product-type';
import { Item } from 'src/app/shared/interfaces/item';
import { Router } from '@angular/router';


@Component({
  selector: 'app-wo-receptions',
  templateUrl: './wo-receptions.component.html',
  styleUrls: ['./wo-receptions.component.scss']
})
export class WoReceptionsComponent implements OnInit {

  @ViewChild('dt', { static: false }) table: Table = {} as Table;


  receptions: WoReceipt[];
  //filteredReceptions: WoReceipt[];
  matchModeOptions: SelectItem[];
  reception: WoReceipt;
  selectedReceptions: WoReceipt[] = [];

  receptionDialog: boolean = false;

  submitted: boolean = false;

  units: Unit[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  genreList: SelectItem[] = [];

  constructor(
   // private api: APIService,
    private filterService: FilterService,
    private receptionService : WoReceptionCrudService,
    private unitService : UnitCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService,
    private router : Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Tables'},
      {label: 'Reception'}
    ];

    this.receptions = [];
    //this.filteredReceptions = this.receptions;
    this.reception = { } as WoReceipt ;
    //this.getAllRecords();

    const customFilterName = "custom-equals";
    this.filterService.register(
      customFilterName,
      (value: { toString: () => any; } | null | undefined, filter: string | null | undefined): boolean => {
        if (filter === undefined || filter === null || filter.trim() === "") {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.toString() === filter.toString();
      }
    );
    
    this.matchModeOptions = [
      { label: "Custom Equals", value: customFilterName },
      { label: "Starts With", value: FilterMatchMode.STARTS_WITH },
      { label: "Contains", value: FilterMatchMode.CONTAINS }
    ];
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.receptionService.getWoReceiptsList(); 
    s.snapshotChanges().subscribe(data => {
      this.receptions = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.receptions.push(jsonItem as WoReceipt);
        }
      })
    });

    let u = this.unitService.GetUnitsList(); 
    u.snapshotChanges().subscribe(data => {
      this.units = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.units.push(jsonItem as unknown as Unit);
        }
      })
    });

    // column management
    this.cols = [
      { field: 'woreceipt', header: 'Internal code' },
      { field: 'receipt', header: 'Receipt' },
      { field: 'deliveryMan', header: 'Delivery man' },
      { field: 'receiver', header: 'Receiver' },
      { field: 'provider', header: 'Provider' },
      { field: 'instant', header: 'Date time' },
      { field: 'items', header: 'Items' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
 
    //  this.sortColumn('datetime',-1);

    this.sortData( {field: 'instant', order: -1} );

    // const customFilterName = 'multi-select-in';
    // this.filterService.register(customFilterName, (value: any[], filter: string | any[] | null): boolean => {
      
    //   console.log('FILTER', value);
    //   console.log('FILTER', filter);
    //   if (filter == null || filter.length == 0) {
    //     return false;
    //   }
    //   return false;
    //  // return value.some((r: any) => filter.includes(r));
    // });

    this.genreList = [
      { value: 'Drama', label: 'Drama' },
      { value: 'Crime', label: 'Crime' },
      { value: 'Action', label: 'Action' },
      { value: 'History', label: 'History' },
      { value: 'Biography', label: 'Biography' },
      { value: 'Thriller', label: 'Thriller' },
    ];

    const customFilterName = "custom-equals";
    this.filterService.register(
      customFilterName,
      (value: { toString: () => any; } | null | undefined, filter: string | null | undefined): boolean => {
        if (filter === undefined || filter === null || filter.trim() === "") {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.toString() === filter.toString();
      }
    );
    
    this.matchModeOptions = [
      { label: "Custom Equals", value: customFilterName },
      { label: "Starts With", value: FilterMatchMode.STARTS_WITH },
      { label: "Contains", value: FilterMatchMode.CONTAINS }
    ];


   
  }

  dataState() {     
    this.receptionService.getWoReceiptsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Receptions);
            doc.save('content-types.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.receptions);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "content-types");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.receptions, 'Receptions', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedReceptions, 'content-types', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.reception = {} as WoReceipt;
    // this.submitted = false;
    // this.receptionDialog = true;
    this.router.navigate(['/reception/receives']);
  }

  deleteSelectedReceptions() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Packaging Types?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.receptions = this.receptions.filter(val => !this.selectedReceptions.includes(val));
          this.receptions.forEach( pallet => {
            this.receptionService.deleteWoReceipt(pallet.$key);
          } );
          
          this.selectedReceptions = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging types deleted', life: 3000});
      }
  });
  }

  editReception(reception: WoReceipt) {
    this.reception = {...reception};
    this.receptionDialog = true;
  }

  deleteReception(reception: WoReceipt) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + reception.woreceipt + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.receptions = this.receptions.filter(val => val.$key !== reception.$key);
            this.reception = {} as WoReceipt;
            this.receptionService.deleteWoReceipt(reception.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.receptionDialog = false;
    this.submitted = false;
  }

  saveReception() {
    this.submitted = true;
    if (this.reception.$key) {
      this.receptions[this.findIndexById(this.reception.$key)] = this.reception;                
      this.receptionService.updateWoReceipt(this.reception);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';
      this.receptionService.addWoReceipt(this.reception);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Created', life: 3000});
      this.receptions = [...this.receptions];
    }
    this.receptionDialog = false;
    this.reception = { } as WoReceipt;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.receptions.length; i++) {
        if (this.receptions[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
   // original  
   dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  
    // test 1  this.filteredReceptions = this.customFilter(this.receptions, (event.target as HTMLInputElement).value)

    // test2
    //const filterValue = event.target.value.toLowerCase();
   // dt.filter(this.customFilter, (event.target as HTMLInputElement).value, 'contains');

  //  dt.filter( (value : any, field : any): boolean => {
  //   return true;
  //  }, (event.target as HTMLInputElement).value, 'contains');

    // const filterValue = event.target.value.toLowerCase();
    // dt.filter( 
    //   (value : any, field : any): boolean => {
    //     return this.customFilter(value, filterValue);
    //     // if (typeof value === 'object' && value !== null) {
    //     //   return value.some( item : any => this.customFilter(item.aName));
    //     // } else if (typeof value === 'number') {
    //     //   return value === parseInt(filterValue, 10);
    //     // } else if (typeof value === 'string') {
    //     //   return value.toLowerCase().includes(filterValue);
    //     // } else {
    //     //   return false;
    //     // }
    //   },
    //   'provider',
    //   'contains'

    // );

  }

  completeReception(reception : WoReceipt) {
    const receptionTmp = {
      ...reception
    };
    console.log("SAVE", receptionTmp);
    this.receptionService.updateWoReceipt(  receptionTmp );
  }

  receptionItems( 
     items : Item[]
  ) : Item[]  {
    
    console.log(typeof items);
    // const keys = Object.keys(items);
    // let results : any[] = [];
    // keys.forEach( key => { 
    //   if (this.isNumber(key)) 
    //   results[Number(key)] = items[Number(key)];
    //   } 
    // );
    return Object.assign([],items);
  }

//   display(reception : WoReceipt, items : {
//     identifier?: string;
//     productType?: ProductType;
//     quantity?: number;
// }[]) : string {
//     console.log(typeof reception.items);
//     let result = "";

//     let ritems = items;

//     const keys = Object.keys(items);
//     let ma : any[] = [];
//     keys.forEach( key => { 
//       if (this.isNumber(key)) 
//         ma[Number(key)] = items[Number(key)];
//       } 
//     );
//     console.log('ma', ma); // 👉️ ['name', 'country']

    
//     //const snapshotToArray = ritems => Object.entries(ritems).map(e => Object.assign(e[1], { key: e[0] }));

//     // console.log(typeof items);
//     ma.forEach( item => {
//       result += " id: "+ item.identifier;
//       result += " quantity: "+ item.quantity;
//       result += " name: "+ item.productType;
//     });
//     return result;
//   }

  isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
  
    if (str.trim() === '') {
      return false;
    }
  
    return !Number.isNaN(Number(str));
  }

  sortColumn(column: string, order: number) {
    this.table.sortField = column;
    this.table.sortOrder = order;
    this.table.sort({})
    this.table.reset();
    this.changeDetectorRef.detectChanges();
  }

  sortData(event: SortEvent) {
    //this.table.sort(event);
    //this.table.reset();
    this.changeDetectorRef.detectChanges();
  }


  // TEST 1
  // customFilter(data: WoReceipt[], filterValue: string) {
  //   return data.filter((item : WoReceipt) => {
  //     // Filter simple fields
  //     let matches = item.position?.toString().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.datetime?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 || 
  //                   item.receiver?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.provider?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.deliveryMan?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.receipt?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.woreceipt?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 ||
  //                   item.notes?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;



  //     // Filter complex fields
  //     matches = matches || item.items.some((field : Item) => {
  //       console.log('search', field);
  //       return   field.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
  //                field.description?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
  //     });
      
  //     return matches;
  //   });
  // }


  // TEST 2
  // customFilter2(value: any, filter: string): boolean {
  //   console.log( typeof value);
  //   console.log( filter);
  //   if (value instanceof Array) {
  //     // If value is an array, search through each item in the array
  //     for (let i = 0; i < value.length; i++) {
  //       if (this.customFilter2(value[i], filter)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   } else if (typeof value === 'object') {
  //     // If value is an object, search through each property in the object
  //     for (const key in value) {
  //       if (Object.prototype.hasOwnProperty.call(value, key)) {
  //         if (this.customFilter2(value[key], filter)) {
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   } else {
  //     // If value is a primitive type, compare it to the filter value
  //     return value.toString().toLowerCase().includes(filter);
  //   }
  // }

  // customFilter3(value: any, filter: string): boolean {
  //   if (typeof value === 'object') {
  //     for (const key in value) {
  //       if (Object.prototype.hasOwnProperty.call(value, key)) {
  //         if (this.customFilter3(value[key], filter)) {
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   } else {
  //     return value.toString().toLowerCase().includes(filter);
  //   }
  // }

  // customFilter(value: any, filter: string): boolean {
  //   // your custom filter logic here
  //   return true;
  // }

}
