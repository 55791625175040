import { Injectable } from '@angular/core';
import { Movement } from '../interfaces/movement';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class MoveCrudService {

  movementsRef: AngularFireList<any> | undefined;
  movementRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create Movement
  addMovement(movement: Movement) {
    this.movementsRef = this.db.list('tables/movements');
    console.log("Add", movement)
    this.movementsRef?.push( movement );
  }
  // Fetch Single Movement Object
  getMovement(id: string) {
    this.movementRef = this.db.object('tables/movements/' + id);
    return this.movementRef;
  }
  // Fetch Movements List
  getMovementsList() {
    this.movementsRef = this.db.list('tables/movements');
    return this.movementsRef;
  }
  // Update Movement Object
  updateMovement(movement: Movement) {
    // this.PackagingTypeRef = this.db.object('PackagingTypes-list/' + packagingType.$key);
    // let tmp : Partial<PackagingType>  = {
    //   ...packagingType
    // }
    // delete tmp?.$key;
    // this.PackagingTypeRef?.update(tmp);
  }
  // Delete Movement Object
  deleteMovement(id: string) {
    this.movementRef = this.db.object('tables/movements/' + id);
    this.movementRef.remove();
  }

}
