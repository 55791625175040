<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
    </div>

    <br>
    <h1><b>Step 1:</b> What do you need?</h1>
    <br>


    <p-pickList #pl 
        [responsive]="true" 
        [source]="list1" 
        [target]="list2" 
        [dragdrop]="true"
        sourceHeader="Product types" 
        targetHeader="My selection" 
        [responsive]="true" 
        [sourceStyle]="{'width':'350px','height':'200px'}" 
        [targetStyle]="{'width':'350px','height':'200px'}" 
        filterBy="name,batch,location"
        (onMoveToTarget)="moveToTarget($event)"
        (onMoveAllToTarget)="moveToTarget($event)"
        (onMoveToSource)="moveToSource($event)"
        (onMoveAllToSource)="moveToSource($event)"
        (onSourceReorder)="sourceReorder($event)"
        sourceFilterPlaceholder="Search" 
        targetFilterPlaceholder="Search"
        [disabled]="disabledPickList()"
        >
        
        <ng-template let-receipt pTemplate="item">
            <div>
                <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
                <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{receipt.name}} - {{receipt.batch}} ( {{receipt.location}} )</div>
            </div>
        </ng-template>
       
    </p-pickList>


    <br>
    <br>
    <br>
    <h1><b>Step 2:</b> Check, refine and choose purchase orders</h1>
    <br>

    <div class="card">
    <p-dataView 
        [value]="purchaseOrders" 
        [paginator]="true" 
        [rows]="9"  
        [style]="{'height':'800px','width':'770px'}"
        layout="grid">
        
        <ng-template pTemplate="header">
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </ng-template>
        <ng-template let-car pTemplate="listItem">
            <div class="p-col-12">
                <div class="product-list-item">
                    <div class="product-list-detail">
                        <div class="product-name">{{car}}</div>
                        <div class="product-description">{{car}}</div>
                        <!-- <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating> -->
                        <i class="pi pi-tag product-category-icon"></i><span class="product-category">{{car}}</span>
                    </div>
                    <div class="product-list-action">
                        <span class="product-price">${{car}}</span>
                        <p-button icon="pi pi-shopping-cart" label="Add to Cart" ></p-button>
                        <span [class]="'product-badge status-' + car.toLowerCase()">{{car}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template let-car pTemplate="gridItem">
            <div class="col-12 md:col-4">
                <div class="product-grid-item card">
                    <div class="product-grid-item-top">
                        <div>
                            <i class="pi pi-tag product-category-icon"></i>
                            <span class="product-category">{{car}}</span>
                        </div>
                        <span [class]="'product-badge status-' + car.toLowerCase()">{{car}}</span>
                    </div>
                    <div class="product-grid-item-content">
                        <div class="product-name">{{car}}</div>
                        <div class="product-description">{{car}}</div>
                        <!-- <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating> -->
                    </div>
                    <div class="product-grid-item-bottom">
                        <span class="product-price">${{car}}</span>
                        <p-button icon="pi pi-shopping-cart" ></p-button>
                    </div>
                </div>
            </div>
        </ng-template>
        
        <!-- <ng-template pTemplate="footer">
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </ng-template> -->
    </p-dataView>
</div>




    <form [formGroup]="formGroup">
        <div class="two-columns">
            <div class="col1">
                <div class="form-group">
                    <label for="datetime">Date and time</label>
                    <p-calendar [(ngModel)]="date7"  [ngModelOptions]="{standalone: true}" [showIcon]="true" [showTime]="true" [showSeconds]="true" inputId="time" (onSelect)="onSelect($event)" (onSelect)="onBlur($event)" (onClose)="onClose($event)"></p-calendar>
                </div>

                <div class="p-inputgroup form-group">
                    <label for="receiver">Receiver</label>
                    <input style="width: 262px" class="inputgroup" readonly pInputText formControlName="receiver" type="text" class="form-control" id="receiver" aria-describedby="receiverHelp" placeholder="Receiver" value="">
                   <button pButton pRipple  icon="pi pi-user" class="p-button-success" (click)="updateReceiver('123');"></button>
                </div>
              
                <div class="form-group">
                    <label for="email">Provider</label>
                    <p-dropdown 
                        [style]="{'minWidth':'300px', 'width': '300px'}"
                        formControlName="provider"
                        appendTo="body" 
                        [filter]="true"
                        [showClear]="true" 
                        [options]="providers" 
                        optionLabel="name" 
                        optionValue="$key"
                        placeholder="Any">
                    </p-dropdown>   
                </div>  
              
                <div class="form-group">
                    <label for="email">Delivery person</label>
                    <p-dropdown 
                        [style]="{'minWidth':'300px', 'width': '300px'}"
                        formControlName="deliveryMan"
                        appendTo="body" 
                        [filter]="true"
                        [showClear]="true" 
                        [options]="deliveryMen" 
                        optionLabel="name" 
                        optionValue="$key"
                        placeholder="Any">
                    </p-dropdown>  
                </div>  
              
                <div class="form-group">
                    <label for="receipt">Receipt</label>
                    <input style="min-width: 300px" pInputText formControlName="receipt" type="text" class="form-control" id="receipt" aria-describedby="receiptHelp" placeholder="Receipt number">
                    <!-- <small id="receiptHelp" class="form-text text-muted">The receipt number if there is one.</small> -->
                </div>  
              
                <div class="p-inputgroup form-group">
                    <label for="woreceipt">Ziptrak code</label>
                    <input pInputText formControlName="woreceipt" type="text" class="form-control" id="woreceipt" aria-describedby="woreceipttHelp" placeholder="An internal code to retrieve it later" value="{{ getWoCode() }}">  
                    <button pButton pRipple icon="pi pi-refresh" class="p-button-success" (click)="updateWoReceipt('123');"></button>
                </div> 
                  
                <div class="form-group" >
                    <label for="notes">Notes</label>
                    <input style="min-width: 300px" pInputText formControlName="notes" type="text" class="form-control" id="notes" aria-describedby="notesHelp" placeholder="e.g: No issue">
                </div>
            </div>
    
            <div style="border-left: 2px solid #004170; height: 270px; margin-top: 25px;margin-right: 35px"></div>
    
            <div class="col2">
                <div class="form-group" style="width: 500px">
                    <label style="line-height: 50px; height: 50px;vertical-align: middle;" for="items">Items</label>
                
                    <button pButton style="text-align: right; float: right;" pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
                    
                    <p-table #dt 
                        [ngStyle]="{'width': 500}"
                        [value]="items"                                                                                                      
                        [rows]="100" 
                        [paginator]="true" 
                        [globalFilterFields]="['name','description','status']"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [(selection)]="selectedItems" 
                        [resizableColumns]="true"
                        [reorderableColumns]="true"
                        [rowHover]="true" 
                        dataKey="$key"
                        styleClass="p-datatable-striped"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" 
                        [showCurrentPageReport]="true"
                        selectionMode="multiple"
                        [columns]="selectedColumns"
                >
                
                <!-- <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="max-width:10%">
                        <col style="max-width:20%">
                        <col style="min-width:10%;max-width:80%">
                        <col style="max-width:20%">
                    </colgroup>
                </ng-template> -->
                
                <!-- <ng-template pTemplate="caption">
                    <p-multiSelect 
                        [options]="cols" 
                        [(ngModel)]="selectedColumns" 
                        optionLabel="header"
                        selectedItemsLabel="{0} columns selected" 
                        [style]="{minWidth: '200px'}" 
                        placeholder="Choose Columns">
                    </p-multiSelect>
                </ng-template> -->
                
                
                <ng-template pTemplate="header" let-columns="columns">
                    <tr>
                        <th pResizableColumn pSortableColumn="position" style="width: 7rem">
                            Rank<p-sortIcon field="position"></p-sortIcon>
                        </th>
                        <!-- <th id="quantity" name="qunatity" pResizableColumn pReorderableColumn pSortableColumn="quantity" [class]="includesCol('quantity') ? 'd' : 'h'">Nb 
                            <p-sortIcon field="name"></p-sortIcon> 
                            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                        </th> -->
                        <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                            <p-sortIcon field="name"></p-sortIcon> 
                            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                        </th>
                        <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description</th>
                       <th pResizableColumn pReorderableColumn style="width: 8rem">
                            Actions 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <!-- <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox> -->
                        </th>
                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns" let-index="rowIndex">
                    <tr [pReorderableRow]="index" >
                        <td pEditableColumn>
                            <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="item.position" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{item.position}} {{index}}
                                </ng-template>
                            </p-cellEditor>
                            <!-- <p-tableCheckbox [value]="provider"></p-tableCheckbox> -->
                        </td>
                
                        <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="item.name" required>
                                </ng-template>
                                <ng-template pTemplate="output" >
                                    <span >{{item.name}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                
                        <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                            <p-cellEditor >
                                <ng-template pTemplate="input" >
                                    <input pInputText type="text" [(ngModel)]="item.description"  (blur)="completeProvider(item)" (keydown.tab)="completeProvider(item)" (keydown.enter)="completeProvider(item)"  required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span >{{item.description}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                
                        <td class="row-actions">
                            <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProvider(item)"></button>
                            <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProvider(item)"></button>
                               <!-- <p-tableCheckbox class="checkbox" [value]="provider"></p-tableCheckbox> -->
                            
                        </td>
                    </tr>
                </ng-template>
                <!-- <ng-template pTemplate="summary">
                    <div class="summary">
                        <div class="summary-total">
                            Total: {{items ? items.length : 0 }} items.
                        </div>
                        <div class="summary-export">
                            <button style="margin-right: 0px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        </div>
                    </div>
                </ng-template> -->
                </p-table>
                </div>
            </div>
    
        </div>

        <div class="submit-line">
            <button pButton type="submit"  label="Submit" [disabled]="!formGroup.valid" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="onSubmit()"></button>
        </div>
    </form>


    <p-dialog [(visible)]="itemDialog" [style]="{width: '450px', height: '380px'}" header="New Item" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>  
        <div class="p-field">
            <label class="label" for="productTypes" style=" display: inline-block;;margin-left: 0px;margin-top: 0px;margin-bottom: 10px;">Product types</label>
            <p-dropdown 
                #productYypes
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="dialogSelectProductTypeKey" 
                [options]="productTypes" 
                optionLabel="name" 
                optionValue="$key"
                (onChange)="onChangeProductType(dialogSelectProductTypeKey)"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field">
            <label class="label" for="description">Notes (quantity and remarks)</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="item.description" rows="3" cols="20"></textarea>
        </div>
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success p-mr-2" (click)="addItem()"></button>
        </div>
    </p-dialog>

    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>

