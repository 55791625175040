import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import { APIService } from 'src/app/API.service';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { CompanyCrudService } from 'src/app/shared/services/company-crud.service';
import { Company } from 'src/app/shared/interfaces/company';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  companys: Company[];
  company: Company;
  selectedCompanys: Company[] = [];

  companyDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private companyService : CompanyCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Companies'}
    ];

    this.companys = [];
    this.company = { } as Company ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.companyService.getCompanysList(); 
    s.snapshotChanges().subscribe(data => {
      this.companys = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.companys.push(jsonItem as Company);
        }
      })
    })


   // this.getAllRecords();

    // column management
    this.cols = [
      { field: 'companyName', header: 'Company Name' },
      { field: 'companyId', header: 'Company ABN/ACN' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.companyService.getCompanysList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.companys);
            doc.save('companys.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.companys);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "companys");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.companys, 'companys', ['companyId', 'companyName', '$key', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.company = {} as Company;
    this.submitted = false;
    this.companyDialog = true;
  }

  deleteSelectedCompanys() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected companys?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.companys = this.companys.filter(val => !this.selectedCompanys.includes(val));
            // this.selectedCompanys = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Companyss Deleted', life: 3000});
        }
    });
  }

  editCompany(company: Company) {
    this.company = {...company};
    this.companyDialog = true;
  }

  deleteCompany(company: Company) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + company.companyName + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.companys = this.companys.filter(val => val.$key !== company.$key);
            this.company = {} as Company;
            this.companyService.deleteCompany(company.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.companyDialog = false;
    this.submitted = false;
  }

  saveCompany() {
    this.submitted = true;

    // if (this.company.companyName.trim()) {
    //     if (this.company.$key) {
    //         this.companys[this.findIndexById(this.company.$key)] = this.company;                
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Updated', life: 3000});
    //     }
    //     else {
    //         // this.company.id = this.createId();
    //         // this.company.image = 'product-placeholder.svg';
    //         this.companys.push(this.company);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Created', life: 3000});
    //     }
    //}

    this.companyService.addCompany(this.company);
    this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Created', life: 3000});

        this.companys = [...this.companys];
        this.companyDialog = false;
        this.company = { } as Company;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.companys.length; i++) {
        if (this.companys[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeCompany(company : Company) {
    console.log("SAVE", company);
  }

}
