import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl('',[ Validators.minLength(2), Validators.required ])
  });
  
  get codeInput() { return this.forgotPasswordForm.get('code'); }
  get emailInput() { return this.forgotPasswordForm.get('email'); }  
  

  constructor(
    public authService: AuthService
  ) { 
    
  }

  ngOnInit(): void {

  }

  public sendEmail() {
    this.authService.ForgotPassword(this.emailInput?.value);
  }

}
