import { Injectable } from '@angular/core';
import { Withdrawal } from '../interfaces/withdrawal';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WithdrawCrudService {

  withdrawalsRef: AngularFireList<any> | undefined;
  withdrawalRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/withdrawals/' ;
  }

  // Create Withdrawal
  addWithdrawal(withdrawal: Withdrawal) {
    this.withdrawalsRef = this.db.list( this.path );
    const item = {
      ...withdrawal
    }
    this.withdrawalsRef?.push(withdrawal);
  }
  // Fetch Single Withdrawal Object
  getWithdrawal(id: string) {
    this.withdrawalRef = this.db.object(this.path + id);
    return this.withdrawalRef;
  }
  // Fetch Withdrawals List
  getWithdrawalsList() {
    this.withdrawalsRef = this.db.list( this.path );
    return this.withdrawalsRef;
  }
  // Update Withdrawal Object
  updateWithdrawal(withdrawal: Withdrawal) {
    this.withdrawalRef = this.db.object(this.path + withdrawal.$key);
    let item : Partial<Withdrawal>  = {
      ...withdrawal
    }
    delete item?.$key;
    this.withdrawalRef?.update(item);
  }
  // Delete Withdrawal Object
  deleteWithdrawal(id: string) {
    this.withdrawalRef = this.db.object(this.path + id);
    this.withdrawalRef.remove();
  }

}
