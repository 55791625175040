import { Injectable } from '@angular/core';
import { Transformation } from '../interfaces/transformation';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransformCrudService {

  transformationsRef: AngularFireList<any> | undefined;
  transformationRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/current/transformations/' ;
  }

  // Create Transformation
  addTransformation(transformation: Transformation) {
    this.transformationsRef = this.db.list( this.path );
    const item = {
      ...transformation
    }
    this.transformationsRef?.push(transformation);
  }
  // Fetch Single Transformation Object
  getTransformation(id: string) {
    this.transformationRef = this.db.object(this.path + id);
    return this.transformationRef;
  }
  // Fetch Transformations List
  getTransformationsList() {
    this.transformationsRef = this.db.list( this.path );
    return this.transformationsRef;
  }
  // Update Transformation Object
  updateTransformation(transformation: Transformation) {
    this.transformationRef = this.db.object(this.path + transformation.$key);
    let item : Partial<Transformation>  = {
      ...transformation
    }
    delete item?.$key;
    this.transformationRef?.update(item);
  }
  // Delete Transformation Object
  deleteTransformation(id: string) {
    this.transformationRef = this.db.object(this.path + id);
    this.transformationRef.remove();
  }

}
