
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedCompanys()" [disabled]="!selectedCompanys || !selectedCompanys.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="companys" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['companyName','companyId','companyDescription','status']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedCompanys" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="companyName" name="companyName" pResizableColumn pReorderableColumn pSortableColumn="companyName" [class]="includesCol('companyName') ? 'd' : 'h'">Company name 
                        <p-sortIcon field="companyName"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="companyId" name="companyId" pResizableColumn pReorderableColumn [class]="includesCol('companyId') ? 'd' : 'h'">Company ABN/ACN</th>
                    <!-- <th id="notes" name="notes" pResizableColumn pReorderableColumn [class]="includesCol('notes') ? 'd' : 'h'">Notes</th> -->
                    <!-- <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
                    <th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
                    <th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
                    <th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" [pSelectableRow]="company">
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="company.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{company.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="company"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('companyName') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="company.companyName" required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{company.companyName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- onEditInit= onEditComplete onEditCancel
                    (blur)="save({ data: item })"
                                 (keydown.tab)="save({ data: item })"
                                 (keydown.enter)="save({ data: item })" -->
                    <td pEditableColumn [class]="includesCol('companyId') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="company.companyId"  (blur)="completeCompany(company)" (keydown.tab)="completeCompany(company)" (keydown.enter)="completeCompany(company)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{company.companyId}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <!-- <td>{{company.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editCompany(company)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteCompany(company)"></button>
                        <p-tableCheckbox class="checkbox" [value]="company"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="company"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{companys ? companys.length : 0 }} companys.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="companyDialog" [style]="{width: '450px', height: '450px'}" header="Company Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Company Name</label>
                <input type="text" pInputText id="name" [(ngModel)]="company.companyName" required autofocus />
                <small class="p-error" *ngIf="submitted && !company.companyName">Name is required.</small>
            </div>

            <div class="p-field">
                <label for="description">Description</label>
                <textarea id="description" pInputTextarea [(ngModel)]="company.companyId" rows="3" cols="20"></textarea>
            </div>



            <!-- <div class="p-field">
                <label for="inventoryStatus">Inventory Status</label> -->
                <!-- <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'product-badge status-' + option.value">{{option.label}}</span>
                    </ng-template>
                </p-dropdown> -->
            <!-- </div> -->
    
            <!-- <div class="p-field">
                <label class="p-mb-3">Category</label>
                <div class="p-formgrid p-grid">
                    <div class="p-field-radiobutton p-col-6">
                        <p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                        <label for="category1">Accessories</label>
                    </div>
                    <div class="p-field-radiobutton p-col-6">
                        <p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
                        <label for="category2">Clothing</label>
                    </div>
                    <div class="p-field-radiobutton p-col-6">
                        <p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
                        <label for="category3">Electronics</label>
                    </div>
                    <div class="p-field-radiobutton p-col-6">
                        <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                        <label for="category4">Fitness</label>
                    </div>
                </div>
            </div> -->
    
            <!-- <div class="p-formgrid p-grid"> -->
                <!-- <div class="p-field p-col"> -->
                    <!-- <label for="price">Price</label> -->
                    <!-- <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber> -->
                <!-- </div>
                <div class="p-field p-col">
                    <label for="quantity">Quantity</label> -->
                    <!-- <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber> -->
                <!-- </div>
            </div> -->
        <!-- </ng-template> -->

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveCompany()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>




