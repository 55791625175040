import { Injectable } from '@angular/core';
import { ContentType } from '../interfaces/content-type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeCrudService {

  ContentTypesRef: AngularFireList<any> | undefined;
  ContentTypeRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create ContentType
  AddContentType(contentType: ContentType) {
    this.ContentTypesRef?.push({
      name: contentType.name,
      inputable: contentType.inputable,
      outputable: contentType.outputable,
      isEnabled: contentType?.isEnabled? true : false
    });
  }
  // Fetch Single ContentType Object
  GetContentType(id: string) {
    this.ContentTypeRef = this.db.object('products/contentTypes/' + id);
    return this.ContentTypeRef;
  }
  // Fetch ContentTypes List
  GetContentTypesList() {
    this.ContentTypesRef = this.db.list('products/contentTypes');
    return this.ContentTypesRef;
  }
  // Update ContentType Object
  UpdateContentType(contentType: ContentType) {
    this.ContentTypeRef = this.db.object('products/contentTypes/' + contentType.$key);
    

    let tmp : Partial<ContentType>  = {
      ...contentType
    }
    // if (p.quantity) {
    //   p.quantity = Number(p.quantity);
    // }
    //if (p.$key) {
    delete tmp?.$key;
    
    
    this.ContentTypeRef?.update(tmp);

  }
  // Delete ContentType Object
  DeleteContentType(id: string) {
    this.ContentTypeRef = this.db.object('products/contentTypes/' + id);
    this.ContentTypeRef.remove();
  }
}
