import { Injectable } from '@angular/core';
import { Feature } from '../interfaces/feature';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class FeatureCrudService {

  featuresRef: AngularFireList<any> | undefined;
  featureRef: AngularFireObject<any> | undefined;

  MODULE_NAME : string = 'application';
  TABLE_NAME : string = 'features';

  constructor(private db: AngularFireDatabase) {}

  // Create Feature
  addFeature(feature: Feature) {
    const item = {
      ...feature
    }
    this.featuresRef?.push( item );
  }
  // Fetch Single Feature Object
  getFeature(id: string) {
    this.featureRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    return this.featureRef;
  }
  // Fetch Features List
  getFeaturesList() {
    this.featuresRef = this.db.list(this.MODULE_NAME + '/' + this.TABLE_NAME + '/');
    return this.featuresRef;
  }
  // Update Feature Object
  updateFeature(feature: Feature) {
    this.featureRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + feature.$key);
   // console.log("P inside", provider);
    let item : Partial<Feature>  = {
      ...feature
    }
    delete item?.$key;
    this.featureRef?.update(item);
  }
  // Delete Feature Object
  deleteFeature(id: string) {
    this.featureRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
    this.featureRef.remove();
  }
}
