import { Injectable } from '@angular/core';
import { Provider } from '../interfaces/provider';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderCrudService {

  providersRef: AngularFireList<any> | undefined;
  providerRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/providers/' ;
  }

  // Create Provider
  addProvider(provider: Provider) {
    const item = {
      ...provider
    }
    this.providersRef?.push( item );
  }
  // Fetch Single Provider Object
  getProvider(id: string) {
    this.providerRef = this.db.object(this.path + id);
    return this.providerRef;
  }
  // Fetch Providers List
  getProvidersList() {
    this.providersRef = this.db.list(this.path);
    return this.providersRef;
  }
  // Update Provider Object
  updateProvider(provider: Provider) {
    this.providerRef = this.db.object(this.path + provider.$key);
    let item : Partial<Provider>  = {
      ...provider
    }
    delete item?.$key;
    this.providerRef?.update(item);
  }
  // Delete Provider Object
  deleteProvider(id: string) {
    this.providerRef = this.db.object(this.path + id);
    this.providerRef.remove();
  }
}
