import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import { APIService } from 'src/app/API.service';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { ProductCrudService } from 'src/app/shared/services/product-crud.service';
//import { ProductArchiveCrudService } from 'src/app/shared/services/product-archive-crud.service';
import { Product } from 'src/app/shared/interfaces/product';
import { ClientCrudService } from 'src/app/shared/services/client-crud.service';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { Client } from 'src/app/shared/interfaces/client';
import { Location } from 'src/app/shared/interfaces/location';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';

@Component({
  selector: 'app-ta-products',
  templateUrl: './ta-products.component.html',
  styleUrls: ['./ta-products.component.scss']
})
export class TaProductsComponent implements OnInit {

  clients: Client[] = [];
  locations: Location[] = [];
  productTypes: WoProductType[] = [];
 
  products: Product[];
  product: Product;
  selectedProducts: Product[] = [];

  productDialog: boolean = false;

  submitted: boolean = false;

  separatorExp: string = "[,| ]";

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoProduct: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  statuses : any[] = [];
  activityValues: number[] = [0, 1000];

  constructor(
   // private api: APIService,
    private productService : ProductCrudService,
    private woProductTypeService : WoProductTypeCrudService,
    //private productArchiveService : ProductArchiveCrudService,
    private clientService : ClientCrudService,
    private locationService : LocationCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'TA'},
      {label: 'Products'}
    ];

    this.products = [];
    this.product = { } as Product ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let ps = this.productService.getProductsList(); 
    ps.snapshotChanges().subscribe(data => {
      this.products = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.products.push(jsonItem as Product);
        }
      })
    });

    this.statuses = [
      {label: 'Unqualified2', value: '2'},
      {label: 'Unqualified', value: 'c1'},
      {label: 'Qualified', value: 'client 3'},
      {label: 'New', value: 'Client 3'},
      {label: 'Negotiation', value: 'negotiation'},
      {label: 'Renewal', value: 'renewal'},
      {label: 'Proposal', value: 'proposal'}
  ]

    let cs = this.clientService.getClientsList(); 
    cs.snapshotChanges().subscribe(data => {
      this.clients = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.clients.push(jsonItem as Client);
        }
      })
    });

    let s = this.woProductTypeService.getWoProductTypesList(); 
    s.snapshotChanges().subscribe(data => {
      this.productTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          const productType = jsonItem as WoProductType;
   
          
          this.productTypes.push(productType);
        }
      })
    })

    let ls = this.locationService.getLocationsList(); 
    ls.snapshotChanges().subscribe(data => {
      this.locations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.locations.push(jsonItem as unknown as Location);
          //console.log('Location' , jsonItem as unknown as Location );
        }
      })
    });


   // this.getAllRecords();

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
   //   { field: 'productType', header: 'Product type' },
      { field: 'batchId', header: 'Batch' },
   //   { field: 'client', header: 'Client' },
   //   { field: 'comment', header: 'Comments' },
      { field: 'location', header: 'Location' },
      // { field: 'row', header: 'Row' },
      // { field: 'column', header: 'Column' },
      // { field: 'level', header: 'Level' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.productService.getProductsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoProduct = false;
        this.noData = true;
      } else {
        this.hideWhenNoProduct = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.selectedProducts);
            doc.save('products.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.products);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "products");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
  //  this.exportService.exportToCsv(this.products, 'products', ['$key', 'qrcode', 'batchId', 'client', 'comment', 'location', 'row', 'column', 'level', 'quantity', 'actions']);
   // console.log("export" , this.exportColumns);
   // console.log("export selected" , this.selectedColumns);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    //this.exportService.exportToCsv(this.selectedProducts, 'products', ['$key', 'qrcode', 'batchId', 'client', 'comment', 'location', 'row', 'column', 'level', 'quantity', 'actions']);
    this.exportService.exportToCsv(this.selectedProducts, 'products', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.product = {} as Product;
    this.submitted = false;
    this.productDialog = true;
  }

  deleteSelectedProducts() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected products?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            
            this.selectedProducts.forEach( product => {
              const k : string = product.$key || ''
              this.productService.deleteProduct(k);
            } );
            
            this.selectedProducts = [];

            //this.products = this.products.filter(val => val.$key !== product.$key);
  
            
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        }
    });
  }

  archiveSelectedProducts() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to archive the selected products?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            
            this.selectedProducts.forEach( product => {
              try {
                let p : Partial<Product>= {
                  ...product
                }
                if (p.$key) {
                  delete p.$key;
                }
               // this.productArchiveService.AddProduct(p as Product);
               const k : string = product.$key || '' 
               this.productService.deleteProduct(k);
              } catch (e) {
                console.error(product);
                console.error(e);
              }
              
            } );
            
            this.selectedProducts = [];

            //this.products = this.products.filter(val => val.$key !== product.$key);
  
            
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Archived', life: 3000});
        }
    });
  }

  editProduct(product: Product) {
    this.product = {...product};
    this.productDialog = true;
  }

  archiveProduct(product: Product) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to archive ' + product.$key + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => val.$key !== product.$key);
            
            try {
              let p : Partial<Product>= {
                ...product
              }
              if (p.$key) {
                delete p.$key;
              }
            //ARCHIVE  this.productArchiveService.addProduct(p as Product);
              //this.productService.deleteProduct(product.$key);
            } catch (e) {
              console.error(product);
              console.error(e);
            }
            this.product = {} as Product;
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Archived', life: 3000});
        }
    });
  }

  deleteProduct(product: Product) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + product.$key + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => val.$key !== product.$key);
            this.product = {} as Product;
            this.productService.deleteProduct(product.$key || '');
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;

        if (this.product.$key) {
            this.products[this.findIndexById(this.product.$key)] = this.product;                
            
            this.productService.updateProduct(this.product);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
            
            
        } else {
            // this.product.id = this.createId();
            // this.product.image = 'product-placeholder.svg';

            this.productService.addProduct(this.product);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
            this.products = [...this.products];
          }

    
        
        this.productDialog = false;
        this.product = { } as Product;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeProduct(product : Product) {
    

    const p1 = {
      $key: product.$key,
      // qrcode: product.qrcode,
      // batchId: product.batchId,
      // client: product.client,
      // comment: product.comment,
      location: product.location,
      quantity: product.quantity
    }

    const p2 = {
      ...product
    }

    //const p = JSON.parse(JSON.stringify(product))

   // Object.setPrototypeOf(p, {} );
   //delete p.__proto__;
    console.log("SAVE", p2);
    
    //this.productService.UpdateProduct( Object.assign( {}, product) );

   // this.productService.UpdateProduct(  { $key : product.$key , qrcode: product.qrcode, quantity: 34} );
   this.productService.updateProduct(  p2 );
    
  }

  customFilterCallback(filter: (a: any) => void, event: any, table: Table): void {
    
  //  console.log("filter ",this.filterRows);
  //  this.filterRows = event;
    let filters : string[] = [];
    event.forEach( (v : { title: string })  => filters.push(v.title) );

   table.filter('123','qrcode','equals');

  }

  computeQuantity() : number{
    let total : number = Number(0);
    this.selectedProducts.forEach( product => {
      if (product.quantity) {
        let i : number = product.quantity as number;
        total += Number(i);
      }
    });
    return total;
  }

  computeClients() : string[] {
    let results : string[] = [];
    // this.selectedProducts.forEach( product => {
    //   if (product.client) {
    //     if (!results.includes(product.client)) {
    //       results.push(product.client);
    //     }
    //   }
    // });
    return results;
  }

  computeLocations() : string[] {
    let results : string[] = [];
    this.selectedProducts.forEach( product => {
      if (product.location) {
        if (!results.includes(product.location)) {
          results.push(product.location);
        }
      }
    });
    return results;
  }

}