import { Component, OnInit, Input, ViewChild, ElementRef  } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';


import { Table } from 'primeng/table';

import { DeliveryMan } from 'src/app/shared/interfaces/delivery-man';
import { Provider } from 'src/app/shared/interfaces/provider';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeliveryManCrudService } from 'src/app/shared/services/delivery-man-crud.service';
import { ProviderCrudService } from 'src/app/shared/services/provider-crud.service';
import { ContainerTypeNode, ProductTypeLeaf, TypeNode } from 'src/app/shared/interfaces/node';


import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-receives',
  templateUrl: './receives.component.html',
  styleUrls: ['./receives.component.scss']
})
export class ReceivesComponent implements OnInit {

 // ui beadcrumb
 breadcrumbs: MenuItem[];
 homeBreadcrumb: MenuItem = {} as MenuItem;

 providers: Provider[];
 deliveryMen: DeliveryMan[];

 datetime: string;
 providerKey: string;
 deliveryManKey: string;
 label: string;
 notes: string;



items: any[];
item: any;
selectedItems: Provider[] = [];
itemDialog: boolean = false;

submitted: boolean = false;

cols: any[] = [];
_selectedColumns: any[] = [];

files1: TreeNode[] = [];

@ViewChild('dt', {static: true}) dt : any;
exportColumns: any[] = []; 

 constructor(
  public authService: AuthService,
  private providerService: ProviderCrudService,
  private deliveryManService: DeliveryManCrudService,
  private confirmationService: ConfirmationService,
  private messageService: MessageService,
  private exportService: ExportService
 ) {
   // ui breadcrumbs
   this.breadcrumbs = [
     {label: 'Actions'},
     {label: 'Receives'}
   ];

   //
   this.providers = [];
   this.deliveryMen = [];

   this.datetime = '';
   this.providerKey = '';
   this.deliveryManKey = '';
   this.label = '';
   this.notes = '';

   this.items = [];
   this.item = {};

   this.cols = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'actions', header: 'Actions' } 
  ];

  this._selectedColumns = this.cols;

  // Exports
  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

  this.files1 = [];

  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    console.log( 'includesCol ', c);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  ngOnInit(): void {
    let p = this.providerService.getProvidersList(); 
    p.snapshotChanges().subscribe(data => {
      this.providers = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.providers.push(jsonItem as Provider);
        }
      })
    });

    let dm = this.deliveryManService.getDeliveryMansList(); 
    dm.snapshotChanges().subscribe(data => {
      this.deliveryMen = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.deliveryMen.push(jsonItem as DeliveryMan);
        }
      })
    });
  }

  getDateTime() : string {
    const now = new Date();
    this.datetime = now.toUTCString();
    return now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
  }

  displayUserData() {
    console.log( this.authService.userData ) ;
    return this.authService.userData.email;
  }




// Export management
exportPdf() {
  import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
          const doc = new jsPDF.default('l','pt');
          // @ts-ignore
          doc.autoTable(this.exportColumns, this.providers);
          doc.save('providers.pdf');
      })
  })
}

exportExcel() {
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.providers);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "items");
  });
}

exportDTCSV() {
  console.log('export csv');
  this.dt.exportCSV();
}

exportToCsv(): void {
  this.exportService.exportToCsv(this.providers, 'providers', ['title', 'description', '$key', 'actions']);
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}


  openNew() {
    this.item = {} ;
    this.submitted = false;
    this.itemDialog = true;
  }

  deleteSelectedProviders() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected providers?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.providers = this.providers.filter(val => !this.selectedProviders.includes(val));
            // this.selectedProviders = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Providerss Deleted', life: 3000});
        }
    });
  }

  editProvider(provider: Provider) {
    this.item = {...provider};
    this.itemDialog = true;
  }

  deleteProvider(provider: any) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + provider.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.items = this.items.filter(val => val.name !== provider.name);
            this.item = {};
           //DO this.providerService.DeleteProvider(provider.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Item Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.itemDialog = false;
    this.submitted = false;
  }

  saveProvider() {
    this.submitted = true;

    // if (this.provider.companyName.trim()) {
    //     if (this.provider.$key) {
    //         this.providers[this.findIndexById(this.provider.$key)] = this.provider;                
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Updated', life: 3000});
    //     }
    //     else {
    //         // this.provider.id = this.createId();
    //         // this.provider.image = 'product-placeholder.svg';
    //         this.providers.push(this.provider);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Created', life: 3000});
    //     }
    //}

    //DO this.providerService.addProvider(this.provider);
    //DO this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Created', life: 3000});

    this.items.push( this.item );

    //    this.items = [...this.items];
        this.itemDialog = false;
        this.item = { };

        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Item added', life: 3000});
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeProvider(provider : Provider) {
    console.log("SAVE", provider);
  }

  submit() {
    console.log('Date time', this.notes);
    console.log('Receiver', this.authService.userData.email);
    console.log('Provider', this.providerKey);
    console.log('Delivery man', this.deliveryManKey);
    console.log('Label', this.label);
    console.log('Notes', this.notes);
    this.items.forEach( item => {
      console.log('Item quantity ', item.quantity );
      console.log('Item name ', item.name );
    });
  }

  submitTest() {
    const mainContainer : TypeNode = new TypeNode();
    let boxOf25Cans : ContainerTypeNode = new ContainerTypeNode(25);
    let can1 : ProductTypeLeaf = new ProductTypeLeaf('can1');
    let can2 : ProductTypeLeaf = new ProductTypeLeaf('can2');
    let can3 : ProductTypeLeaf = new ProductTypeLeaf('can3');
    boxOf25Cans.children.push(can1);
    boxOf25Cans.children.push(can2);
    boxOf25Cans.children.push(can3);

    this.files1 = boxOf25Cans as unknown as TreeNode[];

    this.files1 = 
      
      [
          {
              "label": "Documents",
              "data": "Documents Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [{
                      "label": "Work",
                      "data": "Work Folder",
                      "expandedIcon": "pi pi-folder-open",
                      "collapsedIcon": "pi pi-folder",
                      "children": [{"label": "Expenses.doc", "icon": "pi pi-file", "data": "Expenses Document"}, {"label": "Resume.doc", "icon": "pi pi-file", "data": "Resume Document"}]
                  },
                  {
                      "label": "Home",
                      "data": "Home Folder",
                      "expandedIcon": "pi pi-folder-open",
                      "collapsedIcon": "pi pi-folder",
                      "children": [{"label": "Invoices.txt", "icon": "pi pi-file", "data": "Invoices for this month"}]
                  }]
          },
          {
              "label": "Pictures",
              "data": "Pictures Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [
                  {"label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo"},
                  {"label": "logo.jpg", "icon": "pi pi-image", "data": "PrimeFaces Logo"},
                  {"label": "primeui.png", "icon": "pi pi-image", "data": "PrimeUI Logo"}]
          },
          {
              "label": "Movies",
              "data": "Movies Folder",
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              "children": [{
                      "label": "Al Pacino",
                      "data": "Pacino Movies",
                      "children": [{"label": "Scarface", "icon": "pi pi-video", "data": "Scarface Movie"}, {"label": "Serpico", "icon": "pi pi-video", "data": "Serpico Movie"}]
                  },
                  {
                      "label": "Robert De Niro",
                      "data": "De Niro Movies",
                      "children": [{"label": "Goodfellas", "icon": "pi pi-video", "data": "Goodfellas Movie"}, {"label": "Untouchables", "icon": "pi pi-video", "data": "Untouchables Movie"}]
                  }]
          }
      ]
    ;
  }

  printOrder() {
    window.print();
  }

  @ViewChild('myImage') myImage: ElementRef | undefined ;
  
    printImage() {
        let printContents, popupWin;
        printContents = this.myImage?.nativeElement;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin?.document.open();
        popupWin?.document.write(`
          <html>
            <body onload="window.print();window.close()">
              <img src="${printContents.src}" width="100%">
            </body>
          </html>`
        );
        popupWin?.print();
       // popupWin?.document.close();
    }


    // https://medium.com/@Idan_Co/angular-print-service-290651c721f9

    printQRCode() {
      let printContents, popupWin;
      printContents = document.getElementById('qrcode-print');
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin?.document.open();
      popupWin?.document.write(`
        <html>
          <body onload="window.print();window.close()">
            ${printContents?.innerHTML}
          </body>
        </html>`
      );
      popupWin?.print();
      //popupWin?.document.close();
  }

}
