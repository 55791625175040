import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
//import { RecipeItemCrudService } from 'src/app/shared/services/recipe-Item-crud.service';
import { RecipeItem } from 'src/app/shared/interfaces/recipe-item';
import { UnitCrudService } from 'src/app/shared/services/unit-crud.service';

import { v4 as uuidv4, v4 } from 'uuid';

@Component({
  selector: 'app-recipe-items',
  templateUrl: './recipe-items.component.html',
  styleUrls: ['./recipe-items.component.scss']
})
export class RecipeItemsComponent implements OnInit {


  @Input() isPreTable: boolean = false;
  @Input() isPostTable: boolean = false;
  @Input() isTableFilters: boolean = false;
  @Input() tableTitle: string = 'Recipe items';

  @Output() itemsEmitter = new EventEmitter<RecipeItem[]>();
  sendOutput(recipeItems: RecipeItem[]) {
    this.itemsEmitter.emit(this.recipeItems);
  }


  recipeItems: RecipeItem[] = [];
  recipeItem: RecipeItem = { } as RecipeItem ;
  selectedRecipeItems: RecipeItem[] = [];

  recipeItemDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
   // private recipeItemService : RecipeItemCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'RecipeItems'}
    ];

    this.recipeItems = [];
    this.recipeItem = { } as RecipeItem ;
    //this.getAllRecords();
  }

  ngOnInit(): void {


    // this.recipeItems = [
    //   {
    //     $key: '123',
    //     quantity: 3,
    //     name: 'test'
    //   }
    // ];
    
    // this.dataState();
    // let s = this.recipeItemService.getRecipeItemsList(); 
    // s.snapshotChanges().subscribe(data => {
    //   this.recipeItems = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.recipeItems.push(jsonItem as RecipeItem);
    //     }
    //   })
    // })


   // this.getAllRecords();

    // column management
    this.cols = [
      { field: 'quantity', header: 'Quantity' },
      { field: 'name', header: 'Name' },
      // { field: 'description', header: 'Description' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    // this.recipeItemService.getRecipeItemsList().valueChanges().subscribe(data => {
    //   this.preLoader = false;
    //   if(data.length <= 0){
    //     this.hideWhenNoPallet = false;
    //     this.noData = true;
    //   } else {
    //     this.hideWhenNoPallet = true;
    //     this.noData = false;
    //   }
    // })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
   // console.log( 'includesCol', c);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.recipeItems);
            doc.save('recipeItems.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.recipeItems);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "recipeItems");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.recipeItems, 'recipeItems', ['title', 'description', '$key', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.recipeItem = {} as RecipeItem;
    this.submitted = false;
    this.recipeItemDialog = true;
  }

  deleteSelectedRecipeItems() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected recipeItems?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.recipeItems = this.recipeItems.filter(val => !this.selectedRecipeItems.includes(val));
            // this.selectedRecipeItems = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'RecipeItemss Deleted', life: 3000});
        }
    });
  }

  editRecipeItem(recipeItem: RecipeItem) {
    this.recipeItem = {...recipeItem};
    this.recipeItemDialog = true;
  }

  deleteRecipeItem(recipeItem: RecipeItem) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + recipeItem.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.recipeItems = this.recipeItems.filter(val => val.$key !== recipeItem.$key);
            this.recipeItem = {} as RecipeItem;
         //   this.recipeItemService.deleteRecipeItem(recipeItem.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'RecipeItem Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.recipeItemDialog = false;
    this.submitted = false;
  }

  saveRecipeItem() {
    this.submitted = true;

    if (this.recipeItem.name.trim()) {
        if (this.recipeItem.$key) {
            this.recipeItems[this.findIndexById(this.recipeItem.$key)] = this.recipeItem;                
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'RecipeItem Updated', life: 3000});
        } else {
            //this.recipeItem.$key = uuidv4();
            this.recipeItems.push(this.recipeItem);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'RecipeItem Created', life: 3000});
        }
    }

   // this.recipeItem.$key = '234'; 
       // this.recipeItems.push( this.recipeItem );

        this.sendOutput(this.recipeItems);

        this.recipeItemDialog = false;
        this.recipeItem = { } as RecipeItem;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.recipeItems.length; i++) {
        if (this.recipeItems[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeRecipeItem(recipeItem : RecipeItem) {
    console.log("SAVE", recipeItem);
  }

}
