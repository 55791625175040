
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedReceptions()" [disabled]="!selectedReceptions || !selectedReceptions.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="receptions" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['identifier','deliveryMan','provider','receiver','instant']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedReceptions" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="identifier" name="identifier" pResizableColumn pReorderableColumn pSortableColumn="identifier" [class]="includesCol('identifier') ? 'd' : 'h'">ID 
                        <p-sortIcon field="identifier"></p-sortIcon> 
                        <p-columnFilter type="text" field="identifier" display="menu"></p-columnFilter>
                    </th>
                    <th id="deliveryMan" name="deliveryMan" pResizableColumn pReorderableColumn pSortableColumn="deliveryMan" [class]="includesCol('deliveryMan') ? 'd' : 'h'">Delivery man 
                        <p-sortIcon field="deliveryMan"></p-sortIcon> 
                        <!-- <p-columnFilter field="deliveryMan" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="minActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{minActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{minActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    <th id="receiver" name="receiver" pResizableColumn pReorderableColumn pSortableColumn="receiver" [class]="includesCol('receiver') ? 'd' : 'h'">Receiver 
                        <p-sortIcon field="receiver"></p-sortIcon> 
                        <!-- <p-columnFilter field="maxCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="maxActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{maxActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{maxActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    <th id="provider" name="provider" pResizableColumn pReorderableColumn pSortableColumn="provider" [class]="includesCol('provider') ? 'd' : 'h'">Provider 
                        <p-sortIcon field="unit"></p-sortIcon> 
                        <p-columnFilter type="text" field="unit" display="menu"></p-columnFilter>
                        
                        <img alt="Create provider" src="/assets/images/unit.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/units']"/>
                            <!-- <button mat-flat-button >+</button> -->
                    </th>
                    <th id="instant" name="instant" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('instant') ? 'd' : 'h'">Date time
                        <p-columnFilter type="datetime" field="instant" display="menu"></p-columnFilter>
                    </th>
                    <th id="items" name="items" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('items') ? 'd' : 'h'">Items
                        <p-columnFilter type="text" field="items" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-reception let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{reception.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="reception"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('identifier') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.identifier" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.identifier}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('deliveryMan') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.deliveryMan" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.deliveryMan}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('receiver') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.receiver" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.receiver}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('provider') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.unit" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.unit}}</span>
                            </ng-template>
                        </p-cellEditor> -->

                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <!-- <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="reception.unit" 
                                    [options]="units" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completeReception(reception)"
                                    (keydown.tab)="completeReception(reception)"
                                    (keydown.enter)="completeReception(reception)"
                                    placeholder="Any">
                                </p-dropdown> -->
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.provider}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('instant') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <!-- <p-inputSwitch  [(ngModel)]="reception.isEnabled" (onChange)="completeReception(reception)"></p-inputSwitch> -->
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="reception.instant"  (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{reception.instant}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('items') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <!-- <p-inputSwitch  [(ngModel)]="reception.isEnabled" (onChange)="completeReception(reception)"></p-inputSwitch> -->
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="reception.items"  (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- {{ display( reception, reception.items) }} -->
                                <ul>
                                    <li *ngFor="let item of receptionItems(reception.items)">
                                       T {{item.identifier}} - {{item.quantity}} - {{item.productType}} 
                                    </li>
                                </ul>
           
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <!-- <td>{{reception.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editReception(reception)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteReception(reception)"></button>
                        <p-tableCheckbox class="checkbox" [value]="reception"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="reception"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{receptions ? receptions.length : 0 }} receptions.
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="receptionDialog" [style]="{width: '450px', height: '450px'}" header="Packaging Type Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="identifier">ID</label>
                <input type="text" pInputText id="identifier" [(ngModel)]="reception.identifier" required autofocus />
                <small class="p-error" *ngIf="submitted && !reception.identifier">Name is required.</small>
            </div>

            <!-- <div style="margin-top: 20px;">
                <b>Capacity</b>
            </div>
            <div style="flex-direction: row; display: flex;">
                <label for="minCapacity" style="margin-left: 20px;margin-right: 10px;">Min</label>
                <input type="text" id="minCapacity" pInputText style="width: 50px" [(ngModel)]="reception.minCapacity" />

                <span style="width: 50px;"></span>

                <label for="maxCapacity" style="margin-left: 20px;margin-right: 10px;">Max</label>
                <input type="text" id="maxCapacity" pInputText style="width: 50px"   [(ngModel)]="reception.maxCapacity" />

                <span style="margin-left: 20px;margin-right: 10px;">Units</span>
                <p-dropdown 
                    [style]="{'width':'100px'}"
                    autoWidth="false"
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="reception.unit" 
                    [options]="units" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div> -->

            <div style="flex-direction: row; display: flex ">
                
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveReception()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>





