import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { ReceptionCrudService } from 'src/app/shared/services/reception-crud.service';
import { Reception } from 'src/app/shared/interfaces/reception';
import { Unit } from 'src/app/shared/interfaces/unit';
import { UnitCrudService } from 'src/app/shared/services/unit-crud.service';
import { ProductType } from 'src/app/shared/interfaces/product-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-receptions',
  templateUrl: './receptions.component.html',
  styleUrls: ['./receptions.component.scss']
})
export class ReceptionsComponent implements OnInit {

  receptions: Reception[];
  reception: Reception;
  selectedReceptions: Reception[] = [];

  receptionDialog: boolean = false;

  submitted: boolean = false;

  units: Unit[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private receptionService : ReceptionCrudService,
    private unitService : UnitCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService,
    private router : Router
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Tables'},
      {label: 'Reception'}
    ];

    this.receptions = [];
    this.reception = { } as Reception ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.receptionService.getReceptionsList(); 
    s.snapshotChanges().subscribe(data => {
      this.receptions = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.receptions.push(jsonItem as Reception);
        }
      })
    });

    let u = this.unitService.GetUnitsList(); 
    u.snapshotChanges().subscribe(data => {
      this.units = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.units.push(jsonItem as unknown as Unit);
        }
      })
    });

    // column management
    this.cols = [
      { field: 'identifier', header: 'Identifier' },
      { field: 'deliveryMan', header: 'Delivery man' },
      { field: 'receiver', header: 'Receiver' },
      { field: 'provider', header: 'Provider' },
      { field: 'instant', header: 'Date time' },
      { field: 'items', header: 'Items' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.receptionService.getReceptionsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Receptions);
            doc.save('content-types.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.receptions);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "content-types");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.receptions, 'Receptions', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedReceptions, 'content-types', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.reception = {} as Reception;
    // this.submitted = false;
    // this.receptionDialog = true;
    this.router.navigate(['/reception/receive']);
  }

  deleteSelectedReceptions() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Packaging Types?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.receptions = this.receptions.filter(val => !this.selectedReceptions.includes(val));
          this.receptions.forEach( pallet => {
            this.receptionService.deleteReception(pallet.$key);
          } );
          
          this.selectedReceptions = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging types deleted', life: 3000});
      }
  });
  }

  editReception(reception: Reception) {
    this.reception = {...reception};
    this.receptionDialog = true;
  }

  deleteReception(reception: Reception) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + reception.identifier + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.receptions = this.receptions.filter(val => val.$key !== reception.$key);
            this.reception = {} as Reception;
            this.receptionService.deleteReception(reception.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.receptionDialog = false;
    this.submitted = false;
  }

  saveReception() {
    this.submitted = true;
    if (this.reception.$key) {
      this.receptions[this.findIndexById(this.reception.$key)] = this.reception;                
      this.receptionService.updateReception(this.reception);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';
      this.receptionService.addReception(this.reception);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Created', life: 3000});
      this.receptions = [...this.receptions];
    }
    this.receptionDialog = false;
    this.reception = { } as Reception;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.receptions.length; i++) {
        if (this.receptions[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeReception(reception : Reception) {
    const receptionTmp = {
      ...reception
    };
    console.log("SAVE", receptionTmp);
    this.receptionService.updateReception(  receptionTmp );
  }

  receptionItems( 
     items : any
  ) : {
    identifier?: string;
    productType?: ProductType;
    quantity?: number;
  }[]  {
    const keys = Object.keys(items);
    let results : any[] = [];
    keys.forEach( key => { 
      if (this.isNumber(key)) 
      results[Number(key)] = items[Number(key)];
      } 
    );
    return results;
  }

  display(reception : Reception, items : {
    identifier?: string;
    productType?: ProductType;
    quantity?: number;
}[]) : string {
    console.log(typeof reception.items);
    let result = "";

    let ritems = items;

    const keys = Object.keys(items);
    let ma : any[] = [];
    keys.forEach( key => { 
      if (this.isNumber(key)) 
        ma[Number(key)] = items[Number(key)];
      } 
    );
    console.log('ma', ma); // 👉️ ['name', 'country']

    
    //const snapshotToArray = ritems => Object.entries(ritems).map(e => Object.assign(e[1], { key: e[0] }));

    // console.log(typeof items);
    ma.forEach( item => {
      result += " id: "+ item.identifier;
      result += " quantity: "+ item.quantity;
      result += " name: "+ item.productType;
    });
    return result;
  }

  isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
  
    if (str.trim() === '') {
      return false;
    }
  
    return !Number.isNaN(Number(str));
  }
}
