<div class="sidebar" >
  <br/>

  <!--Barrel management module -->
  <mat-expansion-panel *ngIf="modules.barrels"> 
    <mat-expansion-panel-header>Barrel Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/ic_world.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/barrels/types']">Types</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_carpenter.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/barrels/coopers']">Coopers</button>
    </div>
    <div>
      <img src="/assets/images-black/content.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/barrels/volumes']">Volumes</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_wood.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/barrels/woods']">Wood</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.barrels"/>


  <!-- Pallet management module -->
  <mat-expansion-panel *ngIf="modules.pallets"> 
    <mat-expansion-panel-header>Pallet and Rack Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/pallet.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/pallets/pallets']">Pallets</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_stack.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/pallets/racks']">Racks</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_tank.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/pallets/tanks']">Tanks</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.pallets"/>

  <!-- Wine management module -->
  <mat-expansion-panel *ngIf="modules.wines"> 
    <mat-expansion-panel-header>Wine Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/ic_colour.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/colours']">Colours</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_grape.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/grape-varieties']">Grape varieties</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_region.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/regions']">Regions</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_grower.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/growers']">Growers</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_wine.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/current']">Wines</button>
    </div>
    <div>
      <img src="/assets/images-black/ic_tags.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wines/batches']">Batches</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.pallets"/>
  

  <!-- WO Product module -->
  <mat-expansion-panel *ngIf="modules.warehouse"> 
    <mat-expansion-panel-header>Product Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/wo-product/manage/product-types']">Product types</button>
    </div>
    <div>
      <img src="/assets/images/unit.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/units']">Units</button>
    </div>
    <div>
      <img src="/assets/images/content.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/content-types']">Content types</button>
    </div>
    <div>
      <img src="/assets/images/packaging3.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/packaging-types']">Packaging types</button>
    </div>
    <div>
      <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/product-types']">Product codes</button>
    </div>
    <div>
      <img src="/assets/images/container.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/container-types']">Container types</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.warehouse"/>

  <!-- Product module -->
  <!-- <mat-expansion-panel> 
    <mat-expansion-panel-header>Product Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/unit.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/units']">Units</button>
    </div>
    <div>
      <img src="/assets/images/content.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/content-types']">Content types</button>
    </div>
    <div>
      <img src="/assets/images/packaging3.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/packaging-types']">Packaging types</button>
    </div>
    <div>
      <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/product-types']">Product types</button>
    </div>
    <div>
      <img src="/assets/images/container.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/container-types']">Container types</button>
    </div>
  </mat-expansion-panel>
  <br/> -->

  <!-- Reception module -->
  <!-- <mat-expansion-panel> 
    <mat-expansion-panel-header>Purchase Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/providers.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/providers']">Providers</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- Reception module -->
  <mat-expansion-panel *ngIf="modules.receive"> 
    <mat-expansion-panel-header>Reception Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/deliveryMen.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/delivery-men']">Delivery men</button>
    </div>
    <div>
      <img src="/assets/images/providers.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/providers']">Providers</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.receive"/>  
    
  <!-- Movement module -->
  <mat-expansion-panel *ngIf="modules.move"> 
    <mat-expansion-panel-header>Movement Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/movement/manage/locations']">Locations</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.move"/>  

  <!-- Transformation module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Transformation Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/recipe.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/transformation/manage/recipes']">Recipes</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- WO Transformation module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Transformation Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/recipe.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/transformation/manage/wo-recipes']">Product creations</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- Output module -->
  <mat-expansion-panel *ngIf="modules.output">
    <mat-expansion-panel-header>Outputs Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/outputs.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/outputs']">Output types</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/clients']">Clients</button>
    </div>
    <!-- <div>
      <img src="/assets/images/companies.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/companies']">Companies</button>
    </div> -->
  </mat-expansion-panel>
  <br *ngIf="modules.output"/>

  <!-- Client module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Client Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/clients']">Clients</button>
    </div>
    <div>
      <img src="/assets/images/companies.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/companies']">Companies</button>
    </div>
  </mat-expansion-panel>
  <br/> -->

  <!-- Team module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>HR Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Organisation / Structure</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Team members</button>
    </div>
  </mat-expansion-panel>
  <br/> -->

  <!-- Safety module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Safety Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Emergencies</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety meetings</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety reports</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety audits</button>
    </div>
  </mat-expansion-panel>
  <br/> -->
    
  <br/>
</div>