import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wine-archive',
  templateUrl: './wine-archive.component.html',
  styleUrls: ['./wine-archive.component.scss']
})
export class WineArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
