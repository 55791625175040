import { Component, OnInit, Input, ViewChild  } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { OutputCrudService } from 'src/app/shared/services/output-crud.service';
import { Output } from 'src/app/shared/interfaces/output';


@Component({
  selector: 'app-outputs',
  templateUrl: './outputs.component.html',
  styleUrls: ['./outputs.component.scss']
})
export class OutputsComponent implements OnInit {

  outputs: Output[];
  output: Output;
  selectedOutputs: Output[] = [];

  outputDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";


  constructor(
   // private api: APIService,
    private outputService : OutputCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Outputs'}
    ];

    this.outputs = [];
    this.output = { } as Output ;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.outputService.getOutputsList(); 
    s.snapshotChanges().subscribe(data => {
      this.outputs = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let i : Output = jsonItem as Output;
          // if (i.tags) {
          //   i.tags = Object.values(i.tags);
          // }
          this.outputs.push(i);
        }
      })
    })


    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');
  
    this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
        
    } );
  }

  dataState() {     
    this.outputService.getOutputsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.outputs);
            doc.save('outputs.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.outputs);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "outputs");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.outputs, 'outputs', ['title', 'description', '$key', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.output = {} as Output;
    this.submitted = false;
    this.outputDialog = true;
  }

  deleteSelectedOutputs() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected outputs?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.outputs = this.outputs.filter(val => !this.selectedOutputs.includes(val));
          this.selectedOutputs = [];
          this.selectedOutputs.forEach( output => this.outputService.deleteOutput(output.$key));
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Outputss Deleted', life: 3000});
        }
    });
  }

  editOutput(output: Output) {
    this.output = {...output};
    this.outputDialog = true;
  }

  deleteOutput(output: Output) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + output.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.outputs = this.outputs.filter(val => val.$key !== output.$key);
            this.output = {} as Output;
            this.outputService.deleteOutput(output.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Output Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.outputDialog = false;
    this.submitted = false;
  }

  saveOutput() {
    this.submitted = true;
    this.output.position = this.outputs.length;
    this.outputService.addOutput(this.output);
    this.messageService.add({severity:'success', summary: 'Successful', detail: 'Output Created', life: 3000});
    this.outputs = [...this.outputs];
    this.outputDialog = false;
    this.output = { } as Output;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.outputs.length; i++) {
        if (this.outputs[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeOutput(output : Output) {
    this.outputService.updateOutput(output);
  }

  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;
  previousPosition : number = -1;
  newPosition : number = -1;

  completePosition(output : Output) {
    if ( this.newPosition > -1 ) {
      output.position = this.newPosition;
      output = this.checkPositionLimits(output);
        this.reorderElements(output);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }

  // Reorder
  reorder(event: any) {
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.outputs.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.outputs.length - 1 - event.dropIndex;
    const outputToMove = this.outputs[startIndex];
    
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.outputs[index] = this.outputs[index+1];
        this.outputs[index].position = index;
        this.outputService.updateOutput(this.outputs[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.outputs[index] = this.outputs[index-1];
        this.outputs[index].position = index;
        this.outputService.updateOutput(this.outputs[index]);
      }
    } 
    this.outputs[endIndex] = outputToMove;
    this.outputs[endIndex].position = endIndex;
    this.outputService.updateOutput(this.outputs[endIndex]);
  }

  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.outputs.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  modelChangeFn(event: number, output: Output) {
    console.log("event" +event);
    if (output.position != null && output.position > -1) {
      this.previousPosition = output.position;
      this.newPosition = event;
    }
  }

  checkPositionLimits(output: Output) {
    console.log('check ',output.position + ' ' + this.outputs.length);
    if (!output.position) {
      console.log('check 1');
      return this.output;
    }
    if (output.position < 0) {
      output.position = 0;
      console.log('check 2');
      return output;
    }
    if (output.position > this.outputs.length) {
      output.position = this.outputs.length - 1;
      console.log('check 3', output.position);
      return output;
    }
    console.log('check 4');
    return output;
  }

  async reorderElements(output: Output) {
    this.newPosition = this.newPosition  > this.outputs.length ? this.outputs.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === output.position ) {
      return;
    }
    console.log('Previous position: '+ this.previousPosition);
    console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const outputToMove = this.outputs[startIndex];
    console.log('Item to move: ', this.outputs[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.outputs);
        console.log('Item to +1: ', this.outputs[index+1]);

        this.outputs[index] = this.outputs[index+1];
        this.outputs[index].position = index;
        console.log('Move: ', this.outputs[index+1]);
        console.log('Move pos: ', this.outputs[index].position);
        this.outputService.updateOutput(this.outputs[index]);
        console.log('Update DB: ', this.outputs[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.outputs[index] = this.outputs[index-1];
        this.outputs[index].position = index;
        this.outputService.updateOutput(this.outputs[index]);
      }
    } 
    this.outputs[endIndex] = outputToMove;
    this.outputs[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.outputService.updateOutput(this.outputs[endIndex]);
  }

}
