import { Injectable } from '@angular/core';
import { Unit } from '../interfaces/unit';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class UnitCrudService {

  unitsRef: AngularFireList<any> | undefined;
  unitRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  // Create Unit
  AddUnit(unit: Unit) {
    this.unitsRef?.push({
      name: unit.name
    });
  }
  // Fetch Single Unit Object
  GetUnit(id: string) {
    this.unitRef = this.db.object('units-list/' + id);
    return this.unitRef;
  }
  // Fetch Units List
  GetUnitsList() {
    this.unitsRef = this.db.list('units-list');
    return this.unitsRef;
  }
  // Update Unit Object
  UpdateUnit(unit: Unit) {
    this.unitRef = this.db.object('units-list/' + unit.$key);
    let tmp : Partial<Unit>  = {
      ...unit
    }
    delete tmp?.$key;
    this.unitRef?.update(tmp);
  }
  // Update Unit Object
  // UpdateUnits(units : Unit[]) {
  //   this.unitsRef = this.db.list('units-list');
    
  //   this.unitsRef.
  // }
  // Delete Unit Object
  DeleteUnit(id: string) {
    this.unitRef = this.db.object('units-list/' + id);
    this.unitRef.remove();
  }
}
