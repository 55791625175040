import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {PrimeIcons} from 'primeng/api';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoReceipt } from 'src/app/shared/interfaces/wo-receipt';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';

@Component({
  selector: 'app-wo-product',
  templateUrl: './wo-product.component.html',
  styleUrls: ['./wo-product.component.scss']
})
export class WoProductComponent implements OnInit {

  woProducts: WoProduct[];
  woProduct: WoProduct;
  selectedWoProducts: WoProduct[] = [];

  woProductDialog: boolean = false;

  submitted: boolean = false;

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;


  events : any[];
  events1 : any[];

  productTypeMap = new Map<string, WoProductType>();

  constructor(
   // private api: APIService,
    private woProductService : WoProductCrudService,
    private woProductTypeService : WoProductTypeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Products'}
    ];

    this.woProducts = [];
    this.woProduct = { } as WoProduct ;
    //this.getAllRecords();

    this.events = [
      {status: 'Ordered', date: '15/10/2020 10:30', icon: PrimeIcons.SHOPPING_CART, color: '#9C27B0', image: 'game-controller.jpg'},
      {status: 'Processing', date: '15/10/2020 14:00', icon: PrimeIcons.COG, color: '#673AB7'},
      {status: 'Shipped', date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
      {status: 'Delivered', date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, color: '#607D8B'}
    ];
    this.events1 = [
      {status: 'Ordered', date: '15/10/2020 10:30', icon: PrimeIcons.SHOPPING_CART, color: '#9C27B0', image: 'game-controller.jpg'},
      {status: 'Processing', date: '15/10/2020 14:00', icon: PrimeIcons.COG, color: '#673AB7'},
      {status: 'Shipped', date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
      {status: 'Delivered', date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, color: '#607D8B'}
  ];

  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.woProductService.getWoProductsList(); 
    s.snapshotChanges().subscribe(data => {
      this.woProducts = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.woProducts.push(jsonItem as WoProduct);
        }
      })
    });

    let pt = this.woProductTypeService.getWoProductTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.productTypeMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }

          const productType = jsonItem as unknown as WoProductType;
         // if (productType.receivable) {
            this.productTypeMap.set( productType.$key , productType );
         // }

        }
      });
    });

    // let u = this.unitService.GetUnitsList(); 
    // u.snapshotChanges().subscribe(data => {
    //   this.units = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.units.push(jsonItem as unknown as Unit);
    //     }
    //   })
    // });

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'productType', header: 'ProductType' },
      { field: 'history', header: 'History' },
      // { field: 'deliveryMan', header: 'Delivery man' },
      // { field: 'receiver', header: 'Receiver' },
      // { field: 'provider', header: 'Provider' },
      // { field: 'instant', header: 'Date time' },
      // { field: 'items', header: 'Items' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = [
      { field: 'name', header: 'Name' },
      { field: 'productType', header: 'ProductType' },
      { field: 'history', header: 'History' },
      { field: 'actions', header: 'Actions' } 
    ];

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.woProductService.getWoProductsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.WoProducts);
            doc.save('content-types.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.woProducts);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "content-types");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.woProducts, 'WoProducts', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedWoProducts, 'content-types', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.woProduct = {} as WoProduct;
    this.submitted = false;
    this.woProductDialog = true;
  }

  deleteSelectedWoProducts() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Packaging Types?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.woProducts = this.woProducts.filter(val => !this.selectedWoProducts.includes(val));
          this.woProducts.forEach( pallet => {
            this.woProductService.deleteWoProduct(pallet.$key);
          } );
          
          this.selectedWoProducts = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging types deleted', life: 3000});
      }
  });
  }

  editWoProduct(woProduct: WoProduct) {
    this.woProduct = {...woProduct};
    this.woProductDialog = true;
  }

  deleteWoProduct(woProduct: WoProduct) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + woProduct.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.woProducts = this.woProducts.filter(val => val.$key !== woProduct.$key);
            this.woProduct = {} as WoProduct;
            this.woProductService.deleteWoProduct(woProduct.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.woProductDialog = false;
    this.submitted = false;
  }

  saveWoProduct() {
    this.submitted = true;
    if (this.woProduct.$key) {
      this.woProducts[this.findIndexById(this.woProduct.$key)] = this.woProduct;                
      this.woProductService.updateWoProduct(this.woProduct);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';
      this.woProductService.addWoProduct(this.woProduct);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Packaging Type Created', life: 3000});
      this.woProducts = [...this.woProducts];
    }
    this.woProductDialog = false;
    this.woProduct = { } as WoProduct;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.woProducts.length; i++) {
        if (this.woProducts[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeWoProduct(woProduct : WoProduct) {
    const woProductTmp = {
      ...woProduct
    };
    console.log("SAVE", woProductTmp);
    this.woProductService.updateWoProduct(  woProductTmp );
  }

//   woProductItems( 
//      items : any
//   ) : {
//     identifier?: string;
//     productType?: WoProductType;
//     quantity?: number;
//   }[]  {
//     const keys = Object.keys(items);
//     let results : any[] = [];
//     keys.forEach( key => { 
//       if (this.isNumber(key)) 
//       results[Number(key)] = items[Number(key)];
//       } 
//     );
//     return results;
//   }

//   display(woProduct : WoProduct, items : {
//     identifier?: string;
//     productType?: WoProductType;
//     quantity?: number;
// }[]) : string {
//     console.log(typeof woProduct.items);
//     let result = "";

//     let ritems = items;

//     const keys = Object.keys(items);
//     let ma : any[] = [];
//     keys.forEach( key => { 
//       if (this.isNumber(key)) 
//         ma[Number(key)] = items[Number(key)];
//       } 
//     );
//     console.log('ma', ma); // 👉️ ['name', 'country']

    
//     //const snapshotToArray = ritems => Object.entries(ritems).map(e => Object.assign(e[1], { key: e[0] }));

//     // console.log(typeof items);
//     ma.forEach( item => {
//       result += " id: "+ item.identifier;
//       result += " quantity: "+ item.quantity;
//       result += " name: "+ item.productType;
//     });
//     return result;
//   }

  isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
  
    if (str.trim() === '') {
      return false;
    }
    return !Number.isNaN(Number(str));
  }

  // closeInplace(inplace : any) {
  //   inplace.close();
  //   // update item data
  // }

  getHistory( history : any) {
    const result = Object.assign([],history);
    result.forEach( (item: any) => {
      item.icon= PrimeIcons.CHECK,  //PrimeIcons.USER
      item.color= '#607D8B'
    });
    return result;
  }

  isReception( value:string) {
    return value === 'RECEPTION';
  }

  isDry( value:string) {
    return value === 'DRY';
  }

  isMove(value:string) {
    return value === 'MOVEMENT';
  }

  isTransform(value:string) {
    return value === 'TRANSFORM';
  }

  isSold(value:string) {
    return value === 'SOLD';
  }

  isFrom( origin : string , reference : string) {
    return origin === reference;
  }

  getItems( receipt: WoReceipt ) {
    return Object.assign([],receipt.items);
  }

  displayProductTypeName( productTypeKey: string ) {
    console.log('key', productTypeKey );
    if (this.productTypeMap.has(productTypeKey) ) {
      const productType : WoProductType = this.productTypeMap.get(productTypeKey) || {} as WoProductType;
      return productType.name;
    }
    return '';
  }

}
