import { Component } from '@angular/core';

// Primeng
import { PrimeNGConfig } from 'primeng/api';

import { 
  AngularFireStorage, 
  AngularFireStorageReference, 
  AngularFireUploadTask 
} from '@angular/fire/compat/storage';

import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const getObservable = (storage: AngularFireStorage) => {
  const subject = new BehaviorSubject<Task[]>([]);

  // storage.ref

  // collection.valueChanges({ idField: 'id' }).subscribe((val: Task[]) => {
  //   subject.next(val);
  // });
  return subject;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'project1';
  ref: AngularFireStorageReference | undefined;
  task: AngularFireUploadTask | undefined;

  constructor(private afStorage: AngularFireStorage, private primengConfig: PrimeNGConfig) { }


  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  upload(event: any) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
  }

}
