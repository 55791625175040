class TypeNode {
    name: string;
    children: TypeNode[];

    constructor() {
        this.name = '';
        this.children = [];
    }

    toJSON(): object {
        return {
            name: this.name,
            children: this.children.map( (child : TypeNode) => child.toJSON())
        }
    };
}

class ProductTypeLeaf extends TypeNode {
    constructor(name?: string) {
        super();
        this.name = name || 'N.A.';
    }
}

class ContainerTypeNode extends TypeNode {

    // Public attributes
    minCapacity: number;
    maxCapacity: number;
    quantity: number;

    // Cosntructors
    constructor(maxCapacity : number, quantity? : number, minCapacity = 0) {
        super();
        this.minCapacity = minCapacity;
        this.maxCapacity = maxCapacity;
        this.quantity    = quantity || maxCapacity;
    };

    // Getters
    public getMinCapacity() : number {
        return this.minCapacity;
    }
    public getMaxCapacity() : number {
        return this.maxCapacity;
    }
    public getQuantity() : number {
        return this.quantity;
    }

    // Setters
    public setQuantity( quantity : number ) {
        this.quantity = quantity;
    }

    //
}

export { TypeNode, ProductTypeLeaf, ContainerTypeNode }

